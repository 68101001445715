import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';
import segment from "segment";

import PasswordInput from 'components/PasswordInput';

import styles from 'entry/Entry.module.scss';

class CreateAccount extends Component {
    static contextType = Context;

    state = {
        processing: false,
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        firstNameValid: false,
        lastNameValid: false,
        error: false,
        passwordStrength: null
    };

    componentDidMount() {
        segment.page('Create Account');
    }

    /**
     * Update State
     * @param {Object} event - onChange
     */
    updateState = (event) => {
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        if(event.target.name === "firstName") {
            firstNameValid = /^[a-zÀ-ú'-]{2,}/i.test(event.target.value);
        } else if(event.target.name === "lastName") {
            lastNameValid = /^[a-zÀ-ú'-]{2,}/i.test(event.target.value);
        }
        this.setState({
            [event.target.name]: event.target.value,
            error: false,
            firstNameValid,
            lastNameValid
        })
    };

    /**
     * When password field is changed, update state
     * @param {object} event - onChange Event
     */
    updateStatePassword = (event) =>{
        this.setState({
            [event.target.name]: event.target.value,
            error: false,
            passwordStrength: event.target.strength
        })
    };

    /**
     * Call context createAccount function and handles response (makes accountDetails)
     * @param {Object} event - onClick event
     */
    createAccount = async (event) => {
        this.setState({ processing: true });
        event.preventDefault();

        try {
            if(this.state.firstNameValid && this.state.lastNameValid) {
                if(this.state.passwordStrength >= 4) {
                    await this.context.createAccount(this.state.firstName, this.state.lastName, this.state.email, this.state.password);
                    await segment.track('account_created', {
                        application: 'dashboard'
                    });
                    window.location.href = "/verify-account";
                } else {
                    this.context.setToast('Please choose a stronger password. Try to include capitals, symbols and numbers., please try again.', 'Danger', 10000);
                }
            } else {
                this.context.setToast(!this.state.firstNameValid && !this.state.lastNameValid ? "First and last name are too short or invalid." : !this.state.lastNameValid ? "Last name is too short or invalid." :  "First name is too short or invalid.", "Danger", 10000);
            }
        } catch {
            this.setState({
              error: true,
          })
        } finally {
            this.setState({
                processing: false,
            })
        }
    };

    render() {
        const submit_text = this.state.error ? "Invalid Details. Try again" : "Create Account";
        const submit_class = `btn ${this.state.error ? "btnDanger" : "btnBrand"}`;
        return (
            <>
                <div className={`bgSecondary ${styles.action}`}>
                    <NavLink to="/log-in" className="btn btnBrand">Log In</NavLink>
                </div>
                <div className={`bgSecondary ${styles.login}`}>
                    <form onSubmit={this.createAccount}>
                        <h1>Create Account</h1>
                        <div className={styles.names}>
                            <input 
                                type="text" 
                                name="firstName" 
                                placeholder="First Name" 
                                value={this.state.firstName} 
                                onChange={this.updateState}
                            />
                            <input 
                                type="text" 
                                name="lastName" 
                                placeholder="Last Name" 
                                value={this.state.lastName} 
                                onChange={this.updateState}
                            />
                        </div>
                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Email Address" 
                            value={this.state.email} 
                            onChange={this.updateState}
                        />
                        <PasswordInput 
                            autoComplete={false} 
                            name="password" 
                            placeholder="Choose Password" 
                            value={this.state.password} 
                            onChange={this.updateStatePassword}
                        />
                        <p className={styles.policy}>
                            By creating an account you agree to adhere to our 
                            <a href="https://speedweaver.co.uk/fair-usage-policy" target="_blank">
                                Fair Usage Policy
                            </a>.
                        </p>
                        <input 
                            disabled={this.state.processing || this.state.error} 
                            type="submit" value={submit_text} 
                            className={submit_class}
                        />
                    </form>
                </div>
            </>
        )
    }
}

export default CreateAccount;
