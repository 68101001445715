import React, { Component } from 'react';
import segment from "segment";

import HighChartsReact from "highcharts-react-official";
import HighCharts from "highcharts";

import vars from "styles/_vars-dark.scss";

class DynoGraph extends Component {

    componentDidMount() {
        segment.page('Project > Tuning > Wizards > DynoGraph');
    }

    render() {
        let x = this.props.dyno[0].rpm;
        let yOemHp = this.props.dyno[0].stock.hp;
        let yOemNm = this.props.dyno[0].stock.nm;
        let frontProfileName = "Stage 1";
        let yStageHp = this.props.dyno[0].stage1.hp;
        let yStageNm = this.props.dyno[0].stage1.nm;

        const indexesToRemove = x.map((val, i) => { return i }).filter((val, i) => { return !yOemHp[i] || !yOemNm[i] || !yStageHp[i] || !yStageNm[i] });

        x = x.filter((val, i) => { return !indexesToRemove.includes(i) });
        yOemHp = yOemHp.filter((val, i) => { return !indexesToRemove.includes(i) });
        yOemNm = yOemNm.filter((val, i) => { return !indexesToRemove.includes(i) });
        yStageHp = yStageHp.filter((val, i) => { return !indexesToRemove.includes(i) });
        yStageNm = yStageNm.filter((val, i) => { return !indexesToRemove.includes(i) });

        let maxY = Math.max.apply(null, yOemNm.concat(yStageNm)) * 1.05;
        let maxYHp = Math.max.apply(null, yOemHp.concat(yStageHp)) * 1.05;

        return (
            <HighChartsReact
                highcharts={HighCharts}
                containerProps = {{ className: 'chartContainer' }}
                options={{
                    className: "test",
                    colors: ["rgba(255, 102, 0, 1)", "rgba(255, 102, 0, 0.50)", "rgba(68, 138, 204, 0.4)", "rgba(68, 138, 204, 0.2)", "#b7c5b8"],
                    chart: {
                        type: 'areaspline',
                        backgroundColor: "none",
                        borderWidth: 0,
                        borderRadius: 0,
                        plotBackgroundColor: null,
                        plotShadow: false,
                        plotBorderWidth: 0,
                        height: 130,
                    },
                    title: {
                        text: "",
                    },
                    xAxis: {
                        title: {
                            text: ""
                        },
                        lineWidth: 0,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        labels: {
                            enabled: false,
                        },
                        categories: x
                    },

                    yAxis: [
                        {
                            alternateGridColor: null,
                            minorTickInterval: null,
                            gridLineWidth: 0,
                            lineWidth: 0,
                            tickWidth: 0,
                            labels: {
                                enabled: false
                            },
                            min: 0,
                            max: maxYHp,
                            title: {
                                text: ""
                            }
                        },
                        {
                            alternateGridColor: null,
                            minorTickInterval: null,
                            gridLineWidth: 0,
                            lineWidth: 0,
                            tickWidth: 0,
                            min: 0,
                            max: maxY,
                            labels: {
                                enabled: false
                            },
                            title: {
                                text: "",
                            }

                        }
                    ],
                    tooltip: {
                        enabled: true,
                        crosshairs: true,
                        backgroundColor: vars.highlightPrimary,
                        borderWidth: 0,
                        formatter: function() {
                            var unit = {
                                'Stock HP': 'HP',
                                'Stage 1 + HP': 'HP',
                                'Stage 1 HP': 'HP',
                                'Economy HP': 'HP',
                                'Stage HP': 'HP',
                                'Stock Torque': 'Nm',
                                'Stage 1 + Torque': 'Nm',
                                'Stage 1 Torque': 'Nm',
                                'Economy Torque': 'Nm',
                                'Stage Torque': 'Nm'
                            }[this.series.name];

                            return '<b>'+ this.series.name +'</b><br/>'+ Math.round(this.y) + unit + ' @ '+ this.x +'RPM';
                        },

                        style: {
                            color: '#ffffff',
                            font: '9pt ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif'
                        }
                    },
                    labels: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        areaspline: {
                            fillOpacity: 0,
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 3,
                                lineWidth: 1,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                        area: {
                            fillOpacity: 0,
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 5,
                                lineWidth: 1,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                        spline: {
                            lineWidth: 1,
                            dataLabels: {
                                color: vars.highlightTertiary
                            },
                            marker: {
                                enabled: false,
                                states: {
                                    hover: {
                                        enabled: true,
                                        symbol: 'circle',
                                        radius: 5,
                                        lineWidth: 2
                                    }
                                }
                            }
                        },
                        line: {
                            dataLabels: {
                                enabled: false
                            },
                            enableMouseTracking: true
                        },
                        series: {
                            lineWidth: 3,
                            borderWidth: 0,
                        }
                    },

                    series: [{
                        name: frontProfileName + ' HP',
                        cursor: 'pointer',
                        data: yStageHp
                    },{
                        name: 'Stock HP',
                        cursor: 'pointer',
                        data: yOemHp

                    },{
                        name: frontProfileName +' Torque',
                        cursor: 'pointer',
                        yAxis: 1,
                        data: yStageNm,
                    },{
                        name: 'Stock Torque',
                        cursor: 'pointer',
                        yAxis: 1,
                        data: yOemNm
                    }],
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    credits: {
                        enabled: false
                    },

                }}
            />
        )
    }
}

export default DynoGraph;