import * as ProjectsRespository from '../repository/ProjectsRepository'

export const getProject = async (id) => {
    const res = await ProjectsRespository.getProject(id);
    return res.data;
};

export const getProjects = async (perPage, searchTerm, sortBy, ascending) => {
    const res = await ProjectsRespository.getProjects(perPage, searchTerm, sortBy, ascending);
    return { projects: res.data['hydra:member'], totalProjects: res.data['hydra:totalItems'] };
};

export const getProjectLogs = async (id) => {
    return await ProjectsRespository.getProjectLogs(id);
};

export const getAppliedWizards = async (id) => {
    return await ProjectsRespository.getAppliedWizards(id);
};

export const getMakes = async (projectId) => {
    return await ProjectsRespository.getMakes(projectId);
};

export const getModels = async (projectId, makeId) => {
    let models = await ProjectsRespository.getModels(projectId, makeId);
    return models.map((model) => {
        return {
            ...model,
            name: model.name.replace(" ()", "")
        }
    });
};

export const getPowerTrains = async (projectId, makeId, modelId) => {
    const powerTrains = await ProjectsRespository.getPowerTrains(projectId, makeId, modelId);
    return powerTrains.map((powerTrain) => {
        return {
            ...powerTrain,
            name: powerTrain.name.replace(" ()", "")
        }
    })
};

export const getCompatibilityList = async () => {
    let list = await ProjectsRespository.getCompatibilityList()
    return Object.keys(list).reduce((array, key) => {
        return [...array, list[key]]
    }, []).map((item, idx) => {
        return {
            ...item,
            vehicleMake: item.vehicleMake.replace(" ()", ""),
            vehicleModel: item.vehicleModel.replace(" ()", ""),
            vehiclePowerTrain: item.vehiclePowerTrain.replace(" ()", "")
        }
    })
};

export const getWizards = async (id) => {
    const res = await ProjectsRespository.getWizards(id);
    return res.data['hydra:member'];
};

export const resetProject = async (projectId, type) => {
    let revertToStock = false;
    if(type === "stock") revertToStock = true;
    return await ProjectsRespository.resetProject(projectId, revertToStock);
};

export const applyWizard = async (projectId, wizardId) => {
    return await ProjectsRespository.applyWizard(projectId, wizardId);
};

export const uploadFile = async (file) => {
    return await ProjectsRespository.uploadFile(file);
};

export const updateProject = async (data) => {
    return await ProjectsRespository.updateProject(data);
};

export const deleteProject = async (id) => {
    return await ProjectsRespository.deleteProject(id);
};

export const downloadOriginal = async (projectId) => {
    return await ProjectsRespository.downloadOriginal(projectId);
};

export const createVersion = async (projectId) => {
    return await ProjectsRespository.createVersion(projectId);
};

export const exportProject = async (projectVersion) => {
    return await ProjectsRespository.exportProject(projectVersion);
};

export const archiveProject = async (projectId) => {
    return await ProjectsRespository.archiveProject(projectId);
};

export const unArchiveProject = async (projectId) => {
    return await ProjectsRespository.unArchiveProject(projectId);
};

export const getProjectTickets = async (projectId) => {
    let res = await ProjectsRespository.getProjectTickets(projectId);
    return res.data['hydra:member'];
};

export const purchaseWizards = async (projectId, items, coupon, three_d_secure_action_result_token_id) => {
    return await ProjectsRespository.purchaseWizards(projectId, items, coupon, three_d_secure_action_result_token_id);
}

