import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as zxcvbn from 'zxcvbn';

import styles from 'components/Components.module.scss';

export default class PasswordInput extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        type: 'password',
        strength: 0,
        hideStrength: this.props.hideStrength
    };

    toggleType = event => {
        event.preventDefault();
        this.setState(({type}) => ({
            type: type === 'text' ? 'password' : 'text'
        }));
    };

    updateStrength = (event) => {
        const strength = (event.target.value.length === 0) ? 0 : 2+zxcvbn(event.target.value).score;
        this.setState({ strength: strength });
        event.target.strength = strength;
        this.props.onChange(event);
    };

    render() {
        return (
            <div className={`${styles.passwordInput} ${this.props.marginTop? styles.marginTop : ''}`}>
                <div className={styles.inputWrapper}>
                    <input
                        type={this.state.type}
                        name={this.props.name}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.updateStrength}
                        minLength="6"
                        autoComplete={this.props.autoComplete ? "on" : "off"}
                        className="bgTertiary"
                    />
                    <span className={styles.passwordToggle} onMouseDown={this.toggleType}>{this.state.type === 'text' ? 'Hide' : 'Show'}</span>
                </div>
                {!this.state.hideStrength && <progress className={styles.strength} max="6" value={this.state.strength}>asdf</progress> }
            </div>
        )
    }
}