import React, { Component } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { Context } from 'context/context';

import FreeTrialPopup from "components/FreeTrialPopup";

import styles from "dashboard/support/ticket/Ticket.module.scss";

class NewTicket extends Component {
    static contextType = Context;

    state = {
    };

    render() {
        const userPlan = this.context.user.status === "expired" ? "Free" : this.context.user.plan;
        const hasHadTrial = this.context.subscriptionVariables?.hasHadTrial
        const isFreeUser = userPlan == 'Free';
        const freeTrialLink = !hasHadTrial && isFreeUser?  'freetrial' : 'resubscribe'
        const paywallLink = `/support-tickets/new-ticket/${freeTrialLink}`

        return (
            <>
                <div className={`highlightTertiary ${styles.content}`}>
                    <div className={styles.newTicketOptions}>
                        <NavLink className="bgTertiary" to={`/support-tickets/new-ticket/general`}>
                            <img src={require(`images/icons/${this.context.lightTheme ? "general-query-grey" : "general-query"}.svg`)} alt="" />
                            <div>
                                <h3>General Query</h3>
                                <p className="txtSecondary">Ask any question about Speedweaver.</p>
                            </div>
                        </NavLink>

                        <NavLink className="bgTertiary" to={`/support-tickets/new-ticket/report`}>
                            <img src={require(`images/icons/${this.context.lightTheme ? "issue-grey" : "issue"}.svg`)} alt="" />
                            <div>
                                <h3>Report an Issue</h3>
                                <p className="txtSecondary">Report any issues/bugs you've encountered.</p>
                            </div>
                        </NavLink>

                        {this.context.subscriptionVariables.isProUser ?
                            <>
                                <NavLink className="bgTertiary" to={`/support-tickets/new-ticket/rejected`}>
                                    <img src={require(`images/icons/${this.context.lightTheme ? "rejected-file-grey" : "rejected-file"}.svg`)} alt="" />
                                    <div>
                                        <h3>Report Rejected File</h3>
                                        <p className="txtSecondary">Report a rejected file that appears on our compatibility search.</p>
                                    </div>
                                </NavLink>
                            </>
                            :
                            <>
                                <NavLink to={paywallLink} className={`bgTertiary`}>
                                    <img src={require(`images/icons/${this.context.lightTheme ? "rejected-file-grey" : "rejected-file"}.svg`)} alt="" />
                                    <div>
                                        <div>
                                            <h3>Report Rejected File</h3>
                                            <img src={require('images/icons/padlock.svg')} alt="Padlock" title="Subscription Required"/>
                                        </div>
                                        <p className="txtSecondary">Report a rejected file that appears on our compatibility search.</p>
                                    </div>
                                </NavLink>
                            </>
                        }
                    </div>
                </div>

                <Switch>
                    <Route
                        path="/support-tickets/new-ticket/freetrial"
                        render={(props) => (
                            <FreeTrialPopup 
                                heading="Start your 7-day free trial!"
                                subHeading="Begin your fully featured free trial to continue."
                                buttonText="Start Free Trial"
                            />
                        )}
                    />

                    <Route
                        path="/support-tickets/new-ticket/resubscribe"
                        render={(props) => (
                            <FreeTrialPopup 
                                heading="Your Subscription Expired!"
                                subHeading="Renew your subscription to continue."
                                buttonText="Renew Subscription"
                            />
                        )}
                    />
                </Switch>
            </>
        )
    }
}

export default NewTicket;
