import React, { Component } from 'react';

import styles from '../../Billing.module.scss';
import PropTypes from "prop-types";

class Accordion extends Component {
    static propTypes = {
        heading: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    };

    state = {
        show: false
    }

    showHide = () => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        return (
            <div className={styles.accordion}>
                <div>
                    <h3 onClick={this.showHide}>{this.props.heading}</h3>
                    {this.state.show ?
                        <img src={require('images/icons/cross-dark.svg')}  alt="Close"/>
                    :
                        <img src={require('images/icons/dash.svg')}  alt="Click"/>
                    }
                    <div className={this.state.show ? styles.show : undefined }>
                        <p>{this.props.message}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Accordion;

