import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import segment from 'segment';

import styles from "dashboard/projects/project/Project.module.scss";

class Rejected extends Component {
    static contextType = Context;

    state = {
        showPopUp: false
    };

    componentDidMount() {
        segment.page('Rejected Project');
    }

    render() {
        return (
            <>
                <div className={`bgTertiary highlightSecondary ${styles.activation}`}>
                    <div>
                        <NavLink to={`/projects`} className={styles.cross}>
                            <img src={require(`images/icons/${this.context.lightTheme ? "cross-dark" : "cross"}.svg`)} alt={"X"} />
                        </NavLink>
                        <p className={styles.sub}>Not Supported</p>
                    </div>
                </div>

                <div className={`bgSecondary ${styles.content}`}>
                    <div className={styles.alert}>
                        <div>
                            <img src={require(`images/icons/${this.context.lightTheme ? "alert-grey" : "alert"}.svg`)} alt="Error" />
                            <p className="txtSecondary">We were unable to process the binary file you submitted, this may be due to an error or because Speedweaver does not support the relevant ECU family yet.</p>
                            <p>Please check our 
                                <NavLink onClick={this.closeNav} to="/compatibility-search">
                                    Supported ECU List
                                </NavLink>
                            to ensure that your file is supported. Otherwise, you can raise a support ticket using the button below.</p>
                        </div>
                    </div>
                </div>

                <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                        {this.context.subscriptionVariables.isProUser ?
                            <NavLink className="btn btnBrand" to={`/support-tickets/new-ticket/rejected/${this.context.projects[this.props.projectIndex].id}`}>
                                <img src={require('images/icons/support-noCirc.svg')} alt="" />
                                Open Support Ticket
                            </NavLink>
                        :
                            <NavLink className="btn btnBrand" to={`/billing/subscribe`}>
                                <img src={require('images/icons/support-noCirc.svg')} alt="" />
                                Open Support Ticket (Subscription Only)
                            </NavLink>
                        }

                        <button className="btn btnDanger" onClick={this.props.deleteProject} disabled={this.props.deletingProject}>
                            <img src={require('images/icons/delete.svg')} alt="" />
                            {this.props.deletingProject ? "Deleting Project" : "Delete Project"}
                        </button>
                    </div>
            </>
        )
    }
}

export default Rejected;
