import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from 'components/Components.module.scss';

/**
 * Message Banner component
 */
export default class Toast extends Component{
    render () {
        return (
            <div className={styles.toastie}>
                <div className={`${styles.toast} bg${this.props.type}`}>
                    <p>{this.props.message}</p>
                </div>
                <img src={require('../images/icons/cross.svg')} alt="cross" onClick={this.props.close} />
            </div>
        );
    };
};

Toast.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    close: PropTypes.func
};
