import React, { Component } from 'react';

import styles from '../../Billing.module.scss';

class BillingDetails extends Component {
    render() {

        const billingDetails = this.props.billingDetails;

        return (
            <div className={styles.left}>
                <div className={styles.outer}>
                    <div className={styles.head}>
                        <h2>Billing Details</h2>
                    </div>
                    <div className={styles.billingDetails}>
                        {billingDetails.paymentType === "paypal_billing_agreement" ?
                            <div className={styles.paypalCard}>
                                <img src={require('images/icons/paypal.svg')} alt="PayPal" />
                            </div>
                        :
                            <div className={styles.card}>
                                <div className={styles.detail}>
                                    <p>Card Number</p>
                                    <p>XXXX XXXX XXXX {billingDetails.lastFour}</p>
                                </div>
                                <div>
                                    <div className={styles.detail}>
                                        <p>Expiry Date</p>
                                        <p>{billingDetails.expMonth}/{billingDetails.expYear}</p>
                                    </div>
                                    <div className={styles.detail}>
                                        <p>Card Type</p>
                                        <p>{billingDetails.cardType}</p>
                                    </div>
                                </div>
                            </div>
                        }

                        <div>
                            <h3>Billing Address</h3>
                            <p>{billingDetails.firstName} {billingDetails.lastName}</p>
                            {billingDetails.addressLineOne && <p>{billingDetails.addressLineOne}</p>}
                            {billingDetails.addressLineTwo && <p>{billingDetails.addressLineTwo}</p>}
                            {billingDetails.city && <p>{billingDetails.city}</p>}
                            {billingDetails.country && <p>{billingDetails.country}</p>}
                            {billingDetails.postalCode && <p>{billingDetails.postalCode}</p>}
                            {billingDetails.region && <p>{billingDetails.region}</p>}
                        </div>

                        {(billingDetails.company || billingDetails.vatNumber) &&
                            <div>
                                <h3>Company Details</h3>
                                {billingDetails.company && <p>{billingDetails.company}</p>}
                                {billingDetails.vatNumber && <p>{billingDetails.vatNumber}</p>}
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.navigators}>
                    <button className="btn btnOutline txtBlack" onClick={this.props.resetBillingDetails}>Change Billing Details</button>
                </div>
            </div>
        )
    }
}

export default BillingDetails;