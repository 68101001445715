import React, { useState, useEffect, useContext } from 'react';

import useCheckoutPricing from 'components/recurly/use-checkout-pricing';
import { Context } from 'context/context'
import styles from '../../Billing.module.scss';

import moment from 'moment'

function Pricing (props) {
    let [recurlyError, setRecurlyError] = useState(null);
    const context = useContext(Context)

    let initialPricingInput = {
        address: {
            country: props.country,
            vat_number: props.vatNumber
        },
        coupon: props.coupon
    };

    if(props.subscriptions) initialPricingInput.subscriptions = props.subscriptions;
    if(props.products) initialPricingInput.adjustments = props.products

    let [{ price, loading }, setCheckoutPricing] = useCheckoutPricing(initialPricingInput, setRecurlyError);

    useEffect(() => {
        setRecurlyError(null);
        setCheckoutPricing(initialPricingInput);
    }, [props.country, props.coupon, props.vatNumber]);

    let dateTo = moment().add(7,'d').format('Do MMMM');

    if (!loading) {
        return (
            <>
                {recurlyError ? <div className={styles.error}><p>Error: {recurlyError.message}</p></div> : null}
                    <div className={styles.amounts}>
                        {price.now.discount > 0 &&
                        <div>
                            <p>Discount</p>
                            <p><span>&euro; </span> {price.now.discount}</p>
                        </div>
                        }

                        <div className={styles.taxes}>
                            <div>
                                <p>Tax</p>
                                <p><span>&euro;</span> {price.now.taxes}</p>
                            </div>

                            {context.subscriptionVariables.isTrialAvailable &&
                                <div>
                                    <p>Due on <strong>{dateTo}</strong></p>
                                    <p><span>&euro;</span>{price.now.total}</p>
                                </div>
                            }
                        </div>

                    {context.subscriptionVariables.isTrialAvailable ?
                        <div>
                            <h2>Today you pay</h2>
                            <h2><span>&euro;</span>0.00</h2>
                        </div>
                    :
                        <div>
                            <h2>Total</h2>
                            <h2><span>&euro;</span> {price.now.total}</h2>
                        </div>
                    }
                </div>
            </>
        )
    } else {
        return false;
    }
}

export default Pricing
