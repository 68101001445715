import React, { Component } from 'react';
import {Context} from "../../../../../context/context";

import styles from '../../../Billing.module.scss';

class Subscription extends Component {
    static contextType = Context;

    getBenefits = () => {
        return this.context.product.benefits.map((item, i) => {
            return (
                <div className={styles.item} key={i}>
                    <img src={require('images/icons/tick.svg')} alt="Tick" />
                    <p>{item}</p>
                </div>
            );
        });
    }

    render() {
        let isTrialAvailable = this.context.subscriptionVariables.isTrialAvailable;
        let subscriptionPricePerMonth = this.context.subscriptionPricePerMonth;
        return (
            <div className={styles.details}>
                <div className={styles.head}>
                    <div>
                        <h2>Speedweaver Subscription</h2>
                        <p>{isTrialAvailable ? `€` + subscriptionPricePerMonth + ` per month` : "€" + subscriptionPricePerMonth + " per month (exclusive of VAT)"}</p>
                    </div>
                </div>

                <div className={styles.list}>
                    {this.getBenefits()}
                </div>

                <div className={styles.smallPrint}>
                    <p>This subscription will automatically charge you each month unless you cancel it. You can cancel at any time. There are no minimum terms. All prices listed exclude local taxes such as VAT.</p>
                </div>
            </div>
        )
    }
}

export default Subscription;
