import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import segment from "segment";

import Loading from "components/Loading";

import styles from 'dashboard/projects/project/Project.module.scss';

class AssociatedLogs extends Component {
    static contextType = Context;

    state = {
        loading: true,
        logs: []
    };

    async componentDidMount() {
        segment.page('Project > Associated Logs');

        let res = await this.context.getProjectLogs(this.props.project.id);
        this.setState({
            logs: res["hydra:member"],
            loading: false
        })
    }

    render() {
        if(!this.state.loading){
            return (
                <>
                    <div className={styles.wizards}>
                        <div className={`bgSecondary ${styles.content}`}>
                            <div className={styles.wizardOptions}>
                                <div>
                                    {this.state.logs.length > 0 ?
                                        <>
                                            <div className={styles.bases}>
                                                <h3>Associated Data Logs</h3>
                                                <p className="txtSecondary">Here are the logs that you have associated to this project</p>
                                                {this.state.logs.map((singleLog, idx) => {
                                                    return (
                                                        <div key={idx} className={styles.logs}>
                                                            <NavLink to={`/logs/${singleLog.id}/actions`}>
                                                                {singleLog.name}
                                                            </NavLink>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        </>
                                    :
                                        <>
                                            <div className={styles.alert}>
                                                <img src={require(`images/icons/${this.context.lightTheme ? "alert-grey" : "alert"}.svg`)} alt="Alert" />
                                                <h3>No Logs Associated</h3>
                                                <p>You have not linked any logs to this project.</p>
                                            </div>
                                            
                                            
                                        </>
                                    }
                                   
                                </div>
                            </div>
                        </div>
                        <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                            <NavLink to={`/logs`} className={"btn btnBrand"}>
                                <img src={require('images/icons/wizards-noCirc.svg')} alt="" />
                                Go to logs
                            </NavLink>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <div className={`bgSecondary ${styles.content}`}>
                    <Loading/>
                </div>
            )
        }
    }
}

export default AssociatedLogs;
