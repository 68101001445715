import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Context } from 'context/context';
import segment from "segment";

import queryString from "query-string";

import List from "dashboard/logs/List";
import Log from "dashboard/logs/log/Log";

import Loading from "components/Loading";

import styles from "dashboard/Dashboard.module.scss";
import Cookies from "js-cookie";

export default class Logs extends Component {
    static contextType = Context;

    state = {
        loading: true,
    };

    async componentDidMount(){
        segment.page('Logs');
        this.parseQuery();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.search !== prevProps.location.search) this.parseQuery();
    }

    parseQuery = () => {
        let page = 1;

        const query = queryString.parse(this.props.location.search);
        if(query.page) page = parseInt(query.page);

        this.setState({ page }, this.fetchLogs);
    };

    fetchLogs = async () => {
        await this.context.getLogs(this.state.page);
        this.setState({ loading: false })
    };

    render() {

        const pages = Math.ceil(this.context.totalLogs / 10);

        let nextPage = null;
        if(this.state.page + 1 <= pages) nextPage = this.state.page + 1;

        let prevPage = null;
        if(this.state.page - 1 >= 1) prevPage = this.state.page - 1;

        return (
            <>
                <Helmet>
                    <title>Diagnostics Logs - Speedweaver</title>
                </Helmet>

                <div className={styles.container}>
                    <div className={styles.heading}>
                            <h1>Diagnostics Logs</h1>
                        {this.context.logs.length > 0 &&
                            <div className={styles.right}>
                                <div className={styles.appStoreHeader}>
                                    <a href="https://play.google.com/store/apps/details?id=app.speedweaver.co.uk" target="_blank" className={styles.cross}>
                                        <img src={require('images/icons/google-large.svg')}  alt="Google Play Store"/>
                                    </a>
                                </div>

                                <div className={styles.appStoreHeader}>
                                    <a href="https://apps.apple.com/gb/app/speedweaver/id1457564616" target="_blank" className={styles.cross}>
                                        <img src={require('images/icons/apple-large.svg')}  alt="App Store"/>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>

                    {this.state.loading ?
                        <Loading/>
                    :
                        this.context.totalLogs > 0 ?
                            <List location={this.props.location} />
                        :
                            <div className={styles.centred}>
                                <h2>You don't have any Diagnostics Logs yet.</h2>
                                <p>Download the Speedweaver mobile app for iOS or Android to record your first log.</p>
                                <div className={styles.appStore}>
                                    <a href="https://play.google.com/store/apps/details?id=app.speedweaver.co.uk" target="_blank" className={styles.cross}>
                                        <img src={require('images/icons/google-large.svg')}  alt="Google Play Store"/>
                                    </a>

                                    <a href="https://apps.apple.com/gb/app/speedweaver/id1457564616" target="_blank" className={styles.cross}>
                                        <img src={require('images/icons/apple-large.svg')}  alt="App Store"/>
                                    </a>
                                </div>
                            </div>
                    }
                </div>

                <Log {...this.props} fetchLogs={this.fetchLogs} />
            </>
        );
    }

}