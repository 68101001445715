import React, { Component } from 'react';
import Config from 'Config';
import { NavLink } from 'react-router-dom';
import history from 'history/history';
import { Context } from 'context/context';
import segment from "segment";

import queryString from 'query-string';

import PasswordInput from 'components/PasswordInput';

import styles from 'entry/Entry.module.scss';

class LogIn extends Component {
    static contextType = Context;

    state = {
        email: "",
        password: "",
        error: false
    };

    componentDidMount() {
        segment.page('Log In');
    }

    getStarted = () => {
        segment.track('account_intended', {
            application: 'dashboard',
            button: "entry"
        });
    };

    /**
     * Update State with new input, sets error to false
     * @param {Object} event - OnChange
     */
    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: false
        })
    };

    /**
     * Calls login function in context and handles response
     * @param {Object} event - OnClick
     */
    login = async (event) => {
        event.preventDefault();
        try {
            await this.context.login(this.state.email, this.state.password);
            await segment.track('auth_login', {
                application: 'dashboard'
            });

            let location = "/";
            const query = queryString.parse(history.location.search);
            if(query.referrer && (query.referrer.startsWith(Config.dashboard) || query.referrer.startsWith(Config.editor))) location = query.referrer;

            window.location.href = location;
        } catch(error) {
            this.setState({error: true})
        }
    };

    render() {
        const submit_text = this.state.error ? "Invalid Details. Try again" : "Log In";
        const submit_class = `btn ${this.state.error ? "btnDanger" : "btnBrand"}`;

        return (
            <>
                <div className={`bgSecondary ${styles.action}`}>
                    <NavLink to="/create-account" className="btn btnBrand" onClick={this.getStarted}>Create Account</NavLink>
                </div>
                <div className={`bgSecondary ${styles.login}`}>
                    <form onSubmit={this.login}>
                        <h1>Log In</h1>
                        <input type="email" name="email" placeholder="Email Address" value={this.state.email} onChange={this.updateState}/>
                        <PasswordInput 
                            autoComplete={true} 
                            name="password" 
                            placeholder="Password" 
                            hideStrength={true} 
                            value={this.state.password} 
                            onChange={this.updateState}
                        />
                        <input type="submit" value={submit_text} className={submit_class} disabled={!this.state.password || !this.state.email || this.state.error}/>
                        <div className={styles.links}>
                            <NavLink to="/forgot-password">Forgot Password</NavLink>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

export default LogIn;