import React, { useEffect } from 'react';

import { useRecurly } from '@recurly/react-recurly';

import styles from "../../Billing.module.scss";

export function PayPalButton(props) {
    const recurly = useRecurly();
    const payPal = recurly.PayPal({
        display: { displayName: 'Speedweaver Subscription' }
    });

    useEffect(() => {
        payPal.on('error', error => { props.setFailed(); });
        payPal.on('cancel', error => { props.setFailed(); });

        payPal.on('token', token => {
            props.handleRecurlyToken(token);
        });

        payPal.on('ready', () => {
            console.log('Ready');
        });
    }, [payPal]);

    const handleClick = () => {
        props.setMethod(2);
        payPal.start({
            description: "Speedweaver Subscription"
        });
    };

    return <button onClick={handleClick} className={styles.method}>
        <img src={require('images/icons/paypal.svg')} alt="PayPal" className={styles.paypalIcon} />
        Pay with PayPal
    </button>;
}