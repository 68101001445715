import React, { Component } from 'react';
import { Context } from "context/context";

import { Elements, RecurlyProvider, ThreeDSecureAction } from "@recurly/react-recurly";

import Coupon from './Coupon';
import Pricing from "./Pricing";

import styles from '../../Billing.module.scss';
import segment from "../../../../segment";
import * as Sentry from "@sentry/browser";

class Subscribe extends Component {
    static contextType = Context;

    state = {
        processing: false,
        coupon: null,
        actionTokenId: null
    };

    removeCoupon = () => {
        this.setState({ coupon: null })
    };

    applyCoupon = (coupon) => {
        this.setState({ coupon })
    };

    action = async (token) => {
        let checkoutType = "reactivate";
        if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
        if (this.props.update) checkoutType = "update";

        await segment.track('checkout_proceeded', {
            application: 'dashboard',
            checkoutType,
            type: 'submit'
        });

        this.setState({
            processing: true
        });

        if(token?.id) token = token.id;

        try {
            await this.context.subscribe(this.props.plan.id, this.state.coupon, token);

            let checkoutType = "reactivate";
            if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
            if (this.props.update) checkoutType = "update";

            await segment.track('checkout_completed', {
                application: 'dashboard',
                checkoutType
            });

            await segment.track('subscription_created', {
                application: 'dashboard',
                plan: 'basic'
            });

            let referrer = null;

            if(this.props.location?.search.includes("referrer")){
                referrer = this.props.location.search.split("?referrer=")[1];
            }

            window.location.href = `/billing/subscribe/thank-you${referrer ? `?referrer=${referrer}` : ""}`;
        } catch (error) {
            if (error?.response?.data?.error?.three_d_secure_action_token_id) {
                let checkoutType = "reactivate";
                if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
                if (this.props.update) checkoutType = "update";

                await segment.track('checkout_proceeded', {
                    application: 'dashboard',
                    checkoutType,
                    type: 'threeDSecure'
                });
                this.setState({
                    actionTokenId: error.response.data.error.three_d_secure_action_token_id
                })
            } else {
                if(error?.response?.data?.error?.message){
                    this.props.setErrorMessage(error.response.data.error.message);
                    this.setState({
                        processing: false
                    });
                } else {
                    this.props.setFailed();
                }
            }
        }
    };

    onError = (error) => {
        Sentry.captureException(error);
        this.props.setFailed();
    };

    getBenefits = () => {
        return this.context.product.benefits.map((item, i) => {
            return (
                <div className={styles.item} key={i}>
                    <img src={require('images/icons/tick.svg')} alt="Tick" />
                    <p>{item}</p>
                </div>
            );
        });
    }

    render() {
        let trialAvailable = this.context.subscriptionVariables.isTrialAvailable
        let subscriptionPricePerMonth = this.context.subscriptionPricePerMonth;

        return (
            <>
                {this.state.actionTokenId &&
                    <RecurlyProvider publicKey={this.props.publishableToken}>
                        <Elements>
                            <ThreeDSecureAction
                                actionTokenId={this.state.actionTokenId}
                                onToken={this.action}
                                onError={this.onError}
                                className={styles.secureThreeD}
                            />
                        </Elements>
                    </RecurlyProvider>
                }
                <div className={`${styles.checkout} ${styles.right}`}>
                    <div className={styles.details}>
                        <div className={styles.head}>
                            <div>
                                <h2>Speedweaver Subscription</h2>
                                <p>{trialAvailable ? `€` + subscriptionPricePerMonth + ` per month` : "€" + subscriptionPricePerMonth + " per month"}</p>
                            </div>
                        </div>

                        <div className={styles.list}>
                            {this.getBenefits()}
                        </div>

                        <Coupon
                            removeCoupon={this.removeCoupon}
                            applyCoupon={this.applyCoupon}
                            coupon={this.state.coupon}
                        />

                        <RecurlyProvider publicKey={this.props.publishableToken}>
                            <Elements>
                                <Pricing
                                    {...this.props}
                                    country={this.props.billingDetails.country}
                                    vatNumber={this.props.billingDetails.vatNumber}
                                    coupon={this.state.coupon}
                                    subscriptions={[{plan: 'basic'}]}
                                />
                            </Elements>
                        </RecurlyProvider>
                    </div>

                    <button className="btn btnBrand" onClick={this.action.bind(this, null)} disabled={this.state.processing}>
                        {this.context.subscriptionVariables.isTrialAvailable ?
                            "Start x Day Free Trial"
                        :
                            "Start Subscription"
                        }
                    </button>
                </div>
            </>
        )
    }
}

export default Subscribe;
