import React, { Component } from 'react';
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import { Context } from 'context/context';
import CurrencyFormat from 'react-currency-format';

import TabSwitcher from "dashboard/projects/project/components/TabSwitcher";
import Tuning from "dashboard/projects/project/components/Tuning";
import Details from "dashboard/projects/project/components/Details";

import AssociatedLogs from "./components/AssociatedLogs";

import styles from "dashboard/projects/project/Project.module.scss";
import slugify from "slugify";
import sanitize from "sanitize-filename";
import segment from "../../../segment";
import Wizards from "./components/Wizards";
import clone from "clone";
import Paywall from "../../../components/Paywall";

class Active extends Component {
    static contextType = Context;

    state = {
        tabs: [{
            id: 0,
            title: "Tuning",
            slug: "tuning"
        }, {
            id: 1,
            title: "Details",
            slug: "details"
        },
        {
            id: 2,
            title: "Diagnostic Logs",
            slug: "associated-logs"
        }],
        bases: [],
        patches: [],
        selected: null,
        base: null,
        success: false,
        applying: false,
        hasExported: false,
        isAPurchaseRequired: false,
        billingDetails: null,
    };


    project = this.context.projects[this.props.projectIndex];

    unArchive = async () => {
        await this.context.unArchiveProject(this.context.projects[this.props.projectIndex].id);
        await this.context.getProjects();
        await this.props.fetchProjects();
    };

    async componentDidMount() {
        try {
            const res = await this.context.getWizards(this.context.projects[this.props.projectIndex].id);
            const billingDetails = await this.context.getBillingDetails();
            const bases = res.filter((wizard) => { return wizard.wizType === "Base" });
            const patches = res.filter((wizard) => { return wizard.wizType === "Patch" });

            this.setState({
                bases,
                patches,
                billingDetails,
            })
        } catch(error) {
            this.setState({ loading: false })
        }
    }

    export = () => {
        this.setState({
            exporting: true
        }, async() => {
            let project = this.context.projects[this.props.projectIndex]
            try {
                let fileName = slugify(project.projectName);
                fileName = sanitize(fileName);
                if(fileName === "") fileName = "exported-file";

                await this.context.exportProject(project.id, fileName);
                await segment.track('project_downloaded', {
                    application: 'dashboard',
                    projectId: project.id,
                    projectType: project.projectType === "DEMO" ? "demo" : "user",
                    type: 'modified'
                });

                this.setState({
                    exporting: false,
                    hasExported: true
                })
            } catch {
                this.setState({
                    loading: false,
                    exporting: false
                })
            }
        });
    };

    onCloseExport = () => {
        this.setState({
            hasExported: false
        })
    }

    downloadOriginal = async () => {
        let project = this.context.projects[this.props.projectIndex];
        await segment.track('project_download', {
            application: 'dashboard',
            projectId: project.id,
            projectType: project.projectType === "DEMO" ? "demo" : "user",
            type: 'original'
        });

        let fileName = slugify(project.projectName);
        fileName = sanitize(fileName);
        if(fileName === "") fileName = "original-file";

        await this.context.downloadOriginal(project.id, fileName);
    };

    selectBase = (id) => {
        let bases = this.state.bases;
        bases = bases.map((wizard) => {
            return {
                ...wizard,
                dyno: wizard.id === id
            }
        });

        this.setState({ bases, base: id });
    };

    calcTotalPrice = () => {
        let bases = this.state.bases;
        let patches = this.state.patches;

        let cost = 0;

        // Base Wizards
        bases.map((wizard) => {
            if (wizard.dyno == true && wizard.purchased == false) {
                cost = cost + wizard.price;

            }
        });
        // Patch Wizards
        patches.map((patches) => {
            if (patches.selected == true && patches.purchased == false) {
                cost = cost + patches.price;
            }
        });
        cost = cost / 100;
        return cost;
    }

    totalPrice = () => {
        // https://www.npmjs.com/package/react-currency-format
        return (
            this.context.niceCurrency(this.calcTotalPrice())
        );
    }

    selectPatch = (id) => {
        let patches = this.state.patches;
        patches = patches.map((wizard) => {
            return {
                ...wizard,
                selected: wizard.id === id ? !wizard.selected : wizard.selected
            }
        });

        this.setState({ patches });
    };

    showCheckout = () => {
        if(this.calcTotalPrice() > 0) {
            let basket = {};
            basket.bases = this.state.bases;
            basket.patches = this.state.patches;

            this.context.setBillingDetails(this.state.billingDetails);
            this.context.setProjectItemsToPurchase(basket);
            this.context.setTotalPrice(this.totalPrice());
            this.context.togglePayWall(true);
        } else {
            this.apply();
        }

    };

    apply = () => {
        this.setState({
            applying: true,
            isAPurchaseRequired: false
        }, async () => {
            let project = this.context.projects[this.props.projectIndex];

            try {
                if(this.state.base){
                    const name = this.state.bases.filter((obj) => { return obj.id === this.state.base }).map((obj) => { return obj.name })[0];
                    await this.context.applyWizard(project.id, JSON.stringify(this.state.base));
                    await segment.track('project_tuned', {
                        application: 'dashboard',
                        projectId: project.id,
                        projectType: project.projectType === "DEMO" ? "demo" : "user",
                        type: 'base',
                        name
                    })
                }

                let patchesToApply = this.state.patches.filter((wizard) => { return wizard.selected; });
                patchesToApply = patchesToApply.map((wizard) => { return wizard.id });
                await Promise.all(patchesToApply.map(async (wizard) => {
                    const name = this.state.patches.filter((obj) => { return obj.id === wizard }).map((obj) => { return obj.name })[0];
                    await this.context.applyWizard(project.id, JSON.stringify(wizard));
                    await segment.track('project_tuned', {
                        application: 'dashboard',
                        projectId: project.id,
                        projectType: project.projectType === "DEMO" ? "demo" : "user",
                        type: 'patch',
                        name
                    })
                }));

                await this.context.getUserStats();
                // await this.props.fetchProject();
                this.context.setToast("Successfully applied selected wizards", "Success", 10000);
                this.setState({
                    base: null,
                    patches: this.state.patches.map((wizard) => {
                        return {
                            ...wizard,
                            selected: false
                        }
                    }),
                    applying: false,
                    success: true
                })
            } catch(error) {
                this.setState({
                    applying: false
                })
            }
        })
    };

    render() {
        let project = this.context.projects[this.props.projectIndex];
        const theme = this.context.lightTheme? 'light' : 'dark'
        return (
            <>
                {this.context.payWall &&
                    <Paywall
                        //projectId={this.context.projects[this.state.projectIndex].id}
                        projectId = {project.id}
                        project = {project}
                        apply = {this.apply}
                    />
                }

                {project.projectType === "DEMO" && <div className={styles.banner}>Demo File</div>}

                {project.tunerStatus === "archived" && <div className={styles.banner}>
                    <img src={require('images/icons/archived.svg')} alt="" />
                    This project has been archived. Click below to un-archive.
                </div>}

                <div className={`bgTertiary highlightSecondary ${styles.head}`}>
                    <div className={styles.headLeft}>
                        {project.protocolInformation.vehicleMake.logo && <img className="bgSecondary" src={`${process.env.REACT_APP_LOGOS}/${theme}/${project.protocolInformation.vehicleMake.logo}`} alt="" />}
                        <div className={styles.details}>
                            <div>
                                <p className="txtPrimary">{project.projectName}</p>
                            </div>
                            <div>
                                <p className="txtSecondary">{`${project.protocolInformation.vehicleMake.name}, ${project.protocolInformation.vehicleModel.name.split(" ()")[0]} ${project.protocolInformation.vehiclePowerTrain? ',' :null} ${project.protocolInformation.vehiclePowerTrain.name.split("()").join("")}`}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className={styles.actions}>
                        <NavLink to={`/projects`}>
                            <img src={require(`images/icons/${this.context.lightTheme ? "cross-dark" : "cross"}.svg`)} alt={"X"} />
                        </NavLink>
                    </div>
                </div>

                <div className={`bgTertiary ${styles.tabSwitcher}`}>
                    <TabSwitcher
                        page="projects"
                        id={project.id}
                        tabs={this.state.tabs}
                    />
                </div>

                <Switch>
                    <Route path="/projects/:id/tuning">
                        <Tuning
                            {...{...this.props, ...this.state}}
                            unArchive={this.unArchive}
                            apply={this.apply}
                            showCheckout={this.showCheckout}
                            applying={this.state.applying}
                            success={this.state.success}
                            bases={this.state.bases}
                            base={this.state.base}
                            patches={this.state.patches}
                            billingDetails={this.state.billingDetails}
                            project={project}
                            selectBase={this.selectBase}
                            selectPatch={this.selectPatch}
                            calcTotalPrice={this.calcTotalPrice}
                            totalPrice={this.totalPrice}
                            downloadOriginal={this.downloadOriginal}
                            export={this.export}
                            hasExported={this.state.hasExported}
                            onCloseExportSuccess={this.onCloseExport}
                        />
                    </Route>

                    <Route path="/projects/:id/details">
                        <Details
                            {...this.props}
                            project={project}
                            unArchive={this.unArchive}
                        />
                    </Route>

                    <Route path="/projects/:id/associated-logs">
                        <AssociatedLogs
                            {...this.props}
                            project={project}
                        />
                    </Route>

                </Switch>
            </>
        )
    }
}

export default Active;
