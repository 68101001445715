import React, { Component } from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Context } from 'context/context';
import segment from "segment";
import SearchCategory from 'dashboard/compatibilitySearch/components/SearchCategory'
import Loading from "components/Loading";
import history from "../../history/history";

import Results from "./components/Results";
import SelectSearch from "./components/SelectSearch";


import styles from 'dashboard/compatibilitySearch/CompatibilitySearch.module.scss';

class CompatibilitySearch extends Component {
    static contextType = Context;

    state = {
        compatibilityList: null,

        make: "",
        makes: [],
        model: "",

        ecuManufacturer: "",
        ecuManufacturers: [],
        ecuProtocol: "",
    };

    async componentDidMount() {
        segment.page('Compatibility Search');
        this.setState({
            loading: true
        })
        try {
            const compatibilityList = await this.context.getCompatibilityList();
            const makes = this.setMakes(compatibilityList);
            const ecuManufacturers = this.setManufacturers(compatibilityList);

            this.setState({
                compatibilityList,
                makes,
                ecuManufacturers,
                loading: false
            })
        } catch (error) {
            console.log(error)
            history.push('/projects')
        }
    }

    resetSearch = () => {
        this.setState({
            make: "",
            model: "",
            ecuManufacturer: "",
            ecuProtocol: "",
        })
    }

    formatFiles(int) {
        return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    setMakes(compatibilityList){
        const makes = {};

        compatibilityList.forEach((item, idx) => {
            if(makes[item.vehicleMake] === undefined) makes[item.vehicleMake] = {}
            let make = makes[item.vehicleMake]

            if(make[item.vehicleModel] === undefined) make[item.vehicleModel] = []
            make[item.vehicleModel].push(item)
        })
        return makes
    }

    updateMake = (make) => {
        this.setState({
            make,
            model: "",
        })
    };

    updateModel = (model) => {
        this.setState({
            model,
        });
    };

    setManufacturers = (compatibilityList) => {
        const ecuManufacturers = {};
        compatibilityList.forEach((item, idx) => {
            if(ecuManufacturers[item.ecuManufacturer] === undefined) ecuManufacturers[item.ecuManufacturer] = {}
            let ecuManufacturer = ecuManufacturers[item.ecuManufacturer]

            if(ecuManufacturer[item.ecuProtocol] === undefined) ecuManufacturer[item.ecuProtocol] = []
            ecuManufacturer[item.ecuProtocol].push(item)
        })
        return ecuManufacturers
    }

    updateEcuManufacturer = (ecuManufacturer) => {
        this.setState({
            ecuManufacturer,
            ecuProtocol: "",
        })
    }

    updateEcuProtocol = (ecuProtocol) => {
        this.setState({
            ecuProtocol,
        });
    }

    render() {
        return (
            !this.state.loading && this.state.compatibilityList ?
                <div className={styles.container}>
                    <Helmet><title>Compatibility Search - Speedweaver</title></Helmet>
                    <h1>Compatibility Search</h1>
                    <h3>{this.formatFiles(this.state.compatibilityList.length)} Files Supported</h3>
                    <Switch>
                        <Route
                            exact
                            path="/compatibility-search/ecu/:searchOptionOne/results"
                            render={(props) => (
                                <Results
                                    {...props}
                                    optionsArrayOne={this.state.ecuManufacturers}
                                    title="ECU"
                                    selectTagOne="Manufacturer"
                                    selectTagTwo="Protocol"
                                />
                            )}
                        />

                        <Route
                            path="/compatibility-search/ecu/:searchOptionOne/:searchOptionTwo/results"
                            render={(props) => (
                                <Results
                                    {...props}
                                    optionsArrayOne={this.state.ecuManufacturers}
                                    title="ECU"
                                    selectTagOne="Manufacturer"
                                    selectTagTwo="Protocol"
                                />
                            )}
                        />

                        <Route
                            path="/compatibility-search/ecu"
                            render={(props) => (
                                <SelectSearch
                                    optionsArrayOne={this.state.ecuManufacturers}
                                    searchOptionOne={this.state.ecuManufacturer}
                                    updateOptionOne={this.updateEcuManufacturer}
                                    searchOptionTwo={this.state.ecuProtocol}
                                    updateOptionTwo={this.updateEcuProtocol}
                                    resetSearch={this.resetSearch}
                                    title="ECU"
                                    searchNameOne="ecuManufacturer"
                                    searchNameTwo="ecuProtocol"
                                    selectTagOne="Manufacturer"
                                    selectTagTwo="Protocol"
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/compatibility-search/vehicle/:searchOptionOne/results"
                            render={(props) => (
                                <Results
                                    {...props}
                                    optionsArrayOne={this.state.makes}
                                    title="Vehicle"
                                    selectTagOne="Make"
                                    selectTagTwo="Model"

                                />
                            )}
                        />

                        <Route
                            path="/compatibility-search/vehicle/:searchOptionOne/:searchOptionTwo/results"
                            render={(props) => (
                                <Results
                                    {...props}
                                    optionsArrayOne={this.state.makes}
                                    title="Vehicle"
                                    selectTagOne="Make"
                                    selectTagTwo="Model"
                                />
                            )}
                        />

                        <Route
                            path="/compatibility-search/vehicle"
                            render={(props) => (
                                <SelectSearch
                                    optionsArrayOne={this.state.makes}
                                    searchOptionOne={this.state.make}
                                    updateOptionOne={this.updateMake}
                                    searchOptionTwo={this.state.model}
                                    updateOptionTwo={this.updateModel}
                                    resetSearch={this.resetSearch}
                                    title="Vehicle"
                                    searchNameOne="make"
                                    searchNameTwo="model"
                                    selectTagOne="Make"
                                    selectTagTwo="Model"
                                />
                            )}
                        />

                        <Route path="/compatibility-search" component={SearchCategory} />
                    </Switch> 
                </div>
                :
                    <Loading/>
        )
    }
}

export default CompatibilitySearch;
