import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";

import TextareaAutosize from 'react-autosize-textarea';
import Dropzone from "react-dropzone";

import styles from "dashboard/support/ticket/Ticket.module.scss";

class Reply extends Component {
    static contextType = Context;

    state = {
        uploading: false,
        comment: "",
        attachmentIds: [],
        attachmentNames: [],
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    fileUpload = (file) => {

        if(file.size > 6999999){
            this.context.setToast("You must upload a file smaller than 7MB.", "Danger", 10000);
            return false;
        }

        this.setState({
            uploading: true
        }, async () => {
            try {
                const attachment = await this.context.uploadAttachment(file);
                let attachmentIds = this.state.attachmentIds
                let attachmentNames = this.state.attachmentNames
                attachmentIds.push(attachment.id)
                attachmentNames.push(file.name)
                this.setState({ uploading: false });
            } catch(error) {
                this.setState({ uploading: false });
            }
        })
    };

    sendReply = async (event) => {
        event.preventDefault();
        await this.context.addComment(
            this.props.id,
            null,
            "PRIORITY_LOW",
            this.state.comment,
            this.state.attachmentIds.length > 0 ? this.state.attachmentIds : null
        );
        await segment.track('ticket_replied', {
            application: 'dashboard',
            ticketId: this.props.ticket.ticketType.id,
            ticketType: this.props.ticket.ticketType,
            projectId: this.props.ticket.project ? this.props.ticket.project : undefined,
            type: 'comment'
        });
        this.setState({
            comment: "",
            attachmentIds: [],
            attachmentNames: []
        });
    };

    removeAttachment = (idx) => {
        let attachmentIds = this.state.attachmentIds
        let attachmentNames = this.state.attachmentNames

        attachmentIds.splice(idx, 1)
        attachmentNames.splice(idx, 1)

        this.setState({
            attachmentIds,
            attachmentNames
        })
    }

    render() {
        let open = this.props.ticket.statusString === "STATUS_OPEN";
        let resolved = this.props.ticket.statusString === "STATUS_RESOLVED";
        let closed = this.props.ticket.statusString === "STATUS_CLOSED";
        return (
            <form
                className={`bgPrimary highlightSecondary ${styles.reply}`}
                onSubmit={this.sendReply}
            >
                {!resolved && !closed ?
                <div className="bgSecondary">
                    <TextareaAutosize
                        autoFocus={false}
                        rows={0}
                        name="comment"
                        value={this.state.comment}
                        onChange={this.handleChange}
                        placeholder="Type your reply here..."
                        maxLength={5000}
                    />
                </div>
                    
                :
                    null
                }

                {!open &&
                    <div className={styles.disclaimer}>
                        <p>
                            <img
                                src={require('images/icons/info.svg')}
                                alt="Disclaimer:"
                            />
                            {closed ?
                                'This ticket is closed and can no longer receive replies.'
                            :
                                'You must reopen this ticket to add new comments.'
                            }
                        </p>
                    </div>
                }

                <div className={styles.buttons}>
                    {closed ?
                        <span />
                    : resolved ?
                        <p className="btn btnBrand" onClick={this.props.openSolvedTicket.bind(this, this.props.ticket.id)}>
                            Mark as Open
                        </p>
                    :
                        <>
                            {this.state.attachmentNames.map((name, idx) => {
                                return (
                                    <div className={`highlightTertiary bgPrimary ${styles.attachment}`}>
                                        <div>
                                            <img src={require(`images/icons/${this.context.lightTheme ? "file-grey" : "file" }.svg`)} alt="Remove" />
                                            <p>{name}</p>
                                        </div>

                                        <img className={styles.cross} onClick={this.removeAttachment.bind(this, idx)} src={require(`images/icons/${this.context.lightTheme ? "cross-dark" : "cross" }.svg`)} alt="Remove" />
                                    </div>
                                )
                            })}

                            <div className={styles.splitButtons}>
                                {!this.state.uploading ?
                                    <Dropzone onDrop={acceptedFiles => this.fileUpload(acceptedFiles[0])}>
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps()}>
                                                <p className="bgSecondary btnOutline btn">Upload File</p>
                                                <input {...getInputProps()} />
                                            </div>
                                        )}
                                    </Dropzone>
                                    :
                                    <button className="btn btnOutline bgTertiary" disabled>Uploading...</button>
                                }
                                <button className="btn btnBrand" disabled={!this.state.comment.length > 0}>Send</button>
                            </div>

                            <p className="bgSecondary btn btnOutline" onClick={this.props.solveTicket.bind(this, this.props.ticket.id)}>
                                <img
                                    src={require(`images/icons/${this.context.lightTheme ? "tick" : "tick-white"}.svg`)}
                                    alt="Disclaimer:"
                                />
                                Mark as Solved
                            </p>
                        </>
                    }
                </div>
            </form>
        )
    }
}

export default Reply;
