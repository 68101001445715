import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import history from 'history/history';
import { Context } from 'context/context';
import segment from "segment";

import PasswordInput from 'components/PasswordInput';

import styles from 'entry/Entry.module.scss';

class ResetPassword extends Component {
    static contextType = Context;

    state = {
        password: "",
        error: false,
        complete: false
    };

    async componentDidMount() {
        segment.page('Reset Password');

        try {
            await this.context.checkResetValid(this.props.match.params.hash, this.props.match.params.userId)
        } catch(error) {
            history.push("/log-in");
        }
    }

    /**
     * When password field is changed, update state
     * @param {object} event - onChange Event
     */
    updateStatePassword = (event) =>{
        this.setState({
            [event.target.name]: event.target.value,
            error: false,
            passwordStrength: event.target.strength
        })
    };

    /**
     * Calls resetPassword function in context and handles response
     * @param {Object} event
     */
    resetPassword = async (event) => {
        try {
            event.preventDefault();
            await this.context.resetPassword(this.props.match.params.hash, this.props.match.params.userId, this.state.password);
            await segment.track('password_changed', {
                application: 'dashboard',
                type: 'forgotten'
            });
            history.push("/log-in");
        } catch(error) {
            this.setState({ error: true })
        }
    };

    render() {
        let submit_text = this.state.error ? "Something went wrong. Try again" : "Reset Password";
        const submit_class = `btn ${this.state.error ? "btnDanger" : "btnBrand"}`;

        return (
            <>
                <div className={`bgSecondary ${styles.action}`}>
                    <NavLink to="/log-in" className="btn btnBrand">Log In</NavLink>
                </div>
                <div className={`bgSecondary ${styles.resetPassword}`}>
                    <form onSubmit={this.resetPassword}>
                        <h1>Reset Password</h1>
                        <PasswordInput autoComplete={false} name="password" placeholder="Choose Password" value={this.state.password} onChange={this.updateStatePassword}/>
                        <input disabled={this.state.passwordStrength < 4} type="submit" value={submit_text} className={submit_class}/>
                    </form>
                </div>
            </>
        )
    }
}

export default ResetPassword;

