import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import history from 'history/history';
import { Context } from 'context/context';
import segment from "segment";

import styles from 'entry/Entry.module.scss';

class ForgotPassword extends Component {
    static contextType = Context;

    state = {
        email: "",
        error: false,
        complete: false
    };

    componentDidMount() {
        segment.page('Forgot Password');
    }

    /**
     * Update State
     * @param {Object} event
     */
    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: false
        })
    };

    /**
     * Calls requestPasswordReset function in context and handles response
     * @param {Object} event
     * @todo this needs to be built by carl and readjusted.
     */
    requestPasswordReset = async (event) => {
        try {
            event.preventDefault();

            await this.context.requestPasswordReset(this.state.email);
            await segment.track('password_forgotten', {
                application: 'dashboard'
            });
            history.push("/log-in");
        } catch(error) {
            this.setState({
                error: true
            })
        }
    };

    render() {
        let submit_text = this.state.error ? "Something went wrong. Try again" : "Send Password Reset Email";

        const submit_class = `btn ${this.state.error ? "btnDanger" : "btnBrand"}`;

        return (
            <>
                <div className={`bgSecondary ${styles.action}`}>
                    <NavLink to="/log-in" className="btn btnBrand">Log In</NavLink>
                </div>
                <div className={`bgSecondary ${styles.forgotPassword}`}>
                    <form onSubmit={this.requestPasswordReset}>
                        <h1>Forgot Password</h1>
                        <input type="email" name="email" placeholder="Email Address" value={this.state.email} onChange={this.updateState}/>
                        <input type="submit" value={submit_text} className={submit_class} disabled={!this.state.email}/>
                    </form>
                </div>
            </>
        )
    }
}

export default ForgotPassword;

