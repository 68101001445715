import React, { Component } from 'react';
import { Context } from 'context/context';

import styles from 'dashboard/compatibilitySearch/CompatibilitySearch.module.scss';
import {Link} from "react-router-dom";
import Back from "./Back";
import history from "../../../history/history";
import sortObjectsArray from "sort-objects-array";

class SelectSearch extends Component {
    static contextType = Context;

    state = {
        disabled: true
    };

    componentDidMount() {
        this.props.resetSearch()
    }

    render() {
        let valid = true;
        const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
        if(!regex.test(this.props.searchOptionOne)) valid = false;
        if(!regex.test(this.props.searchOptionTwo)) valid = false;

        return (
            <>
                <Back />

                <div className={styles.actions}>
                    <h1>Search By: {this.props.title}</h1>
                    <div>
                        <select className={`bgSecondary ${styles.select}`} value={this.props.searchOptionOne} name={this.props.searchNameOne} onChange={event => this.props.updateOptionOne(event.target.value)}>
                            <option disabled selected value="">Select {this.props.selectTagOne}...</option>
                            {Object.keys(this.props.optionsArrayOne).sort().map((searchIndexOne, idx) => {
                                let count = 0;
                                Object.values(this.props.optionsArrayOne[searchIndexOne]).forEach((searchIndexTwo) => {
                                    count = count + searchIndexTwo.length;
                                });

                                return <option key={idx} value={searchIndexOne}>{`${searchIndexOne} (${count})`}</option>

                            })}
                        </select>

                        <select className={`bgSecondary ${styles.select} ${!regex.test(this.props.searchOptionOne) && "disabled"}`} value={this.props.searchOptionTwo} name={this.props.searchNameTwo} disabled={!regex.test(this.props.searchOptionOne)} onChange={event => this.props.updateOptionTwo(event.target.value)}>
                            <option disabled selected value="">Select {this.props.selectTagTwo}...</option>
                            {this.props.searchOptionOne &&
                            Object.keys(this.props.optionsArrayOne[this.props.searchOptionOne]).sort().map((searchIndexTwo, idx) => {
                                let count = 0;
                                count = count + this.props.optionsArrayOne[this.props.searchOptionOne][searchIndexTwo].length

                                return <option key={idx} value={searchIndexTwo}>{`${searchIndexTwo} (${count})`}</option>

                            })
                            }
                        </select>
                    </div>

                    <button
                        className="btn btnBrand"
                        disabled={!valid}
                        onClick={() => {
                            history.push(`/compatibility-search/${this.props.title.toLowerCase()}/${this.props.searchOptionOne}/${this.props.searchOptionTwo}/results`)
                        }}
                    >
                        Search
                    </button>
                </div>
            </>
        )
    }
}

export default SelectSearch;