import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Context } from 'context/context';
import segment from "segment";

import TuningOptions from "./TuningOptions";
import Wizards from "dashboard/projects/project/components/Wizards";
import Reset from "dashboard/projects/project/components/Reset";

class Tuning extends Component {
    static contextType = Context;

    state = {
        bases: [],
        patches: [],
        selected: null,
        base: null,
        success: false,
        applying: false,
        isAPurchaseRequired: false,
    }

    async componentDidMount() {
        segment.page('Project > Tuning');
    }

    render() {
        return (
            <Switch>
                <Route path="/projects/:id/tuning/automated-tuning">
                    <Wizards {...this.props}  />
                </Route>

                <Route exact path="/projects/:id/tuning/reset-modifications">
                    <Reset {...this.props}/>
                </Route>

                <Route path="/projects/:id/tuning">
                    <TuningOptions {...this.props}/>
                </Route>
            </Switch>
        )
    }
}

export default Tuning;
