import React, { Component } from 'react';
import { Context } from 'context/context';

import DemoFileButton from 'dashboard/projects/project/components/DemoFileButton'
import CreateProjectButton from 'dashboard/projects/project/components/CreateProjectButton'

import styles from 'dashboard/Dashboard.module.scss';

class ProjectButtons extends Component {

    static contextType = Context;

    render() {
        if(this.props.slaveDetected && this.props.hasSlaveDetector){
            return (
              <div className={styles.slaveWarning}>  
                  <div>
                      <img src={require('images/icons/warning-danger.svg')} alt={""} />
                      <p className="txtPrimary"><b>Slave File Warning:</b> The system does not currently accept slave files. Please upload an unencrypted file from a master tool.</p>
                  </div>
                  <div className={`${styles.slaveWarningButton} btn`} onClick={() => this.props.toggleSlaveDetected(false)}>
                    OK
                  </div>
              </div>
            )
        } else {
            return (
              <div className={styles.projectButtons}>
                <DemoFileButton fetchProjects={this.fetchProjects}/>
                <CreateProjectButton fetchProjects={this.fetchProjects} onSlaveDetected={() => this.props.toggleSlaveDetected(true)}/>
              </div>
            )
        }
    }
}

export default ProjectButtons;
