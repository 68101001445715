import React, { Component } from 'react';

import styles from "components/Components.module.scss";

export default class Loading extends Component {
    render() {
        return (
            <div className={styles.loading}>
                <div>
                    <img src={require('images/animated/loading.svg')} alt={""} />
                    <p>{this.props.text ? this.props.text : "Loading..."}</p>
                </div>
            </div>
        );
    }
}