import React, { Component } from 'react';

import styles from '../Billing.module.scss';

class Footer extends Component {
    getYear() {
        return (
            new Date().getFullYear()
        );
    }

    render() {
        return (
            <div className={styles.footer}>
                <div className={styles.container}>
                    <p>&copy; {this.getYear()} IDX Digital Limited. All Rights Reserved. All prices exclude local VAT if applicable.</p>
                    <a href="mailto:support@speedweaver.co.uk">support@speedweaver.co.uk</a>
                </div>
            </div>
        )
    }
}

export default Footer;