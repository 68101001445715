import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import styles from 'dashboard/compatibilitySearch/CompatibilitySearch.module.scss';

class SearchCategory extends Component {
    static contextType = Context;

    render() {
        return (
            <div className={styles.actions}>
                <h1>Search By:</h1>
                <div>
                    <NavLink className={`highlightTertiary bgSecondary ${styles.selectOption}`} to="/compatibility-search/ecu">
                        <img src={require(`images/icons/${this.context.lightTheme ? "ecu-grey" : "ecu"}.svg`)} alt="Speedweaver logo"/>
                        <h2>ECU</h2>
                    </NavLink>

                    <NavLink className={`highlightTertiary bgSecondary ${styles.selectOption}`} to="/compatibility-search/vehicle">
                        <img src={require(`images/icons/${this.context.lightTheme ? "vehicle-grey" : "vehicle"}.svg`)} alt="Speedweaver logo"/>
                        <h2>Vehicle</h2>
                    </NavLink>
                </div>
            </div>
        )
    }
}

export default SearchCategory;
