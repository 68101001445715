import React, { Component } from 'react';
import history from 'history/history';
import { Context } from 'context/context';
import segment from "segment";
import clone from 'clone';
import Activation from "dashboard/projects/project/Activation";
import Active from "dashboard/projects/project/Active";
import Rejected from "dashboard/projects/project/Rejected";
import Error from "dashboard/projects/project/Error";
import Loading from "components/Loading";
import styles from "dashboard/projects/project/Project.module.scss";
import Paywall from "../../../components/Paywall";

class Project extends Component {
    static contextType = Context;

    state = {
        selected: false,
        loading: false,
        totalProjects: clone(this.context.totalProjects),
    };

    async componentDidMount() {
        segment.page('Project', {
            project: this.props.match.params.id
        });

        await this.fetchProject();
    }

    async componentDidUpdate(prevProps){
        if(this.props.match.params.id !== prevProps.match.params.id) {
            await this.fetchProject();
        }
        if(this.state.totalProjects !== this.context.totalProjects) {
            let contextProject = this.context.getProjectIndex(this.props.match.params.id);
            this.setState({
                projectIndex: contextProject,
                contextProject,
                totalProjects: clone(this.context.totalProjects)
            })
        }
    }

    fetchProject = async () => {
        try {
            if(this.props.match.params.id){
                this.setState({
                    selected: true,
                    loading: true
                });

                let contextProject = this.context.getProjectIndex(this.props.match.params.id);
                const project = this.context.projects[contextProject]

                if((project.tunerStatus !== "error" &&
                    project.tunerStatus !== "rejected")
                ) {
                    await this.context.getAppliedWizards(this.props.match.params.id);
                }
                
                let fileName = "";
                if(project.originalFileName){
                    fileName = project.originalFileName.split("_");
                    fileName.shift();

                    if(fileName === "blob") fileName = "Demo File";
                }

                project.fileName = fileName;

                this.setState({
                    ...this.state,
                    projectIndex: contextProject,
                    loading: false,
                    contextProject,
                    selected: true
                })
            } else {
                this.setState({
                    selected: false,
                    loading: false
                })
            }
        } catch {
            history.push("/projects");
        }
    };

    deleteProject = async () => {
        this.setState({
            deletingProject: true
        });

        if(window.confirm("Are you sure you wish to delete this project? This action can not be undone.")){
            await segment.track('project_deleted', {
                application: 'dashboard',
                projectId: this.context.projects[this.state.projectIndex].id,
                projectType: this.context.projects[this.state.projectIndex].projectType === "DEMO" ? "demo" : "user"
            });
            await this.context.deleteProject(this.context.projects[this.state.projectIndex].id);
            history.push('/projects')
        }

        this.setState({
            deletingProject: false
        });
    };

    render() {
        const project = this.context.projects[this.state.projectIndex]
        if(this.state.selected){
            return (
                <>


                    <div className={`${styles.project} highlightTertiary ${this.state.selected ? "bgSecondary" : null}`}>
                        {!this.state.loading ?
                            project.tunerStatus === "ready" || project.tunerStatus === "archived" ?
                                project.activated  ?
                                    <Active {...this.state} 
                                        fetchProject={this.fetchProject} 
                                        fetchProjects={this.props.fetchProjects} 
                                        deleteProject={this.deleteProject} 
                                        archiveState={this.archiveState} 
                                    />
                                :
                                    <Activation {...this.state} 
                                        fetchProject={this.fetchProject} 
                                        fetchProjects={this.props.fetchProjects} 
                                        deleteProject={this.deleteProject}
                                    />
                            :
                                project.tunerStatus === "error" || project.tunerStatus === "rejected" ?
                                    <Rejected {...this.state}
                                        deleteProject={this.deleteProject}
                                    />
                                :
                                    <Error {...this.state}
                                       deleteProject={this.deleteProject}
                                    />
                            :
                            <Loading/>
                        }
                    </div>
                </>
            )
        } else {
            return false;
        }
    }
}

export default Project;
