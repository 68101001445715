import React, { Component } from 'react';
import Config from "Config";
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import ExportSuccess from 'dashboard/projects/project/components/ExportSuccess'
import styles from 'dashboard/projects/project/Project.module.scss';

class TuningOptions extends Component {
    static contextType = Context;

    state = {
        loading: true,
        wizards: false,
        reset: false,
        exporting: false,
    };


    render() {
        return (
            !this.props.hasExported?
                <>
                <div className={`bgSecondary ${styles.content}`}>
                    <div className={styles.tuning}>

                        {this.props.project.tunerStatus === "archived" ?
                            <div className={`highlightTertiary ${styles.disabled}`}>
                                <img src={require(`images/icons/${this.context.lightTheme ? "templates-grey" : "templates"}.svg`)} alt="" />
                                <div>
                                    <h3>Apply Stage Templates & Addons</h3>
                                    <p className="txtSecondary">This feature is disabled when a project has been archived. Goto 'Details' to un-archive.</p>
                                </div>
                            </div>
                            : this.props.project.modifiedFromOriginal === false ?
                                <NavLink className="highlightTertiary bgTertiary" to={`/projects/${this.props.project.id}/tuning/automated-tuning`}>
                                    <img src={require(`images/icons/${this.context.lightTheme ? "templates-grey" : "templates"}.svg`)} alt="" />
                                    <div>
                                        <h3>Apply Stage Templates & Addons</h3>
                                        <p className="txtSecondary">Instantly apply pre-made tuning options to your file.</p>
                                    </div>
                                </NavLink>
                                :
                                <div className={`highlightTertiary ${styles.disabled}`}>
                                    <img src={require(`images/icons/${this.context.lightTheme ? "templates-grey" : "templates"}.svg`)} alt="" />
                                    <div>
                                        <h3>Apply Stage Templates & Addons</h3>
                                        <p className="txtSecondary">This feature is disabled when a project has been detected as modified.</p>
                                    </div>
                                </div>
                        }

                        {this.props.project.tunerStatus === "archived" ?
                            <div className={`highlightTertiary ${styles.disabled}`}>
                                <img src={require(`images/icons/${this.context.lightTheme ? "editor-grey" : "editor"}.svg`)} alt="" />
                                <div>
                                    <h3>Manual Tuning</h3>
                                    <p className="txtSecondary">This feature is disabled when a project has been archived. Goto 'Details' to un-archive</p>
                                </div>
                            </div>
                            :
                            <a className="highlightTertiary bgTertiary" href={`${Config.editor}/${this.props.project.id}`}>
                                <img src={require(`images/icons/${this.context.lightTheme ? "editor-grey" : "editor"}.svg`)} alt="" />
                                <div>
                                    <h3>Open in Editor</h3>
                                    <p className="txtSecondary">Custom tune your file or make adjustments to tuned projects.</p>
                                </div>
                            </a>
                        }

                        {this.props.project.tunerStatus === "archived" ?
                            <div className={`highlightTertiary ${styles.disabled}`}>
                                <img src={require(`images/icons/${this.context.lightTheme ? "revert-grey" : "revert"}.svg`)} alt=""/>
                                <div>
                                    <h3>Reset Modifications</h3>
                                    <p className="txtSecondary">Revert your file back to its stock or uploaded state.</p>
                                </div>
                            </div>
                            :
                            <NavLink className="highlightTertiary bgTertiary" to={`/projects/${this.props.project.id}/tuning/reset-modifications`}>
                                <img src={require(`images/icons/${this.context.lightTheme ? "revert-grey" : "revert"}.svg`)} alt=""/>
                                <div>
                                    <h3>Reset Modifications</h3>
                                    <p className="txtSecondary">Revert your file back to its stock or uploaded state.</p>
                                </div>
                            </NavLink>
                        }
                    </div>                    
                </div>

                <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                    <button className='btn btnOutline bgSecondary' disabled={this.props.exporting} onClick={this.props.downloadOriginal}>
                        <img src={require(`images/icons/${this.context.lightTheme? 'download-grey' : 'download'}.svg`)} alt="" />
                        Download Original File
                    </button>

                    <button className="btn btnBrand" onClick={this.props.export} disabled={this.props.exporting || !this.props.project.modified || this.props.project.tunerStatus === "archived"}>
                        {this.props.exporting ?
                            <>
                                <img src={require('images/animated/processing.svg')} alt="Processing" />
                                Exporting File...
                            </>
                            :
                            <>
                                <img src={require('images/icons/download.svg')} alt="" />
                                Export Modified File
                            </>
                        }
                    </button>

                    {this.props.project.tunerStatus === "archived" ?
                        <NavLink to={`/projects`} className="btn btnBrand" onClick={this.props.unArchive}>
                            <img src={require('images/icons/unarchive.svg')} alt="" />
                            Un-Archive File
                        </NavLink>
                        :
                        null
                    }
                </div>
            </>
            :
                <ExportSuccess fetchProject={this.props.fetchProject} id={this.props.project.id} onClose={this.props.onCloseExportSuccess}/>   
        )
    }
}

export default TuningOptions;
