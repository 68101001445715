import Config from "Config";

import axios from "axios";
import Cookies from "js-cookie";

export default () => {
    const options = {
        baseURL: Config.api,
        headers: {}
    };

    if(Cookies.get("token")) options.headers.Authorization = `Bearer ${Cookies.get("token")}`;

    return axios.create(options);
};