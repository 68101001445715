import * as TicketsRepository from '../repository/TicketsRepository'

export const getTickets = async (perPage) => {
    const res = await TicketsRepository.getTickets(perPage);
    return {tickets: res.data["hydra:member"], totalItems: res.data["hydra:totalItems"]};
};

export const getTicket = async (id) => {
    return await TicketsRepository.getTicket(id);
};

export const getComments = async (id) => {
    return await TicketsRepository.getComments(id);
};

export const createTicket = async (ticketType, priority, subject, notes, project, attachments) => {
    return await TicketsRepository.createTicket(ticketType, priority, subject, notes, project, attachments);
};

export const uploadAttachment = async (file) => {
    return await TicketsRepository.uploadAttachment(file);
};

export const addComment = async (ticketId, status, priority, message, attachments) => {
    return await TicketsRepository.addComment(ticketId, status, priority, message, attachments);
};

export const solveTicket = async (ticketId) => {
    return await TicketsRepository.solveTicket(ticketId);
};

export const openSolvedTicket = async (ticketId) => {
    return await TicketsRepository.openSolvedTicket(ticketId);
};
