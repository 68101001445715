import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";

import styles from './Forms.module.scss';

class Details extends Component {
    static contextType = Context;

    state = {
        loading: true,
        processing: false,
        firstName: "",
        lastName: ""
    };

    componentDidMount() {
        this.setState({
            firstName: this.context.user.firstName,
            lastName: this.context.user.lastName,
            loading: false
        })
    }

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            processing: true
        });

        try {
            let valid = true;
            let errors = [];

            const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
            if(!regex.test(this.state.firstName)) valid = false;
            if(!regex.test(this.state.lastName)) valid = false;

            if(valid){
                await this.context.updateUser({
                    firstName: this.state.firstName,
                    lastName: this.state.lastName
                });

                await segment.track('account_updated', {
                    application: 'dashboard',
                    type: 'name'
                });

                this.setState({
                    processing: false
                });
            } else {
                this.setState({
                    errors,
                    processing: false
                })
            }
        } catch(error) {
            this.setState({
                processing: false,
                firstName: this.context.user.firstName,
                lastName: this.context.user.lastName
            });
        }
    };

    render() {
        if(!this.state.loading){

            let disabled = true;
            let errors = [];
            const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
            if(!regex.test(this.state.firstName)) errors.push("firstName");
            if(!regex.test(this.state.lastName)) errors.push("lastName");

            if(this.state.firstName !== this.context.user.firstName) disabled = false;
            if(this.state.lastName !== this.context.user.lastName) disabled = false;

            if(errors.length > 0) disabled = true;
            if(this.state.processing) disabled = true;

            return (
                <div className={`highlightTertiary ${styles.form}`}>
                    <div>
                        <h3>Personal Details</h3>
                    </div>
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <input type="text" name="firstName" className={`bgTertiary ${errors.includes("firstName") ? "error" : null}`} placeholder="First name..." value={this.state.firstName} onChange={this.updateState} maxLength={255} />
                            <input type="text" name="lastName" className={`bgTertiary ${errors.includes("lastName") ? "error" : null}`} placeholder="Last name..." value={this.state.lastName} onChange={this.updateState} maxLength={255} />
                            <input type="submit" disabled={disabled} className="btn btnBrand" value={this.state.processing === true ? "Updating Details..." : "Change Name"} />
                        </form>
                    </div>
                </div>
            )
        } else {
            return false;
        }
    }
}

export default Details;
