import React, { Component } from 'react';
import { Context } from 'context/context';

import styles from 'dashboard/compatibilitySearch/CompatibilitySearch.module.scss';
import {Link} from "react-router-dom";
import history from "history/history";
import sortObjectsArray from 'sort-objects-array'
import equal from 'fast-deep-equal';

import Back from "./Back";
class Results extends Component {
    static contextType = Context;

    state = {
        searchedCompList: [],
        searchTerm: "",
    }

    componentDidMount() {
        this.makeSortedList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            this.props.match.params.searchOptionOne !== prevProps.match.params.searchOptionOne ||
            this.props.match.params.searchOptionTwo !== prevProps.match.params.searchOptionTwo
        ) {
            this.makeSortedList();
            this.resetSearch()
        }

        if(this.state.searchTerm !== prevState.searchTerm) this.makeSortedList();
    }

    updateValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    makeSortedList = () => {
        let sortedCompList = [];
        if(this.props.match.params.searchOptionTwo) {
            sortedCompList = sortObjectsArray(this.props.optionsArrayOne[this.props.match.params.searchOptionOne][this.props.match.params.searchOptionTwo], 'vehiclePowertrain', "asec")
            sortedCompList = sortObjectsArray(sortedCompList, 'vehicleModel', "asec")
            sortedCompList = sortObjectsArray(sortedCompList, 'vehicleMake', "asec")
        }

        this.setState({
            searchedCompList: this.searchCompList(sortedCompList)
        })
    }

    searchCompList(sortedCompList) {
        let searchedCompList = sortedCompList
        if(sortedCompList.length > 0) {
            searchedCompList = sortedCompList.filter((option, idx) => {
                return (
                    option.vehicleMake.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                    option.vehicleModel.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                    option.vehiclePowerTrain.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                    option.fuelType.toString().includes(this.state.searchTerm.toLowerCase())
                )
            })
        }

        return searchedCompList
    }

    resetSearch = () => {
        this.setState({
            searchTerm: ""
        })
    }

    updateOptionOne(searchOptionOne) {
        history.push(`/compatibility-search/${this.props.title.toLowerCase()}/${searchOptionOne}/results`)
    }

    updateOptionTwo(searchOptionTwo) {
        history.push(`/compatibility-search/${this.props.title.toLowerCase()}/${this.props.match.params.searchOptionOne}/${searchOptionTwo}/results`)
    }

    render() {
        return (
            <>
                <Back />
                <div className={styles.tableContainer}>
                    <div className={styles.searchOptions}>
                        <select className="bgSecondary" value={this.props.match.params.searchOptionOne} name={this.props.searchNameOne} onChange={event => this.updateOptionOne(event.target.value)}>
                            <option disabled selected value="">Select {this.props.selectTagOne}...</option>
                            {Object.keys(this.props.optionsArrayOne).sort().map((searchIndexOne, idx) => {
                                let count = 0;
                                Object.values(this.props.optionsArrayOne[searchIndexOne]).forEach((searchIndexTwo) => {
                                    count = count + searchIndexTwo.length;
                                });

                                return <option key={idx} value={searchIndexOne}>{`${searchIndexOne} (${count})`}</option>

                            })}
                        </select>

                        <select className="bgSecondary" value={this.props.match.params.searchOptionTwo ? this.props.match.params.searchOptionTwo : ""} name={this.props.searchNameTwo} onChange={event => this.updateOptionTwo(event.target.value)}>
                            <option disabled selected value="">Select {this.props.selectTagTwo}...</option>
                            {this.props.match.params.searchOptionOne &&
                                Object.keys(this.props.optionsArrayOne[this.props.match.params.searchOptionOne]).sort().map((searchIndexTwo, idx) => {
                                    let count = 0;
                                    count = count + this.props.optionsArrayOne[this.props.match.params.searchOptionOne][searchIndexTwo].length

                                    return <option key={idx} value={searchIndexTwo}>{`${searchIndexTwo} (${count})`}</option>

                                })
                            }
                        </select>

                        <input
                            type="text"
                            className={`bgSecondary ${!this.props.match.params.searchOptionTwo && 'disabled'}`}
                            name="searchTerm"
                            placeholder="Search..."
                            value={this.state.searchTerm}
                            onChange={this.updateValue.bind(this)}
                            disabled={!this.props.match.params.searchOptionTwo}
                        />


                        {this.props.match.params.searchOptionTwo &&
                            <p>{this.state.searchedCompList.length} Match{this.state.searchedCompList.length !== 1 ? "es" : null}</p>
                        }
                    </div>

                    <div className={`highlightTertiary ${styles.table}`}>
                        <div className={`highlightTertiary bgTertiary ${styles.result} ${styles.headings}`}>
                            <p>Make</p>
                            <p>Model</p>
                            <p>Powertrain</p>
                            <p>Fuel Type</p>
                        </div>

                        <div className={`highlightTertiary ${styles.scrollArea}`}>
                            {this.state.searchedCompList.length > 0 ?
                                this.state.searchedCompList.map((option, idx) => {
                                    return (
                                        <div key={idx} className={`bgSecondary highlightTertiary ${styles.result}`}>
                                            <p>{option.vehicleMake}</p>
                                            <p>{option.vehicleModel}</p>
                                            <p>{option.vehiclePowerTrain}</p>
                                            <p>{option.fuelType}</p>
                                        </div>
                                    )
                                })
                            : this.props.match.params.searchOptionTwo ?
                                <div className={`highlightTertiary ${styles.center}`}>
                                    <p>No Results Found</p>
                                </div>
                            :
                                <div className={`highlightTertiary ${styles.center}`}>
                                    <p>Please Select {this.props.title} {this.props.selectTagTwo}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Results;