import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";


class CreateProjectButton extends Component {
    static contextType = Context;

    state = {
        loading: false
    };

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    fileUpload = async () => {
        const file = this.uploader.files[0];

        if(file.size > 15000000) {
            this.context.setToast("Files must be under 12Mb", "Danger", 10000);
            return false;
        }

        await this.upload(file);
    };

    upload = async (file) => {
        this.setState({
            loading: true
        });
        try {
            const project = await this.context.uploadFile(file);
            await segment.track("project_created", {
                application: 'dashboard',
                projectId: project.data.project.split('/')[3],
                projectType: 'user'
            });
            await this.props.fetchProjects();
            this.setState({
                loading: false
            });
        } catch {
            this.setState({
                loading: false
            });
        }
    };

    render() {
        if(!this.state.loading){
            return (
                <label className={"btn btnBrand"}>
                    + Create Project
                    <input
                        type="file"
                        ref={(ref) => this.uploader = ref}
                        onChange={this.fileUpload.bind(this)}
                        style={{display: "none"}}
                    />
                </label>
            )
        } else {
            return <button disabled={true} className="btn btnBrand">Uploading...</button>
        }
    }
}

export default CreateProjectButton;
