import React, { Component } from 'react';
import {Link, NavLink, Route, Switch} from "react-router-dom";
import history from "history/history";
import { Helmet } from "react-helmet";
import { Context } from 'context/context';
import segment from "segment";

import Request from "./components/NewTickets/Request";
import General from "./components/NewTickets/General";
import ProjectSelect from "./components/NewTickets/ProjectSelect";
import NewTicket from "./components/NewTickets/NewTicket";
import WizardRequest from "./components/NewTickets/WizardRequest";
import RejectedRequest from "./components/NewTickets/RejectedRequest";

import Mousetrap from 'mousetrap';

import styles from "dashboard/support/ticket/Ticket.module.scss";

class New extends Component {
    static contextType = Context;

    state = {
        processing: false,
        // loading: true,
        ticketType: "general",
        subject: "",
        projectId: "",
        notes: "",
        errors: [],
        checked: false,
        purchased: false,
    };

    async componentDidMount() {
        await segment.page('New Support Tickets');

        await segment.track('ticket_intended', {
            application: 'dashboard',
        });

        Mousetrap.bind("esc", this.close);
    }

    componentWillUnmount(){
        Mousetrap.unbind("esc");
    }

    /**
     * @param {string} ticketType
     * @param {string} subject
     * @param {string} notes
     * @param {string} projectId
     * @param {array} attachmentIds
     */
    handleSubmit = async (ticketType, subject, notes, projectId, attachmentIds) => {
        try {
            this.setState({
                processing: true
            });


            let errors = [];
            if(!ticketType.length > 0) errors.push("ticketType");
            if(!subject.length > 0) errors.push("subject");
            if(!notes.length > 0) errors.push("notes");

            this.setState({ errors });

            if(errors.length > 0) {
                this.setState({
                    processing: false,
                });

                return;
            }

            let project = null;
            if(projectId !== "") project = `/api/projects/${projectId}`;


            const ticket = await this.context.createTicket(ticketType, "PRIORITY_LOW", subject, notes, project, attachmentIds);
            await segment.track('ticket_created', {
                application: 'dashboard',
                ticketId: ticket.id,
                ticketType: ticketType,
                projectId: projectId ? projectId : undefined
            });
            this.setState({
                processing: false,
            });
            history.push(`/support-tickets/ticket/${ticket.id}`);
        } catch(err) {
            console.log(err)
            this.setState({
                processing: false
            });
        }
    };

    close = () => {
        history.push("/support-tickets");
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>Open New Support Ticket - Speedweaver</title>
                </Helmet>
                <div className={`bgSecondary highlightSecondary ${styles.newContainer} ${styles.container}`}>
                    <div className={`bgTertiary highlightSecondary ${styles.newHead} ${styles.head}`}>
                        <NavLink className={`${styles.new} ${styles.sub}`} to="/support-tickets/new-ticket">Open New Support Ticket</NavLink>
                        <div className={styles.actions}>
                            <NavLink className={styles.new} to="/support-tickets"><img src={require(this.context.lightTheme? 'images/icons/cross-dark.svg' : 'images/icons/cross.svg')} alt="Close" /></NavLink>
                        </div>
                    </div>
                    <Switch>
                        <Route
                            path="/support-tickets/new-ticket/request/:id"
                            render={(props) => (
                                <WizardRequest
                                    {...props}
                                    handleSubmit={this.handleSubmit}
                                    ticketType="wizard_request"
                                />
                            )}
                        />

                        <Route
                            exact path="/support-tickets/new-ticket/request/"
                            render={(props) => (
                                <Request
                                    {...props}
                                    handleSubmit={this.handleSubmit}
                                    ticketType="wizard_request"
                                />
                            )}
                        />

                        <Route
                            path="/support-tickets/new-ticket/rejected/:projectId/"
                            render={(props) => (
                                <RejectedRequest
                                    {...props}
                                    handleSubmit={this.handleSubmit}
                                    ticketType="rejected_binary"
                                />
                            )}
                        />

                        <Route path="/support-tickets/new-ticket/rejected"
                            render={(props) => (
                                <ProjectSelect
                                    {...props}
                                    handleSubmit={this.handleSubmit}
                                    ticketType="rejected_binary"
                                    filter={project => project.tunerStatus === "rejected"}
                                    linkTo={project => `/support-tickets/new-ticket/rejected/${project.id}/`}
                                />
                            )}
                        />

                        <Route path="/support-tickets/new-ticket/report/project/:projectId/"
                               render={(props) => (
                                   <General
                                       {...props}
                                       handleSubmit={this.handleSubmit}
                                       ticketType="technical"
                                   />
                               )}
                        />

                        <Route path="/support-tickets/new-ticket/report/general/"
                               render={(props) => (
                                   <General
                                       {...props}
                                       handleSubmit={this.handleSubmit}
                                       ticketType="technical"
                                   />
                               )}
                        />

                        <Route path="/support-tickets/new-ticket/report"
                            render={(props) => (
                                <ProjectSelect
                                    {...props}
                                    handleSubmit={this.handleSubmit}
                                    ticketType="technical"
                                    filter={project => project.tunerStatus !== "rejected"}
                                    linkTo={project => `/support-tickets/new-ticket/report/project/${project.id}/`}
                                    skipTo="/support-tickets/new-ticket/report/general/"
                                />
                            )}
                        />

                        <Route path="/support-tickets/new-ticket/general"
                            render={(props) => (
                                <General
                                    {...props}
                                    handleSubmit={this.handleSubmit}
                                    ticketType="general"
                                />
                            )}
                        />

                        <Route path="/support-tickets/new-ticket/" component={NewTicket} />
                    </Switch>
                </div>
            </>
        )
    }
}

export default New;
