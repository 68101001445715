import React, { Component } from 'react';

import Security from "./Security";
import Subscription from "./products/Subscription";

import styles from '../../Billing.module.scss';

class Product extends Component {
    render() {
        if(!this.props.update){
            return (
                <div className={`${styles.product} ${styles.right}`}>
                    {this.props.subscribe && <Subscription {...this.props} />}
                    <Security />
                </div>
            )
        } else {
            return false;
        }
    }
}

export default Product;