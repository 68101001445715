import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";
import Cookies from 'js-cookie';

import { TokenInput } from 'components/TokenInput';

import styles from 'entry/Entry.module.scss';

class VerifyAccount extends Component {
    static contextType = Context;

    state = {
        code: null,
        password: "",
        error: false,
        complete: false,
        codeInputFull: false
    };

    componentDidMount() {
        segment.page('Verify Account');
    }

    /**
     * Calls logout function in context and handles response; either redirect or error toast
     * @param {Object} event - clicking logout button
     */
    logout = (event) => {
        event.preventDefault();
        if (!this.context.logout()) {
            this.context.setToast('Logout unsuccessful', 'Danger', 10000);
        }
    };

    /**
     * Update State
     * @param {Object} event
     */
    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: false,
            codeInputFull: event.target.full
        })
    };

    /**
     * Calls verifyEmailAddress function in context and handles response
     * @param {Object} event
     */
    verifyEmailAddress = async (event) => {
        try {
            event.preventDefault();
            await this.context.verifyEmailAddress(this.state.code);
            await segment.track('account_verified', {
                application: 'dashboard'
            });

/*
            if(Cookies.get("pro-intention")){
                window.location.href = '/billing/subscribe/?signUp=true';
            } else {
                window.location.href = '/select-plan';
            }

 */
            window.location.href = '/projects';
        } catch(error) {
            this.setState({
                code: "",
                error: false,
                codeInputFull: false,
                complete: false,
            });
        }
    };

    render() {
        let submit_text = this.state.error ? "Something went wrong. Try again" : "Verify account";
        const submit_class = `btn ${this.state.error ? "btnDanger" : "btnBrand"}`;

        return (
            <>
                <div className={`bgSecondary ${styles.action}`}>
                    <button className="btn btnBrand" onClick={this.logout}>Log Out</button>
                </div>
                <div className={`bgSecondary ${styles.verifyAccount}`}>
                    <form onSubmit={this.verifyEmailAddress}>
                        <h1>Verify Account</h1>
                        <TokenInput name="code" value={this.state.code} onChange={this.updateState} label={`We've sent a 6-digit verification code to ${this.context.user.email}. Please enter it below.`}/>
                        <input disabled={!this.state.codeInputFull} type="submit" value={submit_text} className={submit_class} />
                    </form>
                </div>
            </>
        )
    }
}

export default VerifyAccount;