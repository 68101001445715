import Api from "data/repository/Api";

export const getProject = async (id) => {
    return await Api().get(`/projects/${id}`);
};

export const getProjects = async (perPage, searchTerm, sortBy, ascending) => {
    const searchURL = `${searchTerm.length > 0 ? `&projectName=${searchTerm}&`: ""}order[${sortBy}]=${ascending}&page=1&itemsPerPage=${perPage}`;
    return await Api().get(`/projects?${searchURL}`);
};

export const getProjectLogs = async (id) => {
    const res = await Api().get(`/projects/${id}/ecu-data-logs`);
    return res.data;
};

export const getAppliedWizards = async (id) => {
    const res = await Api().get(`/projects/${id}/wizards-applied`);
    return res.data['hydra:member'].length > 0;
};

export const getMakes = async (projectId) => {
    const makes = await Api().get(`/projects/${projectId}/potential/makes`);
    return JSON.parse(makes.data);
};

export const getModels = async (projectId, makeId) => {
    const models = await Api().get(`/projects/${projectId}/potential/make/${makeId}/models`);
    return JSON.parse(models.data);
};

export const getPowerTrains = async (projectId, makeId, modelId) => {
    const powerTrains = await Api().get(`/projects/${projectId}/potential/make/${makeId}/model/${modelId}/power-trains`);
    return JSON.parse(powerTrains.data);
};

export const getCompatibilityList = async () => {
    const list = await Api().get(`/supported-vehicles?&page=1&resultsPerPage=1000000000`);
    return list.data.data;
};

export const getWizards = async (id) => {
    return await Api().get(`/projects/${id}/wizards`);
};

export const resetProject = async (projectId, revertToStock) => {
    const data = { "backToStock": revertToStock };
    const config = {
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    };
    return await Api().patch(`/projects/${projectId}/reset`, data, config);
};

export const applyWizard = async (projectId, wizardId) => {
    const data = { "wizard": wizardId };
    const config = {
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    };
    return await Api().patch(`/projects/${projectId}/applyWizard`, data, config);
};

export const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    return await Api().post("/project_customer_files", formData, config);
};

export const updateProject = async (data) => {
    const config = {
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    };
    return await Api().patch(`/projects/${data.projectId}`, data, config);
};

export const deleteProject = async (id) => {
    return await Api().delete(`/projects/${id}`);
};

export const downloadOriginal = async (projectId) => {
    const config = {
        headers: {
            'content-type': 'application/ld+json',
        },
        responseType: 'blob'
    };
    const res = await Api().get(`/projects/${projectId}/download-original`, config);
    return res.data;
};

export const createVersion = async (projectId) => {
    const config = {
        headers: {
            'content-type': 'application/ld+json'
        }
    };
    return await Api().post("/project_versions/create-version", { project: projectId }, config);
};

export const exportProject = async (projectVersion) => {
    const config = {
        headers: {
            'content-type': 'application/ld+json',
            'accept': 'application/ld+json',
        },
        responseType: 'blob'
    };
    const res = await Api().get(`/project_versions/${projectVersion.data["@id"].substr(22)}/download`, config);
    return res.data;
};

export const archiveProject = async (projectId) => {
    const config = {
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    };
    return await Api().patch("/projects/" + projectId + "/archive", {}, config);
};

export const unArchiveProject = async (projectId) => {
    const config = {
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    };
    return await Api().patch(`/projects/${projectId}/un-archive`, {}, config);
};

export const getProjectTickets = async (projectId) => {
    return await Api().get(`/projects/${projectId}/support_tickets`);
};

export const purchaseWizards = async (projectId, items, coupon, three_d_secure_action_result_token_id) => {

    const config = {
        headers: {
            'content-type': 'application/ld+json'
        }
    };
    return await Api().put(`/project/${projectId}/purchase-wizards`, { wizards: items, 'voucher_code': coupon, three_d_secure_action_result_token_id }, config);
};

