import * as MetaRepository from '../repository/MetaRepository'
import sortObjectsArray from "sort-objects-array";

export const getCountries = async () => {
    let countries = await MetaRepository.getCountries();

    countries = countries.map((country) => {
        return {
            id: country["@id"],
            name: country.countryName,
            code: country.iso31662Letter
        }
    });
    return sortObjectsArray(countries, "name");
};