import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";

import styles from './Forms.module.scss'

class CloseAccount extends Component {
    static contextType = Context;

    /**
     * Opens window.confirm. If true send close request to api and redirect user to /log-in. On error, error toast.
     */
    closeAccount = async () => {
        if(window.confirm("Are you sure you want to delete your account? You will not be refunded and this can't be undone.")){
            await this.context.closeAccount(this.context.userId);
            await segment.track('account_closed', {
                application: 'dashboard'
            });
            this.context.logout();
        }
    };

    render() {
        return (
            <div className={styles.form}>
                <div>
                    <h3>Close Account &amp; Delete Information</h3>
                    <p className="txtSecondary">This action cannot be undone.</p>
                </div>
                <div>
                    <button onClick={this.closeAccount} className="btn btnDanger">Delete and Close Account</button>
                </div>
            </div>
        )        
    }
}

export default CloseAccount;