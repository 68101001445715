import Api from "data/repository/Api";

export const getPlans = async () => {
    const res = await Api().get(`/subscription_plans`);
    return res.data["hydra:member"];
};

export const getPlan = async () => {
    return await Api().get(`/subscription_plans`);
};

export const getSubscriptions = async () => {
    const res = await Api().get(`/user_subscriptions`);
    return res.data["hydra:member"];
};

export const getPublishableToken = async () => {
    const res = await Api().get(`/publishable-token`);
    return res.data.token;
};

export const handleRecurlyToken = async (token_id, three_d_secure_action_result_token_id) => {
    const data = {
        token_id,
        three_d_secure_action_result_token_id
    };
    return await Api().post(`/user_billing_infos`, data);
};

export const payPalExtraDetails = async (id, data) => {
    return await Api().put(`/user_billing_infos/${id}`, data)
};

export const getBillingDetails = async () => {
    const res = await Api().get(`/user_billing_infos`);
    return res.data["hydra:member"][0];
};

export const subscribe = async (subscription_plan, coupon, three_d_secure_action_result_token_id) => {
    const data = {
        subscription_plan,
        'voucher_code': coupon,
        three_d_secure_action_result_token_id
    };
    await Api().post(`/user_subscriptions`, data);
};

export const changePlan = async (id, subscription_plan, coupon) => {
    const data = {
        subscription_plan,
        'voucher_code': coupon
    };
    const config = {
        headers: {
            'Content-Type': 'application/merge-patch+json'
        }
    };

    return await Api().patch(`/user_subscriptions/${id}`, data, config);
};

export const cancel = (id, cancelReason) => {
    const data = {
        'cancelReason': cancelReason
    }
    const config = {
        headers: {
            'Content-Type': 'application/ld+json'
        }
    };
    return Api().put(`/user_subscriptions/${id}/cancel`, data, config);
};

export const reactivate = (id) => {
    const config = {
        headers: {
            'Content-Type': 'application/ld+json'
        }
    };
    return Api().put(`/user_subscriptions/${id}/reactivate`, {}, config);
};