import React, { Component } from 'react';
import { Context } from 'context/context';

import styles from 'dashboard/projects/project/Project.module.scss';

class ExportSuccess extends Component {
    static contextType = Context;

    render() {
        return (
            <>
                <div className={styles.export}>
                    <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                        <div onClick={this.props.onClose}>
                            <img src={require('images/icons/back.svg')} alt="Back" />
                        </div>
                        
                        <h3>Export Success</h3>
                    </div>
                    <div className={`bgSecondary ${styles.content}`}>
                        <div className={styles.exportSuccess}>
                            <img src={require(`images/icons/${this.context.lightTheme ? "success-light" : "success"}.svg`)} alt="Success"/>
                            <h3>File exported</h3>
                            <p>You can now flash your vehicle with your newly modified file.</p>
                            <div>
                                <div>
                                    <img src={require(`images/icons/warning.svg`)} alt="Warning"/>
                                    <p><b>Please be aware before flashing your vehicle:</b></p>
                                </div>
                                
                                <ul>
                                    <li>Speedweaver does not calculate/correct checksums.</li>
                                    <li>Our support team is unable to assist with flashing related issues.</li>
                                    <li>Users are responsible for reviewing changes to their file before flashing.</li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                        <div className={`${styles.done} btn btnBrand`} onClick={this.props.onClose}>
                            <img src={require(`images/icons/tick-white.svg`)} alt=""/>
                            Done
                        </div>
                    </div>
                </div>
            </>
            
        )
    }
}

export default ExportSuccess;
