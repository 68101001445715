import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';
import segment from "segment";
import CurrencyFormat from 'react-currency-format';

import BaseWizard from "./BaseWizard";
import WizardSuccess from "./WizardSuccess";

import Loading from "components/Loading";

import styles from 'dashboard/projects/project/Project.module.scss';

class Wizards extends Component {
    static contextType = Context;

    state = {
        success: false,
        graph: false,
        // loading: true,
        unlocked: false,
        base: null,
        bases: [],
        patches: [],
        applying: false,
        basket: [],
    };

    async componentDidMount() {
        segment.page('Project > Tuning > Wizards');
    }

    // updateState = (event) => {
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     })
    // };

    getTotalCost() {
        return this.props.totalPrice();
    }

    getCheapestStagePrice(mapThrough) {
        let price = 99999999999999999;
        mapThrough.map((wizard, i) => {
            if (wizard.price < price) {
                price = wizard.price;
            }
        })
        price = price / 100; // Price from API is in pence / cents or lowest common
        return <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={this.context.currencySymbol} decimalScale={2} fixedDecimalScale={this.context.alwaysShowDecimalPlacesInPrices}/>
    }

    getCheapestWizardPrice() {
        return this.getCheapestStagePrice(this.props.bases);
    }

    getCheapestAddonPrice() {
        return this.getCheapestStagePrice(this.props.patches);
    }

    trackTicketIntention = async () => {
        await segment.track('ticket_intended', {
            application: 'dashboard',
            button: 'request-a-wizard'
        })
    };

    render() {
        const thereAreWizards = this.props.bases.length + this.props.patches.length > 0;
        const isFree = !this.context.subscriptionVariables.isProUser;
        let disabled = false;
        if(this.props.base === null && !this.props.patches.some((patch) => { return patch.selected; })) disabled = true;
        if(this.props.applying) disabled = true;

        return (
            <>
                <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                    <NavLink className={styles.backButton} to={`/projects/${this.props.project.id}/tuning`}>
                        <img src={require('images/icons/back.svg')} alt="Back" />
                    </NavLink>
                    <h3>Apply Stage Templates & Addons</h3>
                </div>
                <div className={styles.wizards}>
                    {!this.props.success ?
                        thereAreWizards ?
                            <div className={`bgSecondary ${styles.content}`}>
                                <div className={styles.wizardOptions}>
                                    {this.props.bases.length > 0 ?
                                        <div>
                                            <h3>Stage Template <i> from { this.getCheapestWizardPrice() } {this.context.priceEachAbbreviation}</i></h3>
                                            <p className="txtSecondary">Override existing modifications in the file and apply a stage tune which can be used as a base for further manual tweaks if necessary.</p>

                                            <div className={styles.bases}>
                                                <div onClick={this.props.selectBase.bind(this, null)} className={`bgTertiary highlightTertiary ${this.props.base === null ? styles.selected : null}`}>
                                                    <div>
                                                        <div>
                                                            {this.props.base === null ?
                                                                <img src={require(`images/icons/${this.context.lightTheme ? "radio-light" : "radio-dark"}.svg`)} alt="Selected" />
                                                                :
                                                                <img src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)} alt="Not Selected" />
                                                            }
                                                            <p>None</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.props.bases.map((wizard, i) => {
                                                    return (
                                                        <BaseWizard key={i} wizard={wizard} {...this.props} basket={this.addToBasket}/>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    : null }

                                    {this.props.patches.length > 0 ?
                                        <div className="highlightSecondary">
                                            <h3>Addons, <i> from { this.getCheapestAddonPrice() } {this.context.priceEachAbbreviation}</i></h3>
                                            <p className="txtSecondary">Common optional tuning options which can be applied on their own or in addition to a Stage Template.</p>
                                            <div className={styles.warning}>
                                                <img src={require('../../../../images/icons/warning.svg')} alt="Warning" />
                                                <p className="txtSecondary"><b>Warning:</b> Disabling emissions systems may be illegal in your country, please check your local rules before flashing the car.</p>
                                            </div>

                                            <div className={styles.patches}>
                                                {this.props.patches.map((wizard, i) => {
                                                    return (
                                                        <div key={i} className={`bgTertiary highlightTertiary ${wizard.selected? styles.selected : null}`} onClick={this.props.selectPatch.bind(this, wizard.id)}>
                                                            {wizard.selected ?
                                                                wizard.purchased ?
                                                                    <img src={require(`images/icons/${this.context.lightTheme ? "radio-light" : "radio-dark"}.svg`)} alt="Already paid" />
                                                                    :
                                                                    <img src={require(`images/icons/${this.context.lightTheme ? "addon-light" : "addon-dark"}.svg`)} alt="Selected" />
                                                                :
                                                                <img src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)} alt="Not Selected" />
                                                            }

                                                            <p>{wizard.name}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    : null }
                                </div>


                            </div>
                        :
                            <div className={`bgSecondary ${styles.content}`}>
                                <div className={styles.alert}>
                                    <img src={require(`images/icons/${this.context.lightTheme ? "alert-grey" : "alert"}.svg`)} alt="Alert" />
                                    <h3>No Wizards Found</h3>
                                    <p>There aren't any wizards available for this project yet. Please raise a support ticket to request one.</p>
                                </div>
                            </div>
                    :
                        <WizardSuccess fetchProject={this.props.fetchProject} closeWizards={this.props.closeWizards} id={this.props.project.id} />
                    }

                    {!this.props.success ?
                        thereAreWizards ?
                            <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                                <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                                    <h3>Total Cost {this.getTotalCost()}</h3>
                                </div>
                                <button className="btn btnBrand" disabled={disabled} onClick={this.props.showCheckout}>
                                    {this.props.applying ?
                                        <>
                                            <img src={require('images/animated/processing.svg')} alt="Processing" />
                                            Applying wizards...
                                        </>
                                    :
                                        <>
                                            <img src={require('images/icons/wizards-noCirc.svg')} alt="" />
                                            {this.props.calcTotalPrice() > 0 ?
                                                <>
                                                    Purchase &amp; Apply Wizards
                                                </>
                                                :
                                                <>
                                                    Apply Wizards
                                                </>
                                            }
                                        </>
                                    }
                                </button>
                            </div>
                        :
                        <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                            {isFree ?
                                <NavLink to="/billing/subscribe" className={"btn btnOutline bgSecondary btn btnBrand"}>
                                    <img src={require('images/icons/support-noCirc.svg')} alt="" />
                                    Upgrade to Request a Wizard
                                </NavLink>
                                :
                                <NavLink to={`/support-tickets/new-ticket/wizard_request/${this.props.project.id}`} className={"btn btnOutline bgSecondary btn btnOutline"} onClick={this.trackTicketIntention}>
                                    <img src={require('images/icons/support-noCirc.svg')} alt="" />
                                    Request a Wizard
                                </NavLink>
                            }
                        </div>
                        : null}
                </div>
            </>
        )
    }
}

export default Wizards;
