import React, {Component} from 'react';
import Config from "Config";
import history from "history/history";
import {Context} from './context';
import segment from "../segment";

import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import fileDownload from "js-file-download";
import {EventSourcePolyfill} from 'event-source-polyfill';
import sortObjectsArray from "sort-objects-array";

import * as BillingController from '../data/controller/BillingController';
import * as DynoController from '../data/controller/DynoController';
import * as LogsController from '../data/controller/LogsController';
import * as ProjectsController from '../data/controller/ProjectsController';
import * as TicketsController from '../data/controller/TicketsController';
import * as UserController from '../data/controller/UserController';
import * as MetaController from '../data/controller/MetaController';
import CurrencyFormat from "react-currency-format";

export default class ContextProvider extends Component {

    state = {
        // Defaults
        userId: "",
        projectSearchTerm: "",
        projects: [],
        totalProjects: [],
        projectsPerPage: 10,
        toasts: [],
        toastCounter: 0,
        countries: [],
        logs: [],
        totalLogs: 0,
        stats: {
            canCreateAProject: false,
            canCreateSupportTicket: false,
            canMakeProjectWizardProject: false,
            canUseChatSupport: false,
            numberOfProjectSlotsAllowed: 0,
            numberOfProjectSlotsAvailable: 0,
            numberOfWizardsAllowedThisSubscriptionTerm: 0,
            numberOfWizardsRemainingThisSubscriptionTerm: 0
        },
        subscriptions: [],
        subscriptionVariables: {},
        tickets: [],
        ticketsPerPage: 10,
        freeTrialPopup: false,
        lightTheme: false,
        subscriptionPricePerMonth: 14.95,
        wizardsFromPrice: 10,
        patchesFromPrice: 5,
        alwaysShowDecimalPlacesInPrices: false,
        priceEachAbbreviation: 'ea',
        currencySymbol: '€',
        payWall: false,
        priceToPay: 0,
        projectItemsToPurchase: {
            bases: [],
            patches: [],
        },
        product: {
            // Best being even number or will look shit in places
            benefits : [
                'Cancel Anytime',
                'Billed Monthly',
                'Unlimited Manual Projects',
                'Unlimited Diagnostics Logs',
                'Full File Editor Features',
                'File Editor Map Definitions',
                'Full Project Retention',
                'Stage templates from €10 per project',
                'Addons from €5 per project',
            ]
        },

        publishableToken: null,

        // Entry
        setUserId: this.setUserId.bind(this),
        login: this.login,
        logout: this.logout.bind(this),
        createAccount: this.createAccount,
        verifyEmailAddress: this.verifyEmailAddress.bind(this),
        requestPasswordReset: this.requestPasswordReset,
        checkResetValid: this.checkResetValid,
        resetPassword: this.resetPassword,

        //Mercure
        mercureLogin: this.mercureLogin,
        addEventSourceTopic: this.addEventSourceTopic.bind(this),
        addEventSourceListener: this.addEventSourceListener.bind(this),
        removeEventSourceTopic: this.removeEventSourceTopic.bind(this),
        removeEventSourceListener: this.removeEventSourceListener.bind(this),

        // Projects
        getProject: this.getProject,
        subscribeProjects: this.subscribeProjects.bind(this),
        setProjectsPerPage: this.setProjectsPerPage.bind(this),
        getProjects: this.getProjects.bind(this),
        getProjectIndex: this.getProjectIndex.bind(this),
        getProjectLogs: this.getProjectLogs,
        getAppliedWizards: this.getAppliedWizards,
        uploadFile: this.uploadFile,
        updateProject: this.updateProject,
        exportProject: this.exportProject,
        archiveProject: this.archiveProject,
        unArchiveProject: this.unArchiveProject,
        downloadOriginal: this.downloadOriginal,
        deleteProject: this.deleteProject,
        getProjectTickets: this.getProjectTickets,

        //Protocols
        getMakes: this.getMakes,
        getModels: this.getModels,
        getPowerTrains: this.getPowerTrains,
        getCompatibilityList: this.getCompatibilityList,

        // Wizards
        getWizards: this.getWizards,
        getWizardDynoData: this.getWizardDynoData,
        applyWizard: this.applyWizard,
        resetProject: this.resetProject,
        purchaseWizards: this.purchaseWizards,

        // Logs
        getLogs: this.getLogs.bind(this),
        getLog: this.getLog,
        updateLog: this.updateLog,
        deleteLog: this.deleteLog,
        downloadLog: this.downloadLog,

        // User
        getUser: this.getUser.bind(this),
        getUserStats: this.getUserStats.bind(this),
        updateProfilePicture: this.updateProfilePicture,
        updatePassword: this.updatePassword.bind(this),
        updateUser: this.updateUser.bind(this),
        closeAccount: this.closeAccount,

        // Billing
        getPlans: this.getPlans,
        getPlan: this.getPlan,
        getSubscriptions: this.getSubscriptions.bind(this),
        getBillingDetails: this.getBillingDetails,
        getPublishableToken: this.getPublishableToken,
        handleRecurlyToken: this.handleRecurlyToken,
        payPalExtraDetails: this.payPalExtraDetails,
        subscribe: this.subscribe,
        changePlan: this.changePlan,
        cancel: this.cancel,
        reactivate: this.reactivate,
        getCheapestPlan: this.getCheapestPlan,
        setTotalPrice: this.setTotalPrice.bind(this),
        setProjectItemsToPurchase: this.setProjectItemsToPurchase.bind(this),
        billingDetails: null,
        setBillingDetails: this.setBillingDetails.bind(this),


        // Tickets
        subscribeTickets: this.subscribeTickets.bind(this),
        getTickets: this.getTickets.bind(this),
        setTicketsPerPage: this.setTicketsPerPage.bind(this),
        getTicket: this.getTicket,
        ticketTotalItems: null,
        getComments: this.getComments,
        createTicket: this.createTicket,
        uploadAttachment: this.uploadAttachment,
        addComment: this.addComment,
        solveTicket: this.solveTicket,
        openSolvedTicket: this.openSolvedTicket,

        // Toasts
        setToast: this.setToast.bind(this),
        unsetToast: this.unsetToast.bind(this),

        // Errors
        errorHandler: this.errorHandler,

        // Miscellaneous
        toggleFreeTrialPopup: this.toggleFreeTrialPopup.bind(this),
        togglePayWall: this.togglePayWall.bind(this),
        toggleLightMode: this.toggleLightMode.bind(this),
        getCountries: this.getCountries,
        getProfilePictureRoute: this.getProfilePictureRoute,
        setLightTheme: this.setLightTheme.bind(this),
        niceCurrency: this.niceCurrency,
    };

    eventSource = null;
    eventSourceTopics = [];

    //
    // Entry
    //

    /**
     * Set the user's id in context state
     * * @param {string} userId
     */
    setUserId(userId) {
        this.setState({ userId });
    }

    /**
     * Log the user in and set the JWT in local storage
     * @param {string} email
     * @param {string} password
     */
    async login(email, password) {
        try {
            return await UserController.login(email, password);
        } catch(error) {
            console.log(error.response)
            this.setToast(`${error.response.data.message} (${error.response.status})`, 'Danger', 10000);
            throw error
        }
    }

    /**
     * Log the user out by removing their JWT and redirecting them
     */
    async logout() {
        Cookies.remove('token', { domain: Config.family });
        if(!this.eventSourceTopics) {
            this.eventSourceTopics = [];
            this.setEventSource();
        }
        Cookies.remove('mercureToken', { domain: Config.family });
        window.location.href = "/log-in";
    }

    /**
     * Create new user accountDetails and log them in
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} email
     * @param {string} password
     */
    async createAccount(firstName, lastName, email, password) {
        try {
            const account = await UserController.createAccount(firstName, lastName, email, password);
            if(account) return await UserController.login(email, password);
            this.setToast('Your account has been created. Goto your inbox to receive your verification code.', 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Check the verification code with api to verify the user's email address
     * @param {number} code - 6 digit code
     */
    async verifyEmailAddress(code) {
        try {
            await UserController.verifyEmailAddress(this.state.userId, code);
            this.setToast("Email verified.", "Success", 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Initiate the password reset process, triggers an email to be sent to the user
     * @param {string} email
     */
    async requestPasswordReset(email) {
        try {
            const res = await UserController.requestPasswordReset(email);
            this.setToast(res.data[0], 'Success', 10000);
            return res;
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Check that a password reset link is still valid by comparing the reset code and userID
     * @param {string} code - the hash provided from API when requesting for a password change
     * @param {string} userId
     */
    async checkResetValid(code, userId) {
        try {
            return await UserController.checkResetValid(code, userId);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Complete the password reset flow, setting the new password
     * @param {string} code - the hash provided from API when requesting for a password change
     * @param {string} userId
     * @param {string} newPassword
     */
    async resetPassword(code, userId, newPassword) {
        try {
            this.setToast("Password for account changed", 'Success', 10000);
            return await UserController.resetPassword(code, userId, newPassword)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }


    //
    // Mercure
    //

    async mercureLogin() {
        try{
            const mercureToken = await UserController.mercureLogin();
            Cookies.set("mercureToken", mercureToken, { domain: Config.family, expires: 30 });
            return true;
        } catch(error) {
            console.log(error);
            Cookies.remove("token");
            Cookies.remove("mercureToken");
            window.location.reload();
            throw error;
        }
    }

    setEventSource() {
        if(this.eventSource !== null) this.eventSource.close();
        if(this.eventSourceTopics.length < 1) {
            this.eventSource = null

            return Promise.resolve();
        }

        let mercureUrl = new URL(`${Config.mercure}`);
        this.eventSourceTopics.forEach((topic, idx) => {
            mercureUrl.searchParams.append("topic", `${Config.api}${topic}`);
        });

        let eventSource = new EventSourcePolyfill(mercureUrl, {
            headers: {
                Authorization: `Bearer ${Cookies.get('mercureToken')}`
            },
        });

        if(this.eventSource !== null) eventSource._listeners = this.eventSource._listeners;

        return new Promise((resolve, reject) => {
            eventSource.onopen = () => {
                this.eventSource = eventSource;
                resolve(eventSource)
            };

            eventSource.onerror = reject;
        });
    }

    async addEventSourceTopic(topic) {
        try {
            if(this.eventSourceTopics.includes(topic)) return this.eventSource;
            this.eventSourceTopics = [...this.eventSourceTopics, topic];
            await this.setEventSource();
        } catch(error) {
            console.log(error)
            Cookies.remove("mecureToken");
            window.location.reload();
        }
    }

    addEventSourceListener(channel, func) {
        if(!this.eventSource) throw new Error("EventSource is null");
        this.eventSource.addEventListener(channel, func)
    }

    async removeEventSourceTopic(topic) {
        if(!this.eventSourceTopics.includes(topic)) return this.eventSource;
        this.eventSourceTopics = this.eventSourceTopics.filter(stateTopic => stateTopic !== topic);
        await this.setEventSource();
    }

    removeEventSourceListener(channel, func) {
        if(!this.eventSource) return;
        this.eventSource.removeEventListener(channel, func)
    }

    //
    // Projects
    //

    async subscribeProjects() {
        await this.addEventSourceTopic('/projects/{id}');
        this.eventSource.addEventListener("message", this.onProjectUpdate);
    }

     onProjectUpdate = async (e) => {
        try {
            let data = JSON.parse(e.data);
            if(Object.keys(data).length === 1) {
                // run delete function
                await this.getProjects();
                history.push("/projects");
                return;
            }

            if(data['@type'] !== "Project") return;

            if(this.state.projects.filter(projects=> projects.id === data.id).length < 1) {
                this.getProjects()
            } else {
                this.updateSubscribedProject(data)
            }
        } catch(error) {
            console.log(error)
        }
    };

    /**
     * Set projects per page in state
     * @param {number} projectsPerPage
     */
    async setProjectsPerPage(projectsPerPage) {
        this.setState({
            projectsPerPage
        }, async () => {
            try {
                return await this.getProjects();
            } catch(error) {
                this.setToast(this.errorHandler(error), 'Danger', 10000);
            }
        })
    };

    async getProject(id) {
        try {
            return await ProjectsController.getProject(id);
        } catch(error) {
            throw error;
        }
    }

    /**
     * Get a given page of project results
     */
    async getProjects() {
        try {
            const projects = await ProjectsController.getProjects(10000, this.state.projectSearchTerm, "createdDate", "desc");
            this.setState({
                projects: projects.projects,
                totalProjects: projects.totalProjects
            })
        } catch(error) {
            this.setState({
                projectSearchTerm: ""
            }, () => {
                this.setToast(`${this.errorHandler(error)} Unable to get projects.`, 'Danger', 10000);
                throw error;
            });
        }
    }

    getProjectIndex(id) {
        return this.state.projects.findIndex((project) => project.id === id)
    }

    /**
     * Fetch a particular project
     * @param {string} id
     */
    async getProjectLogs(id) {
        try {
            return await ProjectsController.getProjectLogs(id)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Fetch whether or not a wizard has been applied to a project
     * @param {string} id
     */
    async getAppliedWizards(id) {
        try {
            return await ProjectsController.getAppliedWizards(id)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Upload file to create a new project
     * @param {file} file
     */
    async uploadFile(file) {
        try {
            return await ProjectsController.uploadFile(file)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Patch project resource
     * @param {object} data - key value pairs to be patched and projectId
     */
    async updateProject(data) {
        try {
            await ProjectsController.updateProject(data);
            this.setToast(`Project details successfully updated.`, 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    updateSubscribedProject(data) {
        let contextProjects = this.state.projects;

        contextProjects = contextProjects.map((project, idx) => {
            if(project.id === data.id) {
                return data;
            }
            return project
        });

        this.setState({
            projects: contextProjects
        })
    }

    /**
     * Create project version and then export that version
     * @param projectId
     * @param fileName
     */
    async exportProject(projectId, fileName){
        try {
            const projectVersion = await ProjectsController.createVersion(projectId);
            const file = await ProjectsController.exportProject(projectVersion);
            return fileDownload(file, `${fileName}.bin`)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Archives project
     * @param projectId
     */
    async archiveProject(projectId){
        try {
            await ProjectsController.archiveProject(projectId);
            this.setToast(`Project Archived.`, 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Archives project
     * @param projectId
     */
    async unArchiveProject(projectId){
        try {
            await ProjectsController.unArchiveProject(projectId);
            this.setToast(`Project un-archived.`, 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Download a projects original binary file
     * @param projectId
     * @param fileName
     */
    async downloadOriginal(projectId, fileName){
        try {
            const downloadData =  await ProjectsController.downloadOriginal(projectId)
            return fileDownload(downloadData, `${fileName}-original.bin`);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Delete a project resource
     * @param {string} id
     */
    async deleteProject(id) {
        try {
            await ProjectsController.deleteProject(id);
            this.setToast(`Successfully deleted project: ${id}`, "Success", 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Fetch a particular projects associated tickets
     * @param {string} projectId
     */
    async getProjectTickets(projectId) {
        try {
            return await ProjectsController.getProjectTickets(projectId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    //
    // Protocols
    //

    /**
     * Get all potential makes for a project
     * @param {string} projectId
     */
    async getMakes(projectId) {
        try {
            return await ProjectsController.getMakes(projectId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Get all potential models for a project based on selected make
     * @param {string} projectId
     * @param {string} makeId
     */
    async getModels(projectId, makeId) {
        try {
            return await ProjectsController.getModels(projectId, makeId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Get all potential powerTrains for a project based on selected make and selected model
     * @param projectId
     * @param makeId
     * @param modelId
     */
    async getPowerTrains(projectId, makeId, modelId) {
        try {
            return await ProjectsController.getPowerTrains(projectId, makeId, modelId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    async getCompatibilityList() {
        try {
            return await ProjectsController.getCompatibilityList()
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }


    //
    // Wizards
    //

    /**
     * Get all potential wizards for a project
     * @param {string} projectId
     */
    async getWizards(projectId) {
        try {
            return await ProjectsController.getWizards(projectId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Get a wizard's dyno data from the octal-vehiclelookup-api
     * @param dynoHash
     */
    async getWizardDynoData(dynoHash){
        try {
            return await DynoController.getWizardDynoData(dynoHash);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Apply a wizard to a project
     * @param {string} projectId
     * @param {string} wizardId
     */
    async applyWizard(projectId, wizardId) {
        try {
            return await ProjectsController.applyWizard(projectId, wizardId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Reset a project to either Original (stock) or Uploaded - acts like a wizard
     * @param projectId
     * @param type
     */
    async resetProject(projectId, type){
        try {
            await ProjectsController.resetProject(projectId, type);
            this.setToast(`Project successfully reset to ${type}`, "Success", 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Purchase wizards patches
     * @param projectId
     * @param items
     * @param coupon
     * @param three_d_secure_action_result_token_id
     */
    async purchaseWizards(projectId, items, coupon, three_d_secure_action_result_token_id){
        try {
            await ProjectsController.purchaseWizards(projectId, items, coupon, three_d_secure_action_result_token_id);
            this.setToast(`Successfully purchased wizards`, "Success", 10000);
        } catch(error) {
            if(error?.response?.data?.error?.message){
                Sentry.captureException(error);
                this.setToast(error.response.data.error.message, 'Danger', 10000);
            }
            throw error;
        }
    }

    //
    // Logs
    //

    /**
     * Get log results
     */
    async getLogs(logsPerPage) {
        try {
            const logs = await LogsController.getLogs(logsPerPage, "createdDate", "desc");
            this.setState({
                logs: logs.logs,
                totalLogs: logs.totalLogs
            })
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Get a log
     */
    async getLog(id) {
        try {
          return await LogsController.getLog(id);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Patch log resource
     * @param {object} data - key value pairs to be patched and id
     */
    async updateLog(data) {
        try {
            await LogsController.updateLog(data);
            this.setToast(`Log details successfully updated.`, 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Delete a log
     */
    async deleteLog(id) {
        try {
            await LogsController.deleteLog(id);
            this.setToast("Successfully deleted log.", "Success", 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Download log csv
     * @param logId
     * @param fileName
     */
    async downloadLog(logId, fileName){
        try {
            const file = await LogsController.downloadLog(logId);
            return fileDownload(file, `${fileName}.csv`);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    //
    // User
    //

    /**
     * Get the user, their profile picture and subscription
     * @param {string} id
     */
    async getUser(id) {
        try {
            const user = await UserController.getUser(id);

            if(user.verifiedEmail) {
                await this.getSubscriptions();
                const profilePicture = await UserController.getProfilePicture();

                let plan = "Free";
                let status;
                if(this.state.subscriptions.length > 0) {
                    plan = this.state.subscriptions[0].subscriptionPlan.name;
                    status = this.state.subscriptions[0].status;
                    user.dateTrialEnds = this.state.subscriptions[0].dateTrialEnds;
                }
                user.plan = plan;
                user.status = status;
                user.profilePicture = profilePicture;
            }

            this.setState({ user });
        } catch(error) {
            console.log(error)
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /** Get this user's subscription usage stats and allowances */
    async getUserStats() {
        try {
            const stats = await UserController.getUserStats();
            this.setState({ stats })
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    // todo - should this call getUser? Or at least set the new profilePicture in state?
    /**
     * Update the user's profile picture
     * @param file
     */
    async updateProfilePicture(file) {
        try {
            await UserController.updateProfilePicture(file);
            this.setToast('Profile picture changed successfully.', 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Update the user's password (using old password for auth)
     * @param oldPassword
     * @param newPassword
     */
    async updatePassword(oldPassword, newPassword) {
        try {
            await UserController.updatePassword(this.state.userId, oldPassword, newPassword)
            this.setToast('Password changed successfully.', 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Update the user resource
     * @param {object} data - any data object sent from a component; contains user data
     */
    async updateUser(data) {
        try {
            const user = await UserController.updateUser(this.state.userId, data);
            this.setState({
                user: {
                    ...this.state.user,
                    ...user,
                    profilePicture: this.state.user.profilePicture
                }
            });
            this.setToast('Details updated successfully', 'Success', 10000);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    // todo - Does this need to log them out?
    /**
     * Close the accountDetails
     * @param userId
     */
    async closeAccount(userId) {
        try {
            await UserController.closeAccount(userId);
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }


    //
    // Billing
    //

    /** Get all available subscription plans */
    async getPlans() {
        try {
            return await BillingController.getPlans()
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    async getCheapestPlan() {
        try {
            let cheapestAmount = 999999999999999999;
            let cheapestPlan;
            let plans = await BillingController.getPlans();
            plans.forEach(plan => {
                if (plan.unitAmount < cheapestAmount) {
                    cheapestAmount = plan.unitAmount;
                    cheapestPlan = plan;
                }
            });
            return cheapestPlan;
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Get a specific subscription plan
     * @param {string} secondaryResourceId
     */
    async getPlan(secondaryResourceId) {
        try {
            return await BillingController.getPlan(secondaryResourceId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /** Get this user's active subscription */
    async getSubscriptions() {
        try {
            let subscriptions = await BillingController.getSubscriptions();
            let subscriptionVariables = this.getSubscriptionVariables(subscriptions);

            this.setState({
                subscriptions,
                subscriptionVariables
            })
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /** Get this user's trial info */
    getSubscriptionVariables(subscriptions) {
        try {
            let subscriptionVariable = {};
            let today = new Date();

            subscriptionVariable.isNewUser = subscriptions.length === 0;

            if(subscriptions.length > 0) {
                subscriptionVariable.isProUser = subscriptions[0]?.status !== "expired"
                subscriptionVariable.expDate = new Date(subscriptions[0].dateExpires)
                subscriptionVariable.hasHadTrial = !!subscriptions.find(sub => !!sub.dateTrialEnds)

                let trialExistsInCurrentSub = !!subscriptions[0]?.dateTrialEnds
                if(trialExistsInCurrentSub) {
                    subscriptionVariable.trialEnds = new Date(subscriptions[0]?.dateTrialEnds);
                    subscriptionVariable.isTrialActive = today < subscriptionVariable.trialEnds;
                    subscriptionVariable.noSubAfterTrial = !!subscriptions[0].dateExpires && new Date(subscriptionVariable.expDate).getTime() === subscriptionVariable.trialEnds.getTime() && !subscriptionVariable.isProUser
                }
            } else {
                subscriptionVariable.isProUser = false;
                subscriptionVariable.hasHadTrial = false;
            }

            subscriptionVariable.isTrialAvailable = false;

            return subscriptionVariable;
        } catch(error) {
            console.log(error)
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /** Get this user's billing details */
    async getBillingDetails() {
        try {
            return await BillingController.getBillingDetails()
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /** Get our publishable token for Recurly depending on env */
    async getPublishableToken() {
        let publishableToken = Cookies.get("publishableToken");

        if (!publishableToken) {
            try {
                const publishableToken = await BillingController.getPublishableToken()
                Cookies.set("publishableToken", publishableToken, { domain: Config.family, expires: 30 });
            } catch(error) {
                this.setToast(this.errorHandler(error), 'Danger', 10000);
                throw error;
            }
        }

        return publishableToken;
    }

    /** Handle the token returned by Recurly when setting billing detail objects */
    async handleRecurlyToken(token_id, three_d_secure_action_result_token_id) {
        try {
            return await BillingController.handleRecurlyToken(token_id, three_d_secure_action_result_token_id)
        } catch(error) {
            throw error;
        }
    }

    async payPalExtraDetails(id, data) {
        try {
            return await BillingController.payPalExtraDetails(id, data);
        } catch(error) {
            throw error;
        }
    }

    /**
     * Subscribe the user to a particular plan
     * @param planId
     * @param coupon
     */
    async subscribe(planId, coupon, three_d_secure_action_result_token_id) {
        try {
            await BillingController.subscribe(planId, coupon, three_d_secure_action_result_token_id);
        } catch(error) {
            if(!error?.response?.data?.error?.message){
                Sentry.captureException(error);
                this.setToast(error.response.data.error.message, 'Danger', 10000);
            }
            throw error;
        }
    }

    /**
     * Upgrade or downgrade a particular subscription to a different plan
     * @param subscriptionId
     * @param planId
     * @param coupon
     */
    async changePlan(subscriptionId, planId, coupon) {
        try {
            await BillingController.changePlan(subscriptionId, planId, coupon);
            this.setToast("Plan changed successfully.", "Success", 10000)
        } catch(error) {
            this.setToast(error.response.data.error.message, 'Danger', 10000);
            throw error;
        }
    }

    /** Cancel a subscription */
    async cancel(subscriptionId, cancelReason) {
        try {
            await BillingController.cancel(subscriptionId, cancelReason);
            this.setToast("Plan canceled successfully.", "Success", 10000)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /** Reactivate a cancelled subscription */
    async reactivate(subscriptionId){
        try {
            await BillingController.reactivate(subscriptionId);
            this.setToast("Plan activated successfully. Welcome back.", "Success", 10000)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }


    //
    // Tickets
    //

    async subscribeTickets() {
        await this.addEventSourceTopic('/tickets/{id}');
        this.eventSource.addEventListener("message", this.onTicketsUpdate);
    }

    onTicketsUpdate = async (e) => {
        try {
            let data = JSON.parse(e.data);
            if(data['@type'] !== "Ticket") return;

            if(this.state.tickets.filter(ticket=> ticket.id === data.id).length < 1) {
                this.getTickets()
            } else {
                this.updateSubscribedTickets(data)
            }
        } catch(error) {
            console.log(error)
        }

    };

    updateSubscribedTickets(data) {
        let contextTickets = this.state.tickets;

        if(data.ticketThreadItems.length > 0){
            let dataSort = data.ticketThreadItems.sort((item1, item2) => new Date(item2.createdDate) - new Date(item1.createdDate));
            let dataId = dataSort[0].createdBy.split('/')[3];
            let urlId = window.location.href.split('/');
            if (dataId !== this.state.userId && urlId[urlId.length - 1] !== data.id) this.setToast(`Ticket - ${data.subject} - has a new message`, 'Success', 10000, `${Config.dashboard}/support-tickets/ticket/${data.id}`);
        }

        contextTickets = contextTickets.map((ticket, idx) => {
            if (ticket.id === data.id) {
                return data;
            }
            return ticket
        });

        this.setState({
            tickets: contextTickets
        })
    }

    /**
     * Get a given page of ticket results
     */
    async getTickets () {
        try {
            let res = await TicketsController.getTickets(10000);
            this.setState({
                tickets: res.tickets,
                ticketTotalItems: res.totalItems
            })
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Set projects per page in state
     * @param {number} ticketsPerPage
     */
    async setTicketsPerPage(ticketsPerPage) {
        this.setState({
            ticketsPerPage
        }, async () => {
            try {
                return await this.getTickets();
            } catch(error) {
                this.setToast(this.errorHandler(error), 'Danger', 10000);
            }
        })
    };

    /**
     * Get a specific ticket
     * @param {string} id
     */
    async getTicket(id) {
        try {
            return await TicketsController.getTicket(id)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Get a ticket's comments
     * @param ticketId
     */
    async getComments(ticketId){
        try {
            return await TicketsController.getComments(ticketId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Create a new ticket
     * @param {string} ticketType
     * @param {string} priority
     * @param {string} subject
     * @param {string} notes
     * @param {string} projectId
     * @param {array} attachments
     */
    async createTicket(ticketType, priority, subject, notes, projectId, attachments){
        try {
            return await TicketsController.createTicket(ticketType, priority, subject, notes, projectId, attachments)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Add an attachment to a ticket
     * @param {file} file
     */
    async uploadAttachment(file) {
        try {
            return await TicketsController.uploadAttachment(file)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Add a comment to a ticket
     * @param {string} ticketId - the ticket id of the selected ticket
     * @param {string} status
     * @param {string} priority
     * @param {string} message
     * @param {array} attachments
     */
    async addComment(ticketId, status, priority, message, attachments) {
        try {
            return await TicketsController.addComment(ticketId, status, priority, message, attachments)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * Solve a ticket
     * @param ticketId
     */
    async solveTicket(ticketId) {
        try {
            return await TicketsController.solveTicket(ticketId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /**
     * open a solved a ticket
     * @param ticketId
     */
    async openSolvedTicket(ticketId) {
        try {
            return await TicketsController.openSolvedTicket(ticketId)
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    //
    // Toasts
    //

    /**
     * Set a new toast
     * @param {string} message
     * @param {string} type - 'Brand', 'Success', 'Neutral' and 'Danger'
     * @param {number} timeout - Timeout time for toast in ms. If undefined provided, toast will not timeout.
     */
    setToast(message, type, timeout=0) {
        const toasts = this.state.toasts.filter(toast => toast.message !== message);
        let id = this.state.toastCounter;

        this.setState({
            toasts: [ ...toasts, {
                id: this.state.toastCounter,
                message,
                type,
            }],
            toastCounter: this.state.toastCounter + 1,
        },() => {
            if(timeout !== 0){
                window.setTimeout(() => {
                    this.unsetToast(id)
                }, timeout)
            }
        });
    };

    /**
     * Unset a toast
     * @param id
     */
    unsetToast(id) {
        const toasts = this.state.toasts;
        let index = toasts.findIndex((toast) => toast.id === id);
        if(index < 0) return;
        toasts.splice(index, 1);

        this.setState({ toasts });
    };


    //
    // Errors
    //

    /**
     * Determines what to do based on the error status code of a request
     * @param error - Error passed in from Axios
     * @param logoutOn401 - whether a 401 response code should log the user out
     */
    errorHandler(error, logoutOn401 = true) {
        //todo: make this a middleware of axios and remove actions taken in 401 and 403
        Sentry.captureException(error);
        if(error.response.status === 400){
            if(error.response.data.error) {
                return `${error.response.data.error} (${error.response.status})`;
            } else if(error.response.data.violations){
                return `${error.response.data.violations[0].message} (${error.response.status})`;
            } else if(error.response.data.message){
                return `${error.response.data.message} (${error.response.status})`;
            } else {
                return `${error.response.data["hydra:description"]} (${error.response.status})`
            }
        }
        if(error.response.status === 401){
            if (logoutOn401) this.logout();
            return "Your session has expired";
        }
        if(error.response.status === 403){
            this.setToast("Error 403. Please contact support.", "Danger", 10000);
            return `${error.response.data["hydra:description"]} (${error.response.status})`
        }
        if(error.response.status === 404){
            if(error.response.data.error) {
                return `${error.response.data.error} (${error.response.status})`;
            } else if(error.response.data.violations){
                return `${error.response.data.violations[0].message} (${error.response.status})`;
            } else {
                return `${error.response.data["hydra:description"]} (${error.response.status})`
            }
        }
        if(error.response.status === 405){
            return `${error.response.data["hydra:description"]} (${error.response.status})`;
        }
        if(error.response.status === 413){
            return `The file you tried to upload is too large. ${error.response.status}`;
        }
        if(error.response.status === 500){
            return 'Error code: 500 - Internal Server Error';
        }
    }

    async toggleFreeTrialPopup(toggle) {
        this.setState({
            freeTrialPopup: toggle
        })
    };

    async toggleLightMode() {
        this.setState({
            lightTheme: !this.state.lightTheme
        }, () => {
            Cookies.set('lightTheme', this.state.lightTheme, { domain: Config.family, expires: 30 });
        })
    };

    /** Get all countries from the api and sort them */
    async getCountries(){
        try {
            let countries = await MetaController.getCountries();
            return sortObjectsArray(countries, "countryName");
        } catch(error) {
            this.setToast(this.errorHandler(error), 'Danger', 10000);
            throw error;
        }
    }

    /** Return the appropriate URL for profile pictures based on env */
    getProfilePictureRoute(){
        switch(process.env.REACT_APP_ENV){
            case "develop": return process.env.REACT_APP_PROFILE_STAGE;
            case "stage": return process.env.REACT_APP_PROFILE_STAGE;
            case "production": return process.env.REACT_APP_PROFILE_PROD;
            default: return process.env.REACT_APP_PROFILE_PROD;
        }
    }

    setLightTheme(theme){
        this.setState({
            lightTheme: theme
        })
    }

    togglePayWall(toggle) {
        this.setState({
            payWall: toggle
        })
    };

    setTotalPrice(price) {
        this.setState({
            priceToPay: price
        });
    }

    setBillingDetails(billingDetails) {
        this.setState({
            billingDetails
        });
    }

    setProjectItemsToPurchase(projectItemsToPurchase) {
        this.setState({
            projectItemsToPurchase: projectItemsToPurchase
        });
    }

    niceCurrency(price) {
        return <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} decimalScale={2} fixedDecimalScale={this.alwaysShowDecimalPlacesInPrices} renderText={value => <>{value}</>} />
    }

    render() {
        return (
            <Context.Provider value={{ ...this.state }}>
                {this.props.children}
            </Context.Provider>
        )
    }
}
