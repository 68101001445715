import React, { Component } from 'react';
import { Context } from 'context/context';

import styles from './Forms.module.scss'

class ContactWholesale extends Component {
    static contextType = Context;


    render() {
        return (
            <div className={styles.form}>
                <div>
                    <h3>Need more?</h3>
                    <p className="txtSecondary">Should you want to discuss high volume prices (100+ projects / month), API integrations or other account enquiries please contact us via email to <a href="mailto:more@speedministry.com">more@speedministry.com</a></p>
                </div>
            </div>
        )
    }
}

export default ContactWholesale;