import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Actions from "./actions/Actions";
import ThankYou from "./ThankYou";

import Header from "./template/Header";
import FAQ from "./template/FAQ";
import Footer from "./template/Footer";

import styles from './Billing.module.scss';
import { Context } from "../../context/context";
import segment from "../../segment";

/**
 * <Billing /> acts as a view template and sets the relevant action based on the Route.
 */
class Billing extends Component {
    static contextType = Context;

    componentDidMount = async () => {
        segment.page('Subscription');
    }

    render() {
        let title;
        let freeUserTitle = "Start tuning today!";
        let trialEndedUser = "Please review your payment details";
        let expiredUsers = "Welcome! Activate your subscription today.";

        switch (true) {
            case this.context.subscriptionVariables.isTrialAvailable:
                title = freeUserTitle
            break;

            case this.context.subscriptionVariables?.noSubAfterTrial:
                title = trialEndedUser;
            break;

            case !this.context.subscriptionVariables?.isProUser:
                title = expiredUsers;
            break;

            default:
                title = "Update your details"
        }
        return (
            <div className={styles.billing}>
                <Header {...this.props} />
                <Switch>
                    <Route exact path="/billing/update-billing-details" component={(props) => <Actions {...props} title={title} update={true} />} />
                    <Route exact path="/billing/subscribe" component={(props) => <Actions {...props} title={title} subscribe={true} />} />

                    <Route exact path="/billing/update-billing-details/thank-you" component={(props) => <ThankYou {...props} update={true} />} />
                    <Route exact path="/billing/subscribe/thank-you" component={(props) => <ThankYou {...props} subscribe={true} />} />
                    <Redirect to="/projects"/>
                </Switch>
                <FAQ />
                <Footer />
            </div>
        )
    }
}

export default Billing;
