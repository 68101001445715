import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'Root';
import ContextProviderOriginal from 'context/contextProvider';
import * as Sentry from "@sentry/browser";

if (process.env.REACT_APP_ENV !== "develop")
    Sentry.init({
        environment: process.env.REACT_APP_ENV,
        release: `sw-web-dashboard@${process.env.REACT_APP_VERSION}`,
        dsn: "https://46afd016b4534f088bddc26a97cc23d8@sentry.io/5173740",
        debug: process.env.REACT_APP_ENV !== "production"
    });

ReactDOM.render(
    <ContextProviderOriginal>
        <Root />
    </ContextProviderOriginal>,
    document.getElementById('root')
);