import * as LogsRepository from '../repository/LogsRepository'

export const getLogs = async (perPage, sortBy, ascending) => {
    const res = await LogsRepository.getLogs(perPage, sortBy, ascending);
    return { logs: res.logs, totalLogs: res.totalLogs };
};

export const getLog = async (id) => {
    return await LogsRepository.getLog(id);
};

export const updateLog = (data) => {
    return LogsRepository.updateLog(data);
};

export const deleteLog = async (id) => {
    return await LogsRepository.deleteLog(id);
};

export const downloadLog = async (logId) => {
    return await LogsRepository.downloadLog(logId);
};
