import React, { Component } from 'react';
import Config from "Config";
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import segment from "segment";

import slugify from "slugify";
import sanitize from "sanitize-filename";

import styles from 'dashboard/projects/project/Project.module.scss';

class Tuning extends Component {
    static contextType = Context;

    downloadLog = async () => {
        let fileName = slugify(this.props.name);
        fileName = sanitize(fileName);
        if(fileName === "") fileName = "Log-Csv";

        await this.context.downloadLog(this.props.id, fileName);

        await segment.track('log_downloaded', {
            application: 'dashboard',
            logId: this.props.id
        });
    };

    logViewer  = async () => {
        await segment.track('log_viewed', {
            application: 'dashboard',
            logId: this.props.id
        });
    };

    render() {
        return (
            <>
                <div className={`bgSecondary ${styles.content}`}>
                    <div className={styles.tuning}>
                        <a className="bgTertiary highlightTertiary" href={`${Config.diagnostics}/${this.props.id}`} onClick={this.logViewer}>
                            <img src={require(`images/icons/${this.context.lightTheme ? "log-viewer-grey" : "log-viewer"}.svg`)} alt="" />
                            <div>
                                <h3>Log Viewer</h3>
                                <p className="txtSecondary">View and analyse your diagnostics log in our web viewer.</p>
                            </div>
                        </a>

                        <NavLink className="bgTertiary highlightTertiary" to={`/logs/${this.props.id}/actions/sharing`}>
                            <img src={require(`images/icons/${this.context.lightTheme ? "log-viewer-grey" : "log-viewer"}.svg`)} alt="" />
                            <div>
                                <h3>Sharing Options</h3>
                                <p className="txtSecondary">Allow others to view this log via a public sharing link.</p>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className={`bgPrimary highlightTertiary ${styles.fileActions}`}>
                    <button className="btn btnOutline btnBrand" onClick={this.downloadLog}>
                        <img src={require('images/icons/download.svg')} alt="" />
                        Download Log CSV
                    </button>
                </div>
            </>
        )
    }
}

export default Tuning;

