import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";

import axios from 'axios';

import styles from 'dashboard/Dashboard.module.scss';

class DemoFileButton extends Component {
    static contextType = Context;

    state = {
        loading: false,
        dropDown: false
    };

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    demo = () => {
        axios.get("/demoFiles/D915A_N43J002-1037386670-BINARY.bin", {
            responseType: 'blob',
        }).then((res) => {
            this.upload(res.data);
            this.setState({ dropDown: false })
        }).catch((error) => {
            console.log(error);
        })
    };

    upload = async (file) => {
        try {
            this.setState({
                loading: true
            });
            const project = await this.context.uploadFile(file);
            await segment.track("project_created", {
                application: 'dashboard',
                projectId: project.data.project.split('/')[3],
                projectType: 'demo'
            });

            this.setState({
                loading: false
            });
            await this.props.fetchProjects();
        } catch {
            this.setState({
                loading: false
            });
        }
    };

    render() {
        if(!this.state.loading){
            return (
                <div className={`highlightSecondary bgSecondary ${styles.demoFileButton}`} onClick={this.demo}>
                    + Example Project
                </div>
            )
        } else {
            return (
                <button disabled={true} className={`highlightSecondary bgSecondary ${styles.demoFileButton}`}>
                    Uploading...
                </button>
            )
        }
    }
}

export default DemoFileButton;
