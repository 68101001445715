import React, { Component } from 'react';
import { NavLink, Route, Switch } from "react-router-dom";
import history from 'history/history';
import { Context } from 'context/context';
import segment from "segment";

import Actions from 'dashboard/logs/log/Actions';
import Details from "dashboard/logs/log/Details";

import Sharing from "./components/Sharing";
import Loading from "components/Loading";
import TabSwitcher from "dashboard/projects/project/components/TabSwitcher";

import styles from "dashboard/projects/project/Project.module.scss";

import moment from "moment";

class Log extends Component {
    static contextType = Context;

    state = {
        tabs: [{
            id: 0,
            title: "Actions",
            slug: "actions"
        }, {
            id: 1,
            title: "Details",
            slug: "details"
        }],
        selected: false,
        loading: false,
        deletingLog: false,
        sharingDetails: false,
        public: false
    };

    async componentDidMount() {
        segment.page('Log', {
            log: this.props.match.params.id
        });

        await this.fetchLog();
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.id !== prevProps.match.params.id) this.fetchLog();
    }

    fetchLog = async () => {
        try {
            if(this.props.match.params.id){
                this.setState({
                    selected: true,
                    loading: true
                });
                const log = await this.context.getLog(this.props.match.params.id);
                this.setState({
                    ...this.state,
                    ...log,
                    loading: false
                });
                } else {
                    this.setState({
                        selected: false,
                        loading: false
                    })
                }
        } catch {
            history.push("/logs");
        }
    };

    deleteLog = async () => {
        this.setState({
            deletingLog: true
        });
        try {
            if(window.confirm("Are you sure you wish to delete this log? This action can not be undone.")) {
                await this.context.deleteLog(this.state.id);
                await segment.track('log_deleted', {
                    application: 'dashboard',
                    logId: this.state.id
                });
                await this.props.fetchLogs();
                history.push("/logs");
            }
            this.setState({
                deletingLog: false
            });
        } catch {
            this.setState({
                deletingLog: false
            });
            this.context.setToast("Unable to delete project, please try again.", "Danger", 10000);
        }
    };

    updateSharing = async () => {
        await this.context.updateLog({ id: this.state.id, public: !this.state.public });
        await segment.track('log_updated', {
            application: 'dashboard',
            logId: this.props.id,
            type: 'sharing',
        });
        this.setState({
            public: !this.state.public
        })
    };

    render() {
        if(this.state.selected){
            return (
                <div className={`${styles.project} highlightSecondary ${this.state.selected ? "bgSecondary" : null}`}>
                    {!this.state.loading ?
                        <>
                            <div className={`bgTertiary highlightTertiary ${styles.logHead}`}>
                                <div>
                                    <p className="txtPrimary">{this.state.name}</p>
                                    <p className="txtSecondary">Diagnostics Log</p>
                                </div>
                                <div>
                                    <NavLink to={`/logs`}>
                                        <img src={require(`images/icons/${this.context.lightTheme ? "cross-dark" : "cross"}.svg`)} alt={"X"} />
                                    </NavLink>
                                    <p className="txtSecondary">{moment(this.state.logCreated).format('lll')}</p>
                                </div>
                            </div>


                            <div className={`bgTertiary ${styles.tabSwitcher}`}>
                                <TabSwitcher page="logs" tabs={this.state.tabs} id={this.props.match.params.id} />
                            </div>

                            <Switch>
                                <Route path="/logs/:id/actions/sharing">
                                    <Sharing {...this.state} updateSharing={this.updateSharing} fetchLog={this.fetchLog} fetchLogs={this.props.fetchLogs}/>
                                </Route>

                                <Route path="/logs/:id/actions">
                                    <Actions {...this.state} fetchLog={this.fetchLog} fetchLogs={this.props.fetchLogs} openSharing={this.openSharing} closeSharing={this.closeSharing}/>
                                </Route>

                                <Route path="/logs/:id/details">
                                    <Details {...this.state} fetchLog={this.fetchLog} fetchLogs={this.props.fetchLogs} deleteLog={this.deleteLog} deletingLog={this.state.deletingLog} />
                                </Route>
                            </Switch>

                        </>
                    :
                        <Loading/>
                    }
                </div>
            )
        } else {
            return false;
        }
    }
}

export default Log;
