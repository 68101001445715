import React, {useState} from 'react';

import {
    CardCvvElement,
    CardMonthElement,
    CardNumberElement,
    CardYearElement,
    useRecurly
} from "@recurly/react-recurly";

import styles from "../../Billing.module.scss";

export function Forms (props) {

    const formRef = React.useRef();
    const recurly = useRecurly();
    const [showCompanyFields, changeCompanyFields] = useState(false)

    function handleSubmit (event) {
        event.preventDefault();

        let errors = [];
        if(props.first_name.length === 0) errors.push("first_name");
        if(props.last_name.length === 0) errors.push("last_name");

        if(errors.length > 0){
            props.setValidationErrors(errors);
        } else {
            recurly.token(formRef.current, (error, token) => {
                if (error) {
                    console.log(error);
                    if(error.code === "validation") props.setValidationErrors(error.fields);
                    if(error.message) props.setErrorMessage(error.message);
                } else {
                    props.handleRecurlyToken(token);
                }
            });
        }
    }

    return (
        <form className={styles.left} ref={formRef}>
            <div className={props.step === 1 ? null : styles.hide}>
                <div className={styles.outer}>
                    <div className={styles.head}>
                        <h2>Billing Information</h2>
                        <div className={styles.securelyTransmitted}>
                            <img src={require('images/icons/padlock-grey.svg')} alt="Padlock" />
                            <p>Securely Transmitted</p>
                        </div>
                    </div>
                    <div className={`${styles.inner} ${styles.billingInformation}`}>
                        <div>
                            <label>Address Line 1 <span className={styles.required}>*</span></label>
                            <input
                                type="text"
                                name="address1"
                                className={props.errors.includes("address1") ? styles.error : null}
                                data-recurly="address1"
                                value={props.address1}
                                onChange={props.updateField}
                                maxLength={50}
                            />
                        </div>
                        <div>
                            <label>Address Line 2</label>
                            <input
                                type="text"
                                name="address2"
                                data-recurly="address2"
                                value={props.address2}
                                onChange={props.updateField}
                                maxLength={50}
                            />
                        </div>
                        <div>
                            <label>City <span className={styles.required}>*</span></label>
                            <input
                                type="text"
                                name="city"
                                className={props.errors.includes("city") ? styles.error : null}
                                data-recurly="city"
                                value={props.city}
                                onChange={props.updateField}
                                maxLength={50}
                            />
                        </div>
                        <div>
                            <label>State, Province or Region</label>
                            <input
                                type="text"
                                name="state"
                                data-recurly="state"
                                value={props.state}
                                onChange={props.updateField}
                                maxLength={50}
                            />
                        </div>
                        <div>
                            <label>ZIP / Postal Code <span className={styles.required}>*</span></label>
                            <input
                                type="text"
                                name="postal_code"
                                className={props.errors.includes("postal_code") ? styles.error : null}
                                data-recurly="postal_code"
                                value={props.postal_code}
                                onChange={props.updateField}
                                maxLength={20}
                            />
                        </div>
                        <div>
                            <label>Country <span className={styles.required}>*</span></label>
                            <select
                                name="country"
                                data-recurly="country"
                                value={props.country}
                                onChange={props.updateField}
                            >
                                {props.countries.map((country, i) => {
                                    return <option value={country.code} key={i}>{country.name}</option>
                                })}
                            </select>
                        </div>
                        <div />
                    </div>

                    <div className={styles.addDetails} onClick={() => changeCompanyFields(!showCompanyFields)}>
                        {showCompanyFields ?
                            <img src={require('images/icons/selected.svg')} alt="Selected" />
                            :
                            <img src={require('images/icons/unselectedWhite.svg')} alt="Not Selected" />
                        }
                        <p>I have a company and VAT Number.</p>
                    </div>

                    {showCompanyFields &&
                        <div className={styles.extraDetails}>
                            <div>
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    name="company"
                                    data-recurly="company"
                                    value={props.company}
                                    onChange={props.updateField}
                                    maxLength={100}
                                />
                            </div>
                            <div>
                                <label>VAT Number</label>
                                <input
                                    type="text"
                                    name="vat_number"
                                    data-recurly="vat_number"
                                    value={props.vat_number}
                                    onChange={props.updateField}
                                    maxLength={20}
                                />
                            </div>
                        </div>
                    }

                    <p><span className={styles.required}>*</span> Required Fields</p>
                </div>
                <div className={styles.navigators}>
                    <button className="btn btnOutline txtBlack" onClick={props.back.bind(this)}>Back</button>
                    <button className="btn btnBrand" onClick={props.next.bind(this)}>Continue To Billing Details</button>
                </div>
            </div>
            <div className={props.step === 2 ? null : styles.hide}>
                <div className={styles.outer}>
                    <div className={styles.head}>
                        <h2>Payment Information</h2>
                        <div className={styles.securelyTransmitted}>
                            <img src={require('images/icons/padlock-grey.svg')} alt="Padlock" />
                            <p>Securely Transmitted</p>
                        </div>
                    </div>
                    <div className={`${styles.paymentInformation} ${styles.inner}`}>
                        <div className={styles.halves}>
                            <div>
                                <label>Cardholder First Name <span className={styles.required}>*</span></label>
                                <input
                                    type="text"
                                    name="first_name"
                                    className={props.errors.includes("first_name") ? styles.error : null}
                                    data-recurly="first_name"
                                    value={props.first_name}
                                    onChange={props.updateField}
                                    maxLength={50}
                                />
                            </div>
                            <div>
                                <label>Cardholder Last Name <span className={styles.required}>*</span></label>
                                <input
                                    type="text"
                                    name="last_name"
                                    className={props.errors.includes("last_name") ? styles.error : null}
                                    data-recurly="last_name"
                                    value={props.last_name}
                                    onChange={props.updateField}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                        <div>
                            <label>Card Number <span className={styles.required}>*</span></label>
                            <div className={styles.cardNumber}>
                                <CardNumberElement
                                    style={{ fontSize: 16 }}
                                    className={props.errors.includes("number") ? styles.error : null}
                                />
                                <img src={require('images/icons/cards.svg')} alt="Visa, Mastercard, Amex & Discover" />
                            </div>
                        </div>
                        <div className={styles.thirds}>
                            <div>
                                <label>Expiration Date <span className={styles.required}>*</span></label>
                                <div className={styles.exp}>
                                    <CardMonthElement
                                        style={{ placeholder: "MM", fontSize: 16 }}
                                        className={props.errors.includes("month") ? styles.error : null}
                                    />
                                    <CardYearElement
                                        style={{ placeholder: "YYYY", fontSize: 16 }}
                                        className={props.errors.includes("year") ? styles.error : null}
                                    />
                                </div>
                            </div>
                            <div>
                                <label>CVC</label>
                                <CardCvvElement
                                    style={{ fontSize: 16 }}
                                    className={props.errors.includes("year") ? styles.error : null}
                                />
                            </div>
                        </div>
                    </div>

                    <p><span className={styles.required}>*</span> Required Fields</p>
                </div>
                <div className={styles.navigators}>
                    <button className="btn btnOutline txtBlack" onClick={props.setStep.bind(this, 1)}>Back</button>
                    <button className="btn btnBrand" onClick={handleSubmit} disabled={props.processing}>{props.update ? "Complete" : "Continue To Checkout"}</button>
                </div>
            </div>
        </form>
    )
}

export default Forms;