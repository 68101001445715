import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import styles from '../Billing.module.scss';

class Header extends Component {
    render() {
        const signUp = this.props.location.search === "?signUp=true";
        return (
            <div className={styles.header}>
                <div className={styles.container}>
                    <NavLink to={signUp ? "/projects" : "/account"} className="btn btnOutline">{signUp ? "Maybe later" : "Back to Dashboard"}</NavLink>
                    <NavLink to="/projects" className={styles.image}>
                        <img src={require('images/speedweaver.svg')} alt="Speedweaver" />
                    </NavLink>
                    <NavLink to="/support-tickets/new-ticket" className="btn btnOutline">Contact Support</NavLink>
                </div>
            </div>
        )
    }
}

export default Header;