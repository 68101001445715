import React, { Component } from 'react';
import { Context } from "context/context";
import segment from 'segment';

import { PayPalButton } from './updateBillingDetails/PayPalButton.js';
import Steps from './Steps';
import UpdateBillingDetails from './updateBillingDetails/UpdateBillingDetails';
import Checkout from './checkout/Checkout';
import ThankYou from "../ThankYou";
import Error from "./Error";

import Loading from "./components/Loading";
import ErrorMessage from './components/ErrorMessage';

import styles from '../Billing.module.scss';
import {Elements, RecurlyProvider} from "@recurly/react-recurly";

/**
 * <Actions /> is responsible for maintaining the state of the user's progress
 */
class Actions extends Component {
    static contextType = Context;

    state = {
        loading: true,
        step: 1,
        billingDetails: null,
        failed: false,
        errorMessage: "",
        plan: null,
        method: null
    };

    async componentDidMount() {
        try {
            if(!this.props.update){
                if(this.context.subscriptions[0] && this.context.subscriptionVariables.isProUser) window.location.href = "/";
            }

            let plan = null;
            if(this.props.subscribe) plan = await this.context.getPlan("basic");

            let project = null;

            const publishableToken = await this.context.getPublishableToken();
            const countries = await this.context.getCountries();
            await this.fetchBillingDetails();

            let checkoutType = "reactivate";
            if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
            if (this.props.update) checkoutType = "update";

            await segment.track('checkout_initiated', {
                application: 'dashboard',
                checkoutType
            });

            this.setState({
                loading: false,
                publishableToken,
                countries,
                plan,
                project
            })
        } catch(error){
            console.log(error);
            window.location.href = "/";
        }
    }

    fetchBillingDetails = async () => {
        return new Promise(async (resolve) => {

            const billingDetails = this.props.update ? null : await this.context.getBillingDetails();

            this.setState({ billingDetails }, () => {
                resolve();
            })
        })
    };

    resetBillingDetails = () => {
        this.setState({ step: 1, billingDetails: null })
    };

    setErrorMessage = (errorMessage) => {
        this.setState({ errorMessage })
    };

    setStep = (step, event) => {
        if(event) event.preventDefault();
        this.setState({ step })
    };

    setFailed = async () => {
        let checkoutType = "reactivate";
        if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
        if (this.props.update) checkoutType = "update";

        await segment.track('checkout_failed', {
            application: 'dashboard',
            checkoutType
        });

        this.setState({
            failed: true
        })
    };

    setMethod = (method) => {
        this.setState({ method })
    };

    render() {
        if(!this.state.loading){
            return (
                <>
                    <h1 className={`${styles.title}`}>{this.props.title}</h1>

                    <div>
                        <Steps {...{...this.props, ...this.state}} />
                        <div className={styles.main}>
                            {this.state.errorMessage && <ErrorMessage errorMessage={this.state.errorMessage} />}

                            {this.state.failed ?
                                <Error {...{...this.props, ...this.state}} />
                                : !this.state.billingDetails ?
                                    <UpdateBillingDetails
                                        {...{...this.props, ...this.state}}
                                        setStep={this.setStep}
                                        fetchBillingDetails={this.fetchBillingDetails}
                                        setFailed={this.setFailed}
                                        setErrorMessage={this.setErrorMessage}
                                        setMethod={this.setMethod}
                                    />
                                    :
                                    <Checkout
                                        {...{...this.props, ...this.state}}
                                        resetBillingDetails={this.resetBillingDetails}
                                        setFailed={this.setFailed}
                                        setErrorMessage={this.setErrorMessage}
                                    />
                            }
                        </div>
                    </div>
                </>
            )
        } else {
            return <Loading />
        }
    }
}

export default Actions;
