import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";
import Cookies from 'js-cookie';

import styles from '../../Billing.module.scss';
import * as Sentry from "@sentry/browser";
import {Elements, RecurlyProvider, ThreeDSecureAction} from "@recurly/react-recurly";
import Coupon from "./Coupon";
import Pricing from "./Pricing";

class Purchase extends Component {
    static contextType = Context;

    state = {
        processing: false,
        coupon: null,
        fullFail: false,
        actionTokenId: null,
        actionTokenResultId: null
    };

    removeCoupon = () => {
        this.setState({ coupon: null })
    };

    applyCoupon = (coupon) => {
        this.setState({ coupon })
    };

    action = async () => {
        await segment.track('checkout_proceeded', {
            application: 'dashboard',
            checkoutType: 'product',
            type: 'submit'
        });

        this.setState({
            processing: true,
            fullFail: false,
            actionTokenId: null
        });

        try {
            await this.props.action(this.state);

            await segment.track('checkout_completed', {
                application: 'dashboard',
                checkoutType: 'product'
            });
        } catch (error) {
            if (error?.response?.data?.error?.three_d_secure_action_token_id) {
                await segment.track('checkout_proceeded', {
                    application: 'dashboard',
                    checkoutType: 'product',
                    type: 'threeDSecure'
                });
                this.setState({
                    actionTokenId: error.response.data.error.three_d_secure_action_token_id,
                    fullFail: false
                })
            } else {
                this.setState({
                    processing: false,
                    fullFail: false
                });
            }
        }
    };

    onError = (error) => {
        Sentry.captureException(error);
        this.context.setToast(`3D Secure Failed.  Please close this window and try again!`, "Danger", 10000);

        this.setState({
            processing: false,
            actionTokenId: null,
            fullFail: true
        });
    };

    render() {
        let publishableToken = Cookies.get("publishableToken");

        return (
            <>
                {this.state.actionTokenId ?
                        <RecurlyProvider publicKey={publishableToken}>
                            <Elements>
                                <div className={styles.threedSecure}>
                                    <ThreeDSecureAction
                                        actionTokenId={this.state.actionTokenId}
                                        onToken={(token) => {
                                            this.setState({
                                                actionTokenResultId: token.id
                                            });
                                            return this.action();
                                        }}
                                        onError={this.onError}
                                        className={styles.threedSecureRecurly}
                                    />
                                </div>
                            </Elements>
                        </RecurlyProvider>
                    :
                    <div className={`${styles.checkout} ${styles.right}`}>
                        <div className={styles.details}>
                            <div className={styles.head}>
                                <div>
                                    <h2>Products</h2>
                                </div>
                            </div>

                            <Coupon
                                removeCoupon={this.removeCoupon}
                                applyCoupon={this.applyCoupon}
                                coupon={this.state.coupon}
                            />

                            <RecurlyProvider publicKey={publishableToken}>
                                <Elements>
                                <Pricing
                                {...this.props}
                                    country={this.props.billingDetails.country}
                                    vatNumber={this.props.billingDetails.vatNumber}
                                    coupon={this.state.coupon}
                                    products={this.props.products}
                                />
                                </Elements>
                            </RecurlyProvider>
                        </div>
                        {this.state.fullFail ?
                            <>
                                <button className="btn btnBrand" onClick={this.context.togglePayWall.bind(this, false)}>Close
                                </button>
                            </>
                            :
                            <>
                                <button className="btn btnBrand" onClick={this.action.bind(this, null)}
                                        disabled={this.state.processing}>Purchase
                                </button>
                            </>
                        }
                    </div>
                }
            </>
        )
    }
}

export default Purchase;
