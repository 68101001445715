import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import segment from "segment";

import styles from 'dashboard/projects/project/Project.module.scss';

class Reset extends Component {
    static contextType = Context;

    state = {
        type: "uploaded",
        resetting: false
    };

    componentDidMount() {
        segment.page('Project > Tuning > Reset');
    }

    reset = () => {
        this.setState({
            resetting: true
        },  async () => {
            try {
                await this.context.resetProject(this.props.project.id, this.state.type);
                await segment.track('project_reset', {
                    application: 'dashboard',
                    projectId: this.props.project.id,
                    projectType: this.props.project.projectType === "DEMO" ? "demo" : "user",
                    type: this.state.type
                });

                this.setState({
                    resetting: false
                });
            } catch {
                this.setState({
                    resetting: false
                })
            }
        })
    };

    render() {
        return (
            <>
                <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                    <NavLink to={`/projects/${this.props.project.id}/tuning`}>
                        <img src={require('images/icons/back.svg')} alt="Back"  />
                    </NavLink>
                    <h3>Reset Modifications</h3>
                </div>

                <div className={`${styles.wizards} ${styles.reset}`}>
                    <div className={`bgSecondary ${styles.content}`}>
                        <div>
                            <h3 className="txtPrimary">Are you sure you want to reset to uploaded state?</h3>
                            <p className="txtSecondary">This action cannot be undone.</p>
                        </div>
                    </div>
                    <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                        <button className="btn btnBrand" disabled={this.state.resetting} onClick={this.reset}>
                            {this.state.resetting ?
                                <>
                                    <img src={require('images/animated/processing.svg')} alt="Processing" />
                                    Resetting...
                                </>
                            :
                                <>
                                    <img src={require('images/icons/revert-noCirc.svg')} alt="" />
                                    Reset Modifications
                                </>
                            }
                        </button>
                    </div>
                </div>
            </>
            
        )
    }
}

export default Reset;
