import React, { Component } from 'react';
import { Context } from 'context/context';

import {Elements, RecurlyProvider, ThreeDSecureAction} from "@recurly/react-recurly";
import axios from "axios";
import * as Sentry from '@sentry/browser';

import Forms from './Forms';
import { PayPalButton } from './PayPalButton.js';
import PayPalExtraDetails from "./PayPalExtraDetails";
import Product from '../components/Product';

import styles from '../../Billing.module.scss';
import segment from "../../../../segment";

class UpdateBillingDetails extends Component {
    static contextType = Context;

    state = {
        cardPayment: false,
        first_name: "",
        last_name: "",
        company: "",
        vat_number: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        tokenId: null,
        actionTokenId: null,
        errors: [],
        processing: false,
        payPalStepTwo: false
    };

    componentDidMount() {
        axios.get("https://ipapi.co/json/").then((res) => {
            this.setState({
                country: res.data.country_code
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    selectCardPayment = () => {
        this.props.setMethod(1);

        this.setState({
            cardPayment: true
        });
    };

    back = () => {
        this.props.setMethod(null);

        this.setState({
            cardPayment: false
        })
    };

    updateField = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleRecurlyToken = async (token) => {
        this.setState({
            processing: true
        });

        let tokenId = token.id;
        let three_d_secure_action_result = null;

        if(token.type === "three_d_secure_action_result"){
            tokenId = this.state.tokenId;
            three_d_secure_action_result = token.id;
        }

        try {
            await this.context.handleRecurlyToken(tokenId, three_d_secure_action_result);

            let checkoutType = "reactivate";
            if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
            if (this.props.update) checkoutType = "update";

            await segment.track('checkout_proceeded', {
                application: 'dashboard',
                checkoutType,
                type: 'paymentInformation'
            });
            if(this.state.cardPayment === true){
                this.completed();
            } else {
                this.props.setStep(2);
                this.setState({
                    payPalStepTwo: true
                })
            }
        } catch(error) {
            if(error?.response?.data?.three_d_secure_action_token_id) {
                this.setState({
                    tokenId: tokenId,
                    actionTokenId: error.response.data.three_d_secure_action_token_id
                })
            } else {
                if(error?.response?.data?.message){
                    this.props.setErrorMessage(error.response.data.message);
                    this.setState({
                        processing: false
                    })
                } else {
                    this.props.setFailed();
                }
            }
        }
    };

    setValidationErrors = (errors) => {
        this.props.setErrorMessage("Please fill out the required fields");
        this.setState({ errors })
    };

    // Validate user inputs and set errors before moving on to step 2
    next = async (event) => {
        event.preventDefault();
        this.props.setErrorMessage("");

        let errors = [];
        if(this.state.address1.length === 0) errors.push("address1");
        if(this.state.city.length === 0) errors.push("city");
        if(this.state.postal_code.length === 0) errors.push("postal_code");

        if(errors.length > 0){
            this.setValidationErrors(errors);
        } else {
            this.props.setStep(2);

            let checkoutType = "reactivate";
            if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
            if (this.props.update) checkoutType = "update";

            await segment.track('checkout_proceeded', {
                application: 'dashboard',
                checkoutType,
                type: 'billingInformation'
            });
        }
    };

    onError = (error) => {
        Sentry.captureException(error);
        this.props.setFailed();
    };

    handleExtraDetails = async (data) => {
        try {
            let details = await this.context.getBillingDetails();
            await this.context.payPalExtraDetails(details["@id"].split("/")[3], data);
            this.completed();
        } catch(error){
            this.props.setFailed();
        }
    };

    completed = async () => {
        if(this.props.update){
            let checkoutType = "reactivate";
            if (this.context.subscriptionVariables.isTrialAvailable) checkoutType = "trial";
            if (this.props.update) checkoutType = "update";

            await segment.track('checkout_completed', {
                application: 'dashboard',
                checkoutType
            });
            window.location.href = "/billing/update-billing-details/thank-you";
        } else {
            await this.props.fetchBillingDetails();
        }
    };

    render() {
        return (
            <div className={styles.container}>
                <RecurlyProvider publicKey={this.props.publishableToken}>
                    <Elements>
                        {this.state.cardPayment === false ?
                            this.state.payPalStepTwo ?
                                <PayPalExtraDetails
                                    {...{...this.state, ...this.props}}
                                    handleExtraDetails={this.handleExtraDetails}

                                />
                            :
                                <div className={styles.left}>
                                    <div className={styles.outer}>
                                        <div className={styles.head}>
                                            <h2>Select Payment Method</h2>
                                            <div className={styles.securelyTransmitted}>
                                                <img src={require('images/icons/padlock-grey.svg')} alt="Padlock" />
                                                <p>Securely Transmitted</p>
                                            </div>
                                        </div>
                                        <div className={styles.inner}>
                                            <button onClick={this.selectCardPayment} className={styles.method}>
                                                <img src={require('images/icons/cards.svg')} alt="Visa, Mastercard, Amex & Discover" />
                                                Credit / Debit Card
                                            </button>
                                            <PayPalButton
                                                setFailed={this.props.setFailed}
                                                handleRecurlyToken={this.handleRecurlyToken}
                                                setMethod={this.props.setMethod}
                                            />
                                        </div>
                                    </div>
                                </div>
                        :
                            <>
                                {this.state.actionTokenId &&
                                    <ThreeDSecureAction
                                        actionTokenId={this.state.actionTokenId}
                                        onToken={this.handleRecurlyToken}
                                        onError={this.onError}
                                        className={styles.secureThreeD}
                                    />
                                }
                                <Forms
                                    {...{...this.props, ...this.state}}
                                    back={this.back}
                                    next={this.next}
                                    updateField={this.updateField}
                                    setValidationErrors={this.setValidationErrors}
                                    handleRecurlyToken={this.handleRecurlyToken}
                                />
                            </>
                        }
                    </Elements>
                </RecurlyProvider>
                <Product {...{...this.props, ...this.state}} />
            </div>
        )
    }
}

export default UpdateBillingDetails;