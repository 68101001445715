import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from "context/context";
import segment from "segment";

import moment from 'moment';

import styles from 'dashboard/accountDetails/subscription/Subscription.module.scss';
import Cancel from "../Cancel";

class MySubscription extends Component {
    static contextType = Context;

    state = {
        processing: false,
        cancel: false
    };

    reactivate = async () => {
        try {
            this.setState({processing: true});
            await this.context.reactivate(this.context.subscriptions[0].id);
            await segment.track('subscription_reactivated', {
                application: 'dashboard'
            });

            window.location.reload();
        } catch (error) {
            this.setState({processing: false});
            this.context.setToast("Failed to reactivate subscription", "Danger", 1000)
        }
    };

    intCancel = () => {
        this.setState({
            cancel: true
        })
    }

    continue = () => {
        this.setState({
            cancel: false
        })
    };


    render() {
        const subscription = this.context.subscriptions[0]
        const subVariables = this.context.subscriptionVariables
        if (this.context.subscriptions.length > 0 && subVariables) {
            return (
                <div className="highlightTertiary">
                    <div className={styles.plan}>
                        <div className={styles.top}>
                            <div>
                                <h3>My Plan</h3>
                            </div>
                        </div>
                        <div className={`highlightTertiary bgTertiary ${styles.middle}`}>
                            <div>
                                {subVariables?.isTrialActive ?
                                    <h2>7-Day Free Trial, Then €{this.props.subscribedPlan.unitAmount}/mo</h2>
                                    :
                                    <h2>Speedweaver Subscription: €{this.props.subscribedPlan.unitAmount}/mo</h2>
                                }
                            </div>

                            <div>
                                <p className="txtSecondary">Started: {moment(subscription?.dateCreated).format("Do MMMM YYYY")}</p>
                                {!subscription.status === "canceled" &&
                                    <p className="txtSecondary">Next Renewal: {moment(subscription?.dateIntervalEnds).format("Do MMMM YYYY")}</p>
                                }
                                {subVariables?.isTrialActive &&
                                    <p className="txtBrand">Trial Ends: {moment(subVariables?.trialEnds).format("Do MMMM YYYY")}</p>
                                }
                            </div>
                        </div>

                        <div className={styles.bottom}>
                            <NavLink to="/billing/update-billing-details" className="btn btnBrand">Update Billing Details</NavLink>
                            {subscription.status === "canceled" ?
                                <button className="btn btnBrand" disabled={this.state.processing} onClick={this.reactivate}>{this.state.processing ? "Reactivating..." : "Reactivate Subscription"}</button>
                            : !this.state.cancel ?
                                <button className="btn btnOutline bgTertiary" onClick={this.intCancel.bind(this)}>Cancel Subscription {subVariables?.isTrialActive ? "Trial" : "Plan"}</button>
                            :
                                <Cancel
                                    continue={this.continue}
                                />
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            return false;
        }
    }
}

export default MySubscription;
