import React, { Component } from 'react';
import { Context } from 'context/context';

import styles from './Forms.module.scss'

class Aboutus extends Component {
    static contextType = Context;


    render() {
        return (
            <div className={styles.form}>
                <div>
                    <h3>About us</h3>
                    <p className="txtSecondary">Speedweaver is part of the <a href="//speedministry.com?s=sw" rel="author" target="_blank">SpeedMinistry</a> Brand with over 80 years combined tuning and calibration experience.</p>
                </div>
            </div>
        )
    }
}

export default Aboutus;