import React, { Component } from 'react';

import styles from '../../Billing.module.scss';

class Security extends Component {
    render() {
        return (
            <div className={styles.security}>
                <img src={require('images/icons/padlock-dark.svg')} alt="Padlock" className={styles.padlock} />
                <p>Secure Payments</p>
                <img src={require('images/poweredByStripe.svg')} alt="Powered by Stripe" className={styles.stripe} />
            </div>
        )
    }
}

export default Security;