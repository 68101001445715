import React, { Component } from 'react';

import BillingDetails from "./BillingDetails";
import Subscribe from './Subscribe';

import styles from '../../Billing.module.scss';

class Checkout extends Component {
    render() {
        return (
            <div className={`${styles.container} ${styles.review} ${styles.reverse}`}>
                <BillingDetails {...{...this.props, ...this.state}} />
                {this.props.subscribe && <Subscribe {...{...this.props, ...this.state}} /> }
            </div>
        )
    }
}

export default Checkout;