import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Context } from 'context/context';
import segment from "segment";

import Expired from 'dashboard/accountDetails/subscription/components/Expired';
import Status from 'dashboard/accountDetails/subscription/components/Status';
import MySubscription from "dashboard/accountDetails/subscription/components/MySubscription";

import subscriptionStyles from "./Subscription.module.scss";
import TickBenefit from "../../../entry/benefits/TickBenefit";

class Subscription extends Component {
    static contextType = Context;

    async componentDidMount() {
        segment.page('Subscription');
    }

    render() {
        const subVariables = this.context.subscriptionVariables
        const isProUser = subVariables?.isProUser
        const isNewUser = subVariables?.isNewUser
        const isTrialAvailable = this.context.subscriptionVariables.isTrialAvailable
        let subscriptionPricePerMonth = this.context.subscriptionPricePerMonth;

        return (
            <div className={`highlightSecondary ${subscriptionStyles.container}`}>
                <Helmet>
                    <title>Subscription - Speedweaver</title>
                </Helmet>

                <div>
                    {!isProUser && !isNewUser && <Expired date={this.context.subscriptions[0].dateExpires} /> }
                    {isProUser && <Status /> }

                    {isProUser ?
                        <div className={subscriptionStyles.subscribed}>
                            <MySubscription
                                plan={this.props.plan}
                                subscribedPlan={this.props.subscribedPlan}
                            />
                        </div>
                        :
                        <div className={`bgTertiary ${subscriptionStyles.pro}`}>
                            <span>Limited Offer</span>
                            <h2>Speedweaver Subscription</h2>
                            <p className="price">€{subscriptionPricePerMonth} / month (exclusive of VAT)</p>
                            <NavLink to="/billing/subscribe" className="btn btnBrand">{isTrialAvailable? 'Start Tuning Now' : 'Start Tuning Now'}</NavLink>

                            <p className={subscriptionStyles.smallPrint}><TickBenefit listAs="inLineBullets" /></p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Subscription;
