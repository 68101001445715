import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';

import styles from "dashboard/projects/project/Project.module.scss";

class TabSwitcher extends Component {
    static contextType = Context;

    render() {
        return (
            this.props.tabs.map((tab, idx) => {
                return (
                    <NavLink className="highlightSecondary" to={`/${this.props.page}/${this.props.id}/${tab.slug}`} key={idx} activeClassName={styles.active}>
                        <p>{tab.title}</p>
                    </NavLink>

                    )
            })
        )
    }
}

export default TabSwitcher;
