import React, { Component } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import New from "dashboard/support/ticket/New";
import Existing from "dashboard/support/ticket/Existing";

class Ticket extends Component {
    render() {
        return (
            <Switch>
                <Route path="/support-tickets/new-ticket" component={New} />
                {/*<Route exact path="/support-tickets/new-ticket/:ticketType/:projectId" component={New} />*/}
                <Route exact path="/support-tickets/ticket/:id" component={Existing} />
                <Redirect to="/support-tickets" />
            </Switch>
        )
    }
}

export default Ticket;
