import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';

import Item from 'dashboard/support/Item';

import styles from "dashboard/Dashboard.module.scss";

class List extends Component {
    static contextType = Context;

    loadMore = async () => {
        await this.context.setTicketsPerPage(this.context.ticketsPerPage + 5);
        await this.context.getTickets();
    };

    render() {
        return (
            this.context.tickets.length > 0 ?
                <div className={styles.list}>
                    {this.context.tickets.map((ticket, idx) => <Item key={idx} {...ticket}/> )}
                    {this.context.ticketTotalItems <= this.context.tickets.length ?
                        null
                    :
                        <div className={styles.pagination}>
                            <button className="btn btnSecondary btnOutline" onClick={this.loadMore}>Load More</button>
                        </div>
                    }
                </div>
            :
                <div className={styles.centred}>
                    <p>No Tickets Found</p>
                    <NavLink to="/support-tickets/new-ticket" className="btn btnBrand">+ Open New Ticket</NavLink>
                </div>
        )
    }
}

export default List;
