import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import tools from "./imports/tools.json";

import ReactDropdown from "react-dropdown";

import styles from "../../Ticket.module.scss";

class ToolSelect extends Component {
    static contextType = Context;



    render() {
        let toolOptions = tools.map((tool) => {
            return {
                value: tool,
                label: tool
            }
        })

        toolOptions.push({value: "Other", label: "Other"})

        let disabled =
        (this.props.selectedTool !== "Other" && this.props.selectedTool !== null
            ||
        this.props.selectedTool === "Other" && this.props.otherTool)
            &&
        this.props.checkMaster
            &&
        this.props.modifiedValue

        return (
            <>
                <div className={`bgTertiary highlightTertiary ${styles.sidebarHead}`}>
                    <NavLink to={`/support-tickets/new-ticket/rejected`}>
                        <img src={require('images/icons/back.svg')} alt="Back"/>
                    </NavLink>

                    <div>
                        <h3>Report Rejected File</h3>
                    </div>
                </div>

                <div className={styles.content}>
                    <div className={styles.form}>
                        <div className={`${styles.itemList} ${styles.tools}`}>
                            <div>
                                <p>Flash Tool <span className="txtDanger">*</span></p>
                                <ReactDropdown
                                    className="bgTertiary"
                                    options = {toolOptions}
                                    value={this.props.selectedTool}
                                    placeholder="Tool Used"
                                    onChange={this.props.updateSelectedTool}
                                />
                            </div>

                            { this.props.selectedTool === "Other" ?
                                <div>
                                    <textarea name="otherTool" placeholder="Please Specify" onChange={this.props.updateState} value={this.props.otherTool} rows={1} />
                                </div>
                                :
                                null
                            }

                            <div className={`${styles.options} ${styles.multiChoiceButtons}`}>
                                <p>Has the file previously been modified? <span className="txtDanger">*</span></p>

                                <div>
                                    <div onClick={this.props.updateButtons.bind(this, "Yes")} className={`bgTertiary highlightTertiary ${styles.optionContainer} ${this.props.modifiedValue === "Yes" ? styles.selected : null}`}>
                                        <div className={styles.info}>
                                            {this.props.modifiedValue === "Yes" ?
                                                <img
                                                    src={require(`images/icons/${this.context.lightTheme ? "radio-light" : "radio-dark"}.svg`)}
                                                    alt="Selected"
                                                />
                                            :
                                                <img
                                                    src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)}
                                                    alt="Not Selected"
                                                />
                                            }

                                            <p>Yes</p>
                                        </div>
                                    </div>

                                    <div onClick={this.props.updateButtons.bind(this, "No")} className={`bgTertiary highlightTertiary ${styles.optionContainer} ${this.props.modifiedValue === "No" ? styles.selected : null}`}>
                                        <div className={styles.info}>
                                            {this.props.modifiedValue === "No" ?
                                                <img
                                                    src={require(`images/icons/${this.context.lightTheme ? "radio-light" : "radio-dark"}.svg`)}
                                                    alt="Selected"
                                                />
                                            :
                                                <img
                                                    src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)}
                                                    alt="Not Selected"
                                                />
                                            }

                                            <p>No</p>
                                        </div>
                                    </div>

                                    <div onClick={this.props.updateButtons.bind(this, "Unsure")} className={`bgTertiary highlightTertiary ${styles.optionContainer} ${this.props.modifiedValue === "Unsure" ? styles.selected : null}`}>
                                        <div className={styles.info}>
                                            {this.props.modifiedValue === "Unsure" ?
                                                <img
                                                    src={require(`images/icons/${this.context.lightTheme ? "radio-light" : "radio-dark"}.svg`)}
                                                    alt="Selected"
                                                />
                                            :
                                                <img
                                                    src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)}
                                                    alt="Not Selected"
                                                />
                                            }

                                            <p>Unsure</p>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className={styles.options}>
                                <div onClick={this.props.changeCheck.bind(this)} className={`${styles.optionContainer} noBorder`}>
                                    <div className={styles.info}>
                                        {this.props.checkMaster ?
                                            <img
                                                src={require(`images/icons/${this.context.lightTheme ? "addon-light" : "addon-dark"}.svg`)}
                                                alt="Selected"
                                            />
                                            :
                                            <img
                                                src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)}
                                                alt="Not Selected"
                                            />
                                        }

                                        <p>The file is unencrypted and read using a master tool <span className="txtDanger">*</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`bgPrimary highlightTertiary ${styles.buttonActions}`}>
                    <p className={styles.required}><span className="txtDanger">*</span> Required</p>
                    <button className="btn btnBrand" disabled={!disabled} onClick={this.props.updateStep.bind(this, 2)}>
                        Next
                    </button>
                </div>
            </>
        )
    }
}

export default ToolSelect;
