import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import ReactDropdown from "react-dropdown";

import styles from "../../Ticket.module.scss";
import history from "../../../../../history/history";

class PowertrainSelect extends Component {
    static contextType = Context;

    state = {
        make: "",
        makes: [],
        model: "",
        models: [],
        powerTrain: "",
        protocolId: "",
        powerTrains: [],
        disabled: true
    };

    async componentDidMount() {
        this.setState({
            loading: true
        });
        try{
            let list = await this.context.getCompatibilityList();
            const makes = {};

            list.forEach((item, idx) => {
                if(makes[item.vehicleMake] === undefined) makes[item.vehicleMake] = {}
                let make = makes[item.vehicleMake]

                if(make[item.vehicleModel] === undefined) make[item.vehicleModel] = {}
                let model = make[item.vehicleModel]

                if(model[item.vehiclePowerTrain] === undefined) model[item.vehiclePowerTrain] = item
            })

            this.setState({
                makes,
                disabled: false
            });
        } catch {
            history.push("/projects");
        }
        this.setState({
            loading: false
        })
    }

    updateMake = (event) => {
        try {
            this.setState({
                make: event.target.value,
                model: "",
                powerTrain: "",
                powerTrains: [],
            })
        } catch {
            history.push("/projects");
        }
    };

    updateModel = async (event) => {
        try {
            this.setState({
                model: event.target.value,
                powerTrain: "",
            });
        } catch {
            history.push("/projects");
        }

    };

    updatePowerTrain = (event) => {
        this.setState({
            powerTrain: event.target.value,
            protocol: `api/protocol_infos/${event.target.value}`
        })
    };

    handleSubmit = () => {
        let protocol = this.state.makes[this.state.make][this.state.model][this.state.powerTrain];
        let subject = `[${protocol.ecuManufacturer} ${protocol.ecuProtocol}] ${protocol.vehicleMake.replace(/  +/g, ' ')}, ${protocol.vehicleModel.replace(/  +/g, ' ')}, ${protocol.vehiclePowerTrain.replace(/  +/g, ' ')}`

        this.props.handleSubmit(subject)
    }

    render() {
        let valid = true;
        const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
        if(!regex.test(this.state.projectName)) valid = false;
        if(!regex.test(this.state.make)) valid = false;
        if(!regex.test(this.state.model)) valid = false;
        if(!regex.test(this.state.powerTrain)) valid = false;

        return (
            <>
                <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                    <NavLink to={`/support-tickets/new-ticket`}>
                        <img src={require('images/icons/back.svg')} alt="Back"/>
                    </NavLink>
                    <h3>Report Rejected File</h3>
                </div>

                <div className={styles.content}>
                    <div className={`${styles.itemList}`}>
                        <div>
                            <h3>Powertrain</h3>
                            <p className="txtSecondary">
                                Please select the vehicle from our compatibility list your file is from.
                                Please note we are unable to help with vehicles that don't appear below.
                            </p>

                            <div className={styles.options}>
                                <div className={styles.select}>
                                    <p>Manufacturer <span className="txtDanger">*</span></p>

                                    <select value={this.state.make} name="make" disabled={this.state.disabled} onChange={this.updateMake}>
                                        <option disabled selected value="">Select...</option>
                                        {Object.keys(this.state.makes).sort().map((make, idx) => {
                                            return <option key={idx} value={make}>{make}</option>
                                        })}
                                    </select>

                                </div>
                                <div className={styles.select}>
                                    <p>Model <span className="txtDanger">*</span></p>

                                    <select value={this.state.model} name="model" disabled={this.state.disabled} onChange={this.updateModel}>
                                        <option disabled selected value="">Select...</option>
                                        {this.state.make &&
                                            Object.keys(this.state.makes[this.state.make]).sort().map((model, idx) => {
                                                return <option key={idx} value={model}>{model}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={styles.select}>
                                    <p>Powertrain <span className="txtDanger">*</span></p>

                                    <select value={this.state.powerTrain} name="powerTrain" disabled={this.state.disabled} onChange={this.updatePowerTrain}>
                                        <option disabled selected value="">Select...</option>
                                        {this.state.make && this.state.model &&
                                            Object.keys(this.state.makes[this.state.make][this.state.model]).sort().map((powerTrain, idx) => {
                                                return <option key={idx} value={powerTrain}>{powerTrain}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`bgPrimary highlightSecondary ${styles.buttonActions}`}>
                        <p className={styles.required}><span className="txtDanger">*</span> Required</p>
                        <button
                            className="btn btnBrand"
                            disabled={!valid}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            Next
                        </button>
                    </div>
            </>
        )
    }
}

export default PowertrainSelect;
