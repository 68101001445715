import React, { Component } from 'react';
import { Context } from 'context/context';

import DynoGraph from "./DynoGraph";

import styles from 'dashboard/projects/project/Project.module.scss';

class BaseWizard extends Component {
    static contextType = Context;

    state = {
        loading: false,
        stockMax: null,
        stageOneMax: null,
    };

    async componentDidMount() {
        this.setState({
            loading: true
        });
        let promises = [];

        if(this.props.wizard.dynoHash){
            promises.push(await this.context.getWizardDynoData(this.props.wizard.dynoHash))
        }

        const dyno = await Promise.all(promises);

        let stockMax = null;
        let stageOneMax = null;
        let wizardChange = null;
        if(dyno[0]) {
            stockMax = Math.max(...dyno[0].dyno[0].stock.hp);
            stageOneMax = Math.max(...dyno[0].dyno[0].stage1.hp);
            wizardChange = stageOneMax - stockMax
        }

        this.setState({
            dyno: dyno[0],
            loading: false,
            wizardChange
        })
    }

    render() {
        return (
            !this.state.loading ?
                <div onClick={this.props.selectBase.bind(this, this.props.wizard.id)} className={`highlightTertiary bgTertiary ${this.props.base === this.props.wizard.id ? styles.selected : null}`}>

                    <div>
                        <div>
                            {this.props.base === this.props.wizard.id ?
                                this.props.wizard.purchased ?
                                    <img src={require(`images/icons/${this.context.lightTheme ? "radio-light" : "radio-dark"}.svg`)} alt="Already paid" />
                                    :
                                    <img src={require(`images/icons/${this.context.lightTheme ? "addon-light" : "addon-dark"}.svg`)} alt="Selected" />
                            :
                                <img src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)} alt="Not Selected" />
                            }
                            <p>{this.props.wizard.name.toLowerCase()} Base Map</p>
                        </div>

                        <div className={styles.wizardDetails}>
                            {this.state.wizardChange && <p className={styles.increase}>+{this.state.wizardChange}<span>HP</span></p>}
                        </div>
                    </div>

                    {this.state.dyno && this.props.wizard.dyno &&
                        <div className={styles.dyno}>
                            <DynoGraph {...this.state.dyno} />
                        </div>
                    }

                </div>
            :
                null
        )
    }
}

export default BaseWizard;
