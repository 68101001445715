import Api from "data/repository/Api";

export const getLogs = async (perPage, sortBy, ascending) => {
    const searchURL = `order[${sortBy}]=${ascending}&page=1&itemsPerPage=${perPage}`;
    const res = await Api().get(`/ecu_data_logs?${searchURL}`);
    return { logs: res.data['hydra:member'], totalLogs: res.data['hydra:totalItems'] };
};

export const getLog = async (id) => {
    const res = await Api().get(`/ecu_data_logs/${id}`);
    return res.data;
};

export const updateLog = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/ld+json"
        }
    };
    return Api().put(`/ecu_data_logs/${data.id}`, data, config);
};

export const deleteLog = async (id) => {
    return await Api().delete(`/ecu_data_logs/${id}`);
};

export const downloadLog = async (logId) => {
    const config = {
        headers: {
            'content-type': 'application/ld+json',
        },
        responseType: 'blob'
    };
    return await Api().get(`/ecu_data_logs/${logId}/export-csv`, config);
};