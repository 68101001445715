import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Context } from 'context/context';
import history from 'history/history'

import wizards from '../NewTickets/imports/wizards.json'

import styles from "../../Ticket.module.scss";
import Loading from "../../../../../components/Loading";

class WizardRequest extends Component {
    static contextType = Context;

    state = {
        apiTemplateNames: [],
        apiAddonNames: [],
        allWizards: {
            templates: [],
            addons: []
        },
        subject: "",
        notes: "",
        templateNotes: ["Requested Templates:\n"],
        addonNotes: ["Requested Addons:\n"],
    };

    async componentDidMount() {
        if(!this.context.subscriptionVariables.isProUser) history.push('/support-tickets/new-ticket')
        this.setState({
            loading: true
        })
        const fileWizards = await this.context.getWizards(this.props.match.params.id)
        const fileTemplates = fileWizards.filter((wizard) => { return wizard.wizType === "Base" });
        const fileAddons = fileWizards.filter((wizard) => { return wizard.wizType === "Patch" });
        const apiTemplateNames = fileTemplates.map(apiTemplate => apiTemplate.name.toLowerCase())
        const apiAddonNames = fileAddons.map(apiAddon => apiAddon.name.toLowerCase())

        let templates = wizards.templates.map((wizard) => {
            return {
                name: wizard,
                selected: false
            }
        });

        let addons = wizards.addons.map((wizard) => {
            return {
                name: wizard,
                selected: false
            }
        });

        let allWizards = {
            templates,
            addons
        }

        let projectIndex = this.context.getProjectIndex(this.props.match.params.id);
        let contextProject = this.context.projects[projectIndex].protocolInformation;
        let subject = [
            contextProject.vehicleMake.name,
            contextProject.vehicleModel.name.replace(' ()', ''),
            contextProject.vehiclePowerTrain.name.replace(' ()', ''),
        ].join(', ');

        subject = `[${contextProject.ecuManufacturer} ${contextProject.ecuProtocol}] ` + subject;

        this.setState({
            apiTemplateNames,
            apiAddonNames,
            allWizards,
            subject,
            loading: false
        })
    }

    selectBase = (name) => {
        let allWizards = this.state.allWizards;
        allWizards.templates = allWizards.templates.map((wizard) => {
            return {
                name: wizard.name,
                selected: wizard.name === name ? !wizard.selected : wizard.selected
            }
        });

        let templateNotes = this.state.templateNotes
        if(!templateNotes.includes(`- ${name}\n`)) {
            templateNotes.push(`- ${name}\n`)
        } else {
            templateNotes.splice(templateNotes.indexOf(`- ${name}\n`), 1)
        }

        this.setState({
            allWizards,
            templateNotes
        });
    };

    selectPatch = (name) => {
        let allWizards = this.state.allWizards;
        allWizards.addons = allWizards.addons.map((wizard) => {
            return {
                name: wizard.name,
                selected: wizard.name === name ? !wizard.selected : wizard.selected
            }
        });

        let addonNotes = this.state.addonNotes
        if(!addonNotes.includes(`- ${name}\n`)) {
            addonNotes.push(`- ${name}\n`)
        } else {
            let index = addonNotes.indexOf(`- ${name}\n`)
            addonNotes.splice(index, 1)
        }

        this.setState({
            allWizards,
            addonNotes
        });
    };

    handleSubmit = () => {
        let templateString = ""
        let addonString = ""
        if(this.state.templateNotes.length > 1) templateString = this.state.templateNotes.join('');
        if(this.state.addonNotes.length > 1) addonString = this.state.addonNotes.join('');
        let notes = `${templateString}\n ${addonString}`

        let subject = this.state.subject;
        let numberSelected = (this.state.templateNotes.length - 1) + (this.state.addonNotes.length - 1)
        let wizardNumber = ` - ${numberSelected} Wizard${numberSelected > 1 ? "s" : ""} Requested`
        subject += wizardNumber

        this.props.handleSubmit(
            this.props.ticketType,
            subject,
            notes,
            this.props.match.params.id
        )
    }

    render() {
        let disabled = false;
        if(!this.state.allWizards.templates.some((template) => { return template.selected; }) && !this.state.allWizards.addons.some((addon) => { return addon.selected; })) disabled = true;
        if(!this.state.loading) {
            return (
                <>
                    <div className={`bgTertiary highlightTertiary ${styles.sidebarHead}`}>
                        <NavLink to={`/support-tickets/new-ticket/request`}>
                            <img src={require('images/icons/back.svg')} alt="Back"/>
                        </NavLink>

                        <div>
                            <h3>Request Stage Templates & Add-ons</h3>
                        </div>
                    </div>

                    <div className={styles.content}>
                        <div className={styles.itemList}>
                            <div>
                                <h3>Request Stage Templates</h3>
                                <p className="txtSecondary">Stage wizard layers override any existing modifications to your
                                    file and provide a starting point for you to tune from.</p>

                                <div className={`${styles.options}`}>
                                    {this.state.allWizards.templates.map((template, idx) => {
                                        if(this.state.apiTemplateNames.includes(template.name.toLowerCase())) {
                                            return (
                                                <div className={`${styles.disabled} ${styles.optionContainer} bgTertiary highlightTertiary`}>
                                                    <div className={styles.info}>
                                                        <img src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)} alt="Not Selected" />
                                                        <p>{template.name}</p>
                                                    </div>

                                                    <p className={styles.desc}>Already Available</p>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div onClick={this.selectBase.bind(this, template.name)} className={`${styles.optionContainer} bgTertiary highlightTertiary ${template.selected ? styles.selected : null}`}>
                                                    <div className={styles.info}>
                                                        {template.selected ?
                                                            <img
                                                                src={require(`images/icons/${this.context.lightTheme ? "addon-light" : "addon-dark"}.svg`)}
                                                                alt="Selected"
                                                            />
                                                        :
                                                            <img
                                                                src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)}
                                                                alt="Not Selected"
                                                            />
                                                        }

                                                        <p>{template.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>

                            <div className={`${styles.addons} highlightTertiary`}>
                                <h3>Request Addons</h3>
                                <p className="txtSecondary">Feature wizards build on your Stage Wizard layer, allowing you
                                    to apply specific modifications.</p>

                                <div className={`${styles.options}`}>
                                    {this.state.allWizards.addons.map((addon, idx) => {
                                        if(this.state.apiAddonNames.includes(addon.name.toLowerCase())) {
                                            return (
                                                <div className={`${styles.disabled} ${styles.optionContainer} bgTertiary highlightTertiary`}>
                                                    <div className={styles.info}>
                                                        <img src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)} alt="Not Selected" />
                                                        <p>{addon.name}</p>
                                                    </div>

                                                    <p className={styles.desc}>Already Available</p>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div onClick={this.selectPatch.bind(this, addon.name)} className={`bgTertiary highlightTertiary ${styles.optionContainer} ${addon.selected ? styles.selected : null}`}>
                                                    <div className={styles.info}>
                                                        {addon.selected ?
                                                            <img
                                                                src={require(`images/icons/${this.context.lightTheme ? "addon-light" : "addon-dark"}.svg`)}
                                                                alt="Selected"
                                                            />
                                                        :
                                                            <img
                                                                src={require(`images/icons/${this.context.lightTheme ? "unselected-grey" : "unselected"}.svg`)}
                                                                alt="Not Selected"
                                                            />
                                                        }

                                                        <p>{addon.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`bgPrimary highlightTertiary ${styles.buttonActions}`}>
                        <button
                            className="btn btnBrand"
                            disabled={disabled}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            Request
                        </button>
                    </div>
                </>
            )
        } else {
            return <Loading />
        }
    }
}

export default WizardRequest;
