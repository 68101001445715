import React, { Component } from 'react';

import styles from "components/Components.module.scss";

export default class ProgressBar extends Component {
    render() {
        const totalArray = new Array(this.props.total)
        totalArray.fill(false)
        totalArray.fill(true, totalArray.length - this.props.value, totalArray.length)
        return (
            <div className={styles.progress}>
                {totalArray.map((item, index) => <span className={item? styles.active : null} key={index}/>)}
            </div>
        );
    }
}