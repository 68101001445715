import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import moment from 'moment';

import styles from 'dashboard/support/Support.module.scss';

class Item extends Component {
    static contextType = Context;

    render (){
        return (
            <NavLink className={`bgSecondary ${this.context.lightTheme? styles.lightBorder : styles.darkBorder} ${styles.ticket} ${this.props.statusStringHuman.toLowerCase()}`} to={`/support-tickets/ticket/${this.props.id}`}>
                <div>
                    <p className={`${styles.status} ${this.props.statusStringHuman.toLowerCase()}`}>{this.props.statusStringHuman}</p>
                    <p className={styles.subject}>{this.props.subject}</p>
                </div>

                <div>
                    <p className={`${styles.updatedAt}`}>{moment(this.props.updatedDate).format('MMM Do, HH:mm')}</p>
                </div>
            </NavLink>
        )
    }
}

export default Item;