import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import moment from "moment";

import styles from 'dashboard/logs/Logs.module.scss';

class List extends Component {
    static contextType = Context;

    render() {
        return (
            <div className={styles.list}>
                {this.context.logs.map((log, idx) => {
                    return (
                        <NavLink key={idx} to={`/logs/${log.id}/actions`} className={`${styles.log} ${this.context.lightTheme? styles.lightBorder : styles.darkBorder} bgSecondary`}>
                            <div>
                                <p className="txtPrimary">{log.name}</p>
                                <p className="txtSecondary">Created: {moment(log.logCreated).format('MMM Do, HH:mm')}</p>
                            </div>
                        </NavLink>
                    )
                })}
            </div>
        )
    }
}

export default List;

//${Config.diagnostics}/${log.id}