import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import tools from "./imports/tools.json";

import ToolSelect from "./ToolSelect";
import PowertrainSelect from "./PowertrainSelect";

import history from "../../../../../history/history";

class RejectedRequest extends Component {
    static contextType = Context;

    state = {
        selectedTool: null,
        checkMaster: false,
        modifiedValue: "",
        otherTool: "",
        step: 1,
    };

    async componentDidMount() {
        if(!this.context.subscriptionVariables.isProUser) history.push('/support-tickets/new-ticket')
    }

    updateSelectedTool = (e) => {
        this.setState({
            selectedTool: e.value
        })
    };

    changeCheck = () => {
        this.setState({
            checkMaster: !this.state.checkMaster
        })
    };

    updateButtons = (value) => {
        this.setState({
            modifiedValue: value,
        })
    };

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    updateStep = (step) => {
        this.setState({
            step: step
        })
    }

    handleSubmit = (subject) => {
        let toolString = "";

        toolString = `Tool Used: **${this.state.selectedTool}**`
        if(this.state.selectedTool === "Other") {
            toolString = `Tool Used: **${this.state.selectedTool} - ${this.state.otherTool}**`
        }

        let modifiedString = `The file has previously been modified: **${this.state.modifiedValue}**`

        let notes = `${toolString}\n\n${modifiedString}`;

        this.props.handleSubmit(
            this.props.ticketType,
            subject,
            notes,
            this.props.match.params.projectId
        )
    }

    render() {
        let toolOptions = tools.map((tool) => {
            return {
                value: tool,
                label: tool
            }
        })

        toolOptions.push({value: "Other", label: "Other"})

        let projectIndex = this.context.getProjectIndex(this.props.match.params.projectId);

        return (
            this.state.step === 1 ?
                <ToolSelect
                    checkMaster={this.state.checkMaster}
                    changeCheck={this.changeCheck}
                    otherTool={this.state.otherTool}
                    selectedTool={this.state.selectedTool}
                    modifiedValue={this.state.modifiedValue}
                    updateButtons={this.updateButtons}
                    updateSelectedTool={this.updateSelectedTool}
                    updateState={this.updateState}
                    updateStep={this.updateStep}
                />
                :
                <PowertrainSelect
                    {...this.props}
                    updateSelectedTool={this.updateSelectedTool}
                    selectedTool={this.state.selectedTool}
                    projectIndex={projectIndex}
                    updateStep={this.updateStep}
                    handleSubmit={this.handleSubmit}
                />
        )
    }
}

export default RejectedRequest;
