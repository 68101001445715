import React, { Component } from 'react';
import Config from 'Config.js';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import segment from "segment";

import Copy from 'copy-to-clipboard';
import ReactSwitch from "react-switch";

import styles from "../../../projects/project/Project.module.scss";
import accountDetailsStyles from "../../../accountDetails/AccountDetails.module.scss";

class Sharing extends Component {
    static contextType = Context;

    copy = (e) => {
        Copy(e.target.value)
    };

    render() {
        return (
            <>
                <div className={`${styles.wizards} ${styles.reset}`}>
                    <div className={`bgTertiary highlightTertiary ${styles.sidebarHead}`}>
                        <NavLink to={`/logs/${this.props.id}/actions`}>
                            <img src={require('../../../../images/icons/back.svg')} alt="Back" onClick={this.props.closeSharing} />
                        </NavLink>

                        <h3>Sharing Options</h3>
                    </div>
                    <div className={`bgSecondary ${styles.content}`}>
                        <div className={styles.wizardOptions}>
                            <div>
                                <div className={`highlightTertiary ${accountDetailsStyles.theme}`}>
                                    <label className="reactSwitch">
                                        <div>
                                            <h3>Sharing</h3>
                                            <p className="txtSecondary">Enable sharing on this log to allow others to view it.</p>
                                        </div>

                                        <ReactSwitch
                                            onChange={this.props.updateSharing}
                                            checked={this.props.public}
                                            onColor="#fe6500"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                    </label>
                                </div>
                            </div>

                            {this.props.public ?
                                <div>
                                    <h3>Public Link <p className="txtSecondary" style={{display: "inline-block"}}>(Click to copy)</p></h3>

                                    <div className={`${styles.form} ${styles.verticle}`}>
                                        <div className={styles.copyDiv}>
                                            <input className={styles.copyText} value={`${Config.diagnostics}/${this.props.id}`} readOnly onClick={this.copy.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Sharing;
