import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Context } from 'context/context';
import segment from "segment";
import binaryEntropy from 'binary-shannon-entropy'
import equal from 'fast-deep-equal';
import { FileDrop } from "react-file-drop";
import { Route, Switch } from 'react-router-dom';
import List from "dashboard/projects/List";
import Project from 'dashboard/projects/project/Project';
import ProjectButtons from 'dashboard/projects/project/components/ProjectButtons'
import DemoFileButton from 'dashboard/projects/project/components/DemoFileButton'
import CreateProjectButton from 'dashboard/projects/project/components/CreateProjectButton'
import FreeTrialPopup from "components/FreeTrialPopup";

import Loading from "components/Loading";

import styles from "dashboard/Dashboard.module.scss";
import projectStyles from "./Projects.module.scss";


class Projects extends Component {
    static contextType = Context;

    state = {
        loading: true,
        showHover: false,
        showLoading: false,
        slaveDetected: false
    };

    async componentDidMount() {
        segment.page('Projects');
        await this.fetchProjects();

    }

    fetchProjects = async () => {
        await this.context.getProjects();
        this.setState({ loading: false })
    };

    enter = () => {
        this.setState({
            showHover: true
        })
    };

    leave = () => {
        this.setState({
            showHover: false
        })
    };

    fileUpload = async (file) => {
        if(file.size > 15000000) {
            this.context.setToast("Files must be under 12Mb", "Danger", 10000);
            return false;
        }

        await this.upload(file);
    };

    upload = async (file) => {
        this.setState({
            showHover: false,
            showLoading: true
        });
        try {
            const project = await this.context.uploadFile(file[0]);
            this.setState({
                showLoading: false
            });
            await segment.track("project_created", {
                application: 'dashboard',
                projectId: project.data.project.split('/')[3],
                projectType: 'user'
            });
            await this.fetchProjects();
        } catch {
            this.setState({
                showLoading: false
            })
        }
    };


    render() {
        let subscriptionPricePerMonth = this.context.subscriptionPricePerMonth;

        return (
            <>
                <Helmet>
                    <title>Projects - Speedweaver</title>
                </Helmet>

                <div className={styles.container}>
                    <div className={`${styles.heading} ${styles.projectHeading}`}>
                        <h1>Your Tuning Projects</h1>
                        <ProjectButtons {...this.state}
                            hasSlaveDetector={true}
                            toggleSlaveDetected={(isDetected) => this.setState({slaveDetected: isDetected})}
                        />
                    </div>
                    { this.state.loading ?
                        <Loading/>
                    :
                        <>
                            <FileDrop
                                className={`file-drop highlightTertiary ${this.context.lightTheme? 'light' : 'dark'} ${this.context.totalProjects <= 0 ? "dashed" : null}`}
                                onDrop={(e) => {this.fileUpload(e)}}
                                onFrameDragEnter={this.enter}
                                onFrameDragLeave={this.leave}
                            >
                                {this.context.totalProjects > 0 ?
                                    <List
                                        showHover={this.state.showHover}
                                        showLoading={this.state.showLoading}
                                        location={this.props.location}
                                        projects={this.context.projects}
                                    />
                                    : this.state.showLoading ?
                                        <Loading text={"Uploading..."}/>
                                        : this.state.showHover ?
                                            <div className={`${styles.centred} ${projectStyles.noProjects}`}>
                                                <img className={styles.centreImg} src={require('images/icons/upload.svg')} alt={""} />
                                                <p>Drop to upload</p>
                                            </div>
                                            :
                                            <div className={`${styles.centred} ${projectStyles.noProjects}`}>
                                                <h2>Click 'Create Project' to get started.</h2>
                                                <ProjectButtons {...this.state}
                                                    hasSlaveDetector={false}
                                                    toggleSlaveDetected={(isDetected) => this.setState({slaveDetected: isDetected})}
                                                />
                                                <p className={styles.dashboardP}>Alternatively drag and drop your file here</p>
                                            </div>
                                }
                            </FileDrop>
                        </>
                    }
                </div>
                {this.context.projects.length > 0 &&
                    <Project {...this.props}
                        fetchProjects={this.fetchProjects}
                    />
                }

                <Switch>
                    <Route
                        path="/projects/freetrial"
                        render={(props) => (
                            <FreeTrialPopup
                                heading="Subscribe Now"
                                subHeading={"Just " + this.context.currencySymbol + this.context.subscriptionPricePerMonth + " per month (exclusive of VAT)"}
                                buttonText="Start Tuning Now"
                            />
                        )}
                    />

                    <Route
                        path="/projects/resubscribe"
                        render={(props) => (
                            <FreeTrialPopup
                                heading="Subscribe Now"
                                subHeading={"Just " + this.context.currencySymbol + this.context.subscriptionPricePerMonth + " per month (exclusive of VAT)"}
                                buttonText="Start Subscription"
                            />
                        )}
                    />
                </Switch>

            </>
        )
    }
}

export default Projects;
