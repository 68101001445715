import React, { Component } from 'react';

import styles from '../Billing.module.scss';

class Steps extends Component {
    render() {

        let step = this.props.step;
        if(this.props.billingDetails) step = 3;
        if(this.props.complete) step = 4;

        return (
            <div className={styles.steps}>
                <div className={styles.container}>
                    {this.props.method &&
                        <>
                            <div className={step > 1 ? styles.complete : step === 1 ? styles.active : null}>
                                <img src={require("images/icons/person.svg")} alt="Person" />
                                <p>{this.props.method === 1 ? "Billing Information" : "Connect to PayPal"}</p>
                            </div>
                            <div className={step > 2 ? styles.complete : step === 2 ? styles.active : null}>
                                <img src={require("images/icons/card.svg")} alt="Card" />
                                <p>{this.props.method === 1 ? "Payment Information" : "Additional Details"}</p>
                            </div>
                            {!this.props.update &&
                                <div className={step > 3 ? styles.complete : step === 3 ? styles.active : null}>
                                    <img src={require("images/icons/tick-white.svg")} alt="Tick" />
                                    <p>Review &amp; Checkout</p>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default Steps;