import Api from "data/repository/Api";

export const getUser = async (id) => {
    const res = await Api().get(`/users/${id}`);
    return res.data;
};

export const getUserStats = async () => {
    const res = await Api().get("/user/stats");
    return res.data;
};

export const getProfilePicture = async () => {
    const res = await Api().get('user_profile_pictures?page=1');
    let profilePicture = null;
    if(res.data['hydra:member'][0]) profilePicture = res.data['hydra:member'][0].contentUrl;
    return profilePicture;
};

export const updateProfilePicture = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    return await Api().post("/user_profile_pictures", formData, config);
};

export const login = async (email, password) => {
    const data = {
        username: email,
        password: password
    };
    const res = await Api().post("/login", data);
    return res.data.token;
};

export const mercureLogin = async () => {
    const res = await Api().get("/mercure");
    return res.data.token;
};

export const createAccount = async (firstName, lastName, email, password) => {
    const data = {
        firstName,
        lastName,
        email,
        plainPassword: password
    };
    return await Api().post("/register", data);
};

export const requestPasswordReset = async (email) => {
    const data = {
        email: email
    };
    return await Api().post("/request-password-reset", data);
};

export const checkResetValid = async (code, userId) => {
    const data = {
        userId: userId,
        code: code,
    };
    return await Api().post("/request-password-reset-is-valid", data);
};

export const resetPassword = async (code, userId, newPassword) => {
    const data = {
        userId,
        code,
        newPassword
    };
    return await Api().post("/request-password-reset-attempt", data);
};

export const verifyEmailAddress = async (id, code) => {
    const data = {
        plainVerificationCode: parseInt(code)
    };
    const config = {
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    };
    return await Api().patch(`/users/confirm-email/${id}`, data, config);
};

export const updatePassword = (id, oldPassword, newPassword) => {
    const data = {
        plainPassword: newPassword,
        currentPassword: oldPassword
    };
    return Api().put(`/users/${id}/change-password`, data);
};

export const updateUser = async (id, data) => {
    const config = {
        headers: {
            "Content-Type": "application/merge-patch+json"
        }
    };
    const res = await Api().patch(`/users/${id}`, data, config);
    return res.data;
};

export const closeAccount = async (id) => {
    return await Api().delete(`/users/${id}`);
};