import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import styles from "../../Ticket.module.scss";
import Loading from "../../../../../components/Loading";
import history from "../../../../../history/history";

class Request extends Component {
    static contextType = Context;

    async componentDidMount() {
        if(!this.context.subscriptionVariables.isProUser) history.push('/support-tickets/new-ticket')
    }

    render() {
        return (
            <>
                <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                    <NavLink to={`/support-tickets/new-ticket`}>
                        <img src={require('images/icons/back.svg')} alt="Back"/>
                    </NavLink>
                    <h3>Request Stage Templates & Add-ons</h3>
                </div>
                <div className={styles.content}>
                    <div className={styles.itemList}>
                        <div>
                                {this.context.projects.length > 0 ?
                                    <>
                                        <h3>Choose a project</h3>
                                        <div className={`${styles.options}`}>
                                        {this.context.projects.map((project, idx) => {
                                            if(project.projectType !== "DEMO") {
                                                return (
                                                    project.tunerStatus === "rejected" ?
                                                        <div className={`${styles.disabled} ${styles.optionContainer} bgTertiary highlightTertiary`}>
                                                            <div className={styles.info}>
                                                                <p className={styles.optionText}>{project.originalFileName}</p>
                                                            </div>

                                                            <p className={`${styles.brand} ${styles.desc}`}>Rejected Project</p>
                                                        </div>
                                                    : project.activated ?
                                                        <NavLink to={`/support-tickets/new-ticket/request/${project.id}`} className={`${styles.optionContainer} bgTertiary highlightTertiary`}>
                                                            <div className={styles.info}>
                                                                <p className={styles.optionText}>{project.projectName ? project.projectName : project.originalFileName}</p>
                                                            </div>

                                                            <img src={require('images/icons/forward.svg')} alt="forward"/>
                                                        </NavLink>
                                                    :
                                                        <div className={`${styles.disabled} ${styles.optionContainer} bgTertiary highlightTertiary`}>
                                                            <div className={styles.info}>
                                                                <p className={styles.optionText}>{project.originalFileName}</p>
                                                            </div>

                                                            <p className={`${styles.brand} ${styles.desc}`}>Not Activated</p>
                                                        </div>
                                                )
                                            } else {
                                                return (
                                                    <div className={`${styles.disabled} ${styles.optionContainer} bgTertiary highlightTertiary`}>
                                                        <div className={styles.info}>
                                                            <p className={styles.optionText}>{project.projectName ? project.projectName : project.originalFileName}</p>
                                                        </div>

                                                        <p className={`${styles.desc} ${styles.demo}`}>Demo Project</p>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </>
                            :
                                <div className={styles.alert}>
                                    <img src={require(`images/icons/${this.context.lightTheme ? "alert-grey" : "alert"}.svg`)} alt="Alert" />
                                    <h3>No projects to show.</h3>
                                    <p>There are no projects on your account.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={`bgPrimary highlightSecondary ${styles.buttonActions}`}>
                    <NavLink to={`/projects`} className={"btn btnBrand"}>
                        Go to projects
                    </NavLink>
                </div>
            </>
        )
    }
}

export default Request;
