import React, { Component } from 'react';
import { Context } from 'context/context';

import { markdown } from "markdown";
import Linkify from 'react-linkify'
import moment from "moment";
import clone from 'clone'
import equal from "fast-deep-equal";

import styles from "dashboard/support/ticket/Ticket.module.scss";

class Chat extends Component {
    static contextType = Context;

    state = {
        comments: clone(this.props.comments)
    };

    chat = React.createRef();


    componentDidMount() {
        this.chat.current.scrollTop = 10000000000;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!equal(this.props.comments.length, this.state.comments.length)) {
            this.chat.current.scrollTop = 10000000000;
            this.setState({
                comments: clone(this.props.comments)
            })
        }
    }

    render() {
        return (
            <div className={`highlightSecondary ${this.props.status} ${styles.content} ${styles.chat}`} ref={this.chat}>
                <div className={`bgTertiary highlightTertiary ${styles.comment} ${styles.user}`}>
                    <div className="highlightTertiary">
                        <p>{this.context.user.firstName} {this.context.user.lastName}</p>
                        <span className={styles.divider} />
                        <span>{moment(this.props.createdDate).fromNow()}</span>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: markdown.toHTML(this.props.notes)}}/>
                    {this.props.attachments.length > 0 && <p className={styles.filesTitle}>Files Attached:</p>}
                    {this.props.attachments.length > 0 ?
                        this.props.attachments.map((attachment, idx) => {
                            let fileName = attachment.filePath.split("_");
                            fileName.splice(0, 1);
                            fileName = fileName.join("_");
                            return (
                                <div className={`highlightTertiary ${styles.attachment}`} key={idx}>
                                    <p>{fileName}</p>
                                    <a href={attachment.resourceUrl} target="_blank" rel="noopener noreferrer">
                                        <img src={require(`images/icons/${this.context.lightTheme ? "download-grey" : "download" }.svg`)} alt="Download" />
                                    </a>
                                </div>
                            )
                        })
                    :
                        null
                    }
                </div>

                {this.props.comments.map((comment, i) => {
                    const agent = !comment.createdBy || comment.createdBy.split("/")[3] !== this.context.user.id;
                    return (
                        <div key={i} className={`bgTertiary highlightTertiary ${styles.comment}`}>
                            <div className={`highlightTertiary ${!agent ? null : styles.greyBorder}`}>
                                <p>{comment.authorName}</p>
                                <span className={styles.divider} />
                                <span>{moment(comment.createdDate).fromNow()}</span>
                            </div>

                            {!comment.message.charAt(0) === "!" ?
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="_blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}>
                                    {comment.message}
                                </Linkify>
                            :
                                <div dangerouslySetInnerHTML={{__html: markdown.toHTML(comment.message)}}/>
                            }
                            {comment.attachments.length > 0 ?
                                comment.attachments.map((attachment, idx) => {

                                    let fileName = attachment.filePath.split("_");
                                    fileName.splice(0, 1);
                                    fileName = fileName.join("_");

                                    return (
                                        <div className={`highlightTertiary ${styles.attachment}`} key={idx}>
                                            <div>
                                                <img src={require(`images/icons/${this.context.lightTheme ? "file-grey" : "file" }.svg`)} alt="Remove" />
                                                <p>{fileName}</p>
                                            </div>
                                            <a href={attachment.resourceUrl} target="_blank" rel="noopener noreferrer">
                                                <img src={require(`images/icons/${this.context.lightTheme ? "download-grey" : "download" }.svg`)} alt="Download" />
                                            </a>
                                        </div>
                                    )
                                })
                            : null }
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Chat;
