import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";

import ReactDropdown from "react-dropdown";

import Loading from "components/Loading";

import styles from 'dashboard/projects/project/Project.module.scss';

class Details extends Component {
    static contextType = Context;

    state = {
        loading: true,
        name: "",
        project: null
    };

    componentDidMount() {
        this.setState({
            loading: false,
            name: this.props.name,
            project: this.props.project,
        });
    }

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    save = async () => {
        await this.context.updateLog({
            id: this.props.id,
            name: this.state.name,
            project: this.state.project
        });
        await segment.track('log_updated', {
            application: 'dashboard',
            logId: this.props.id,
            type: 'details'
        });
        await this.props.fetchLog();
        await this.props.fetchLogs();
    };

    updateSelectedProject = (e) => {
      this.setState({
          project: e.value
      })
    };

    render() {
        if(!this.state.loading){
            let changed = false;
            let valid = true;

            const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
            if((this.state.name !== this.props.name) && regex.test(this.state.name)) changed = true;
            if((this.state.project !== this.props.project) && regex.test(this.state.project)) changed = true;

            if(!regex.test(this.state.name)) valid = false;

            const disabled = changed && valid;

            let readyFiles = this.context.projects.filter((project) => { if(project.tunerStatus === "ready" || project.tunerStatus === "archived") return project; });

            return (
                <>
                    <div className={`bgSecondary ${styles.content}`}>
                        <div>
                            <div className={`${styles.form} ${styles.verticle}`}>
                                <div>
                                    <p>Log Name</p>
                                    <input className="bgTertiary" name="name" maxLength={255} value={this.state.name} onChange={this.updateState.bind(this)} />
                                </div>
                                <div>
                                    <p>Associated Project</p>
                                    <ReactDropdown
                                        className="bgTertiary"
                                        options = {
                                            readyFiles.map((project) => {
                                                let fileName = "";
                                                if(project.originalFileName){
                                                    fileName = project.originalFileName.split("_");
                                                    fileName.shift();

                                                    if(fileName == "blob") fileName = "Demo File";
                                                }

                                                return {
                                                    value: project["@id"],
                                                    label: project.activated ? project.projectName : fileName
                                                }
                                            })
                                        }
                                        value={this.state.project}
                                        onChange={this.updateSelectedProject}
                                    />
                                </div>
                            </div>
                            <div className={`highlightTertiary ${styles.form} ${styles.verticle}`}>
                                <h3>Log Parameters</h3>
                                <div>
                                    {this.props.headers.map((param) => {
                                        return <p>&bull; {param.label}</p>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`bgPrimary highlightTertiary ${styles.fileActions}`}>
                        <button className="btn btnBrand" disabled={!disabled} onClick={this.save.bind(this)}>
                            <img src={require('images/icons/tick-white.svg')} alt="" />
                            Save Details
                        </button>
                        <button className="btn btnDanger" onClick={this.props.deleteLog} disabled={this.props.deletingLog}>
                            <img src={require('images/icons/delete.svg')} alt="" />
                            {this.props.deletingLog ? "Deleting Log" : "Delete Log"}
                        </button>
                    </div>
                </>
            )
        } else {
            return <Loading/>
        }
    }
}

export default Details;
