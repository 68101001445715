import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import history from "history/history";
import { Context } from 'context/context';
import segment from "segment";

import Loading from "../../../components/Loading";

import styles from "dashboard/projects/project/Project.module.scss";

class Activation extends Component {
    static contextType = Context;

    state = {
        projectName: "",
        make: "",
        makes: [],
        model: "",
        models: [],
        powerTrain: "",
        protocolId: "",
        powerTrains: [],
        disabled: true
    };

    async componentDidMount() {
        this.setState({
            loading: true
        });
        try{
            this.setState({
                projectName: this.context.projects[this.props.contextProject].originalFileName.split('_')[1] === "blob" ? "Demo File" : this.context.projects[this.props.contextProject].originalFileName.split('_')[1],
                makes: await this.context.getMakes(this.context.projects[this.props.projectIndex].id),
                disabled: false
            });
        } catch {
            history.push("/projects");
        }
        this.setState({
            loading: false
        })
    }

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    updateMake = (event) => {
        try {
            this.setState({
                make: event.target.value,
                model: "",
                powerTrain: "",
                powerTrains: [],
                disabled: true
            }, async () => {
                this.setState({
                    models: await this.context.getModels(this.context.projects[this.props.projectIndex].id, this.state.make),
                    disabled: false
                });
            })
        } catch {
            history.push("/projects");
        }
    };

    updateModel = async (event) => {
        try {
            this.setState({
                model: event.target.value,
                powerTrain: "",
                disabled: true
            }, async () => {
                this.setState({
                    powerTrains: await this.context.getPowerTrains(this.context.projects[this.props.projectIndex].id, this.state.make, this.state.model),
                    disabled: false
                });
            });
        } catch {
            history.push("/projects");
        }

    };

    updatePowerTrain = (event) => {
        this.setState({
            powerTrain: event.target.value,
            protocol: `api/protocol_infos/${event.target.value}`
        })
    };

    save = async () => {
        await this.context.updateProject({
            projectId: this.context.projects[this.props.projectIndex].id,
            projectName: this.state.projectName,
            activated: true,
            protocolInformation: this.state.protocol
        });
        await segment.track('project_activated', {
            application: 'dashboard',
            projectId: this.context.projects[this.props.projectIndex].id,
            projectType: this.context.projects[this.props.projectIndex].projectType === "DEMO" ? "demo" : "user",
            ecuProtocol: this.context.projects[this.props.projectIndex].ecu
        });
        await this.props.fetchProject();
        await this.props.fetchProjects();
    };

    render() {
        let valid = true;
        const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
        if(!regex.test(this.state.projectName)) valid = false;
        if(!regex.test(this.state.make)) valid = false;
        if(!regex.test(this.state.model)) valid = false;
        if(!regex.test(this.state.powerTrain)) valid = false;

        return (
            this.state.loading ?
                <Loading />
            :
                <>
                    <div className={`bgTertiary highlightSecondary ${styles.activation}`}>
                        <div>
                            <NavLink to={`/projects`} className={styles.cross}>
                                <img src={require(`images/icons/${this.context.lightTheme ? "cross-dark" : "cross"}.svg`)} alt={"X"} />
                            </NavLink>
                            <p className={styles.sub}>Activate Project</p>
                            <h1>{this.state.projectName ? this.state.projectName : this.context.projects[this.props.contextProject].originalFileName.split('_')[1]}</h1>
                            <p className="txtSecondary">We need some basic information about your project and vehicle in order to get started.</p>
                        </div>
                    </div>

                    <div className={styles.activationContent}>
                        <div>
                            <p>Project Name</p>
                            <input className="bgTertiary" name="projectName" maxLength={255} value={this.state.projectName} onChange={this.updateState.bind(this)} />
                        </div>
                        <div>
                            <p>Manufacturer</p>

                            <select className="bgTertiary" value={this.state.make} name="make" disabled={this.state.disabled} onChange={this.updateMake}>
                                <option disabled value="">Select...</option>
                                {this.state.makes.map((make, i) => {
                                    return <option key={i} value={make.id}>{make.name}</option>
                                })}
                            </select>

                        </div>
                        <div>
                            <p>Model</p>

                            <select className="bgTertiary" value={this.state.model} name="model" disabled={this.state.disabled} onChange={this.updateModel}>
                                <option disabled value="">Select...</option>
                                {this.state.models.map((model, i) => {
                                    return <option key={i} value={model.id}>{model.name}</option>
                                })}
                            </select>
                        </div>
                        <div>
                            <p>Powertrain</p>

                            <select className="bgTertiary" value={this.state.powerTrain} name="powerTrain" disabled={this.state.disabled} onChange={this.updatePowerTrain}>
                                <option disabled value="">Select...</option>
                                {this.state.powerTrains.map((powerTrain, i) => {
                                    return <option key={i} value={powerTrain.protocolId}>{powerTrain.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                        <button className="btn btnBrand" disabled={!valid} onClick={this.save}>
                            <img src={require('images/icons/tick-white.svg')} alt="Tick" />
                            Activate Project
                        </button>
                        <button className="btn btnDanger" onClick={this.props.deleteProject} disabled={this.props.deletingProject}>
                            <img src={require('images/icons/delete.svg')} alt="" />
                            {this.props.deletingProject ? "Deleting Project" : "Delete Project"}
                        </button>
                    </div>
                </>
        )
    }
}

export default Activation;
