import React, { Component } from 'react';
import styles from "../../Billing.module.scss";

class PayPalExtraDetails extends Component {

    state = {
        company: "",
        vat_number: "",
        country: "",
    };

    componentDidMount() {
        this.setState({
            company: this.props.company,
            vat_number: this.props.vat_number,
            country: this.props.country
        })
    }

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            company: this.state.company,
            vat_number: this.state.vat_number,
            address: {
                country: this.state.country
            }
        };
        this.props.handleExtraDetails(data);
    };

    render() {
        return (
            <form className={styles.left}>
                <div>
                    <div className={styles.outer}>
                        <div className={styles.head}>
                            <h2>Additional Information</h2>
                            <div className={styles.securelyTransmitted}>
                                <img src={require('images/icons/padlock-grey.svg')} alt="Padlock" />
                                <p>Securely Transmitted</p>
                            </div>
                        </div>
                        <div className={`${styles.inner} ${styles.billingInformation}`}>
                            <div>
                                <label>Company (Optional)</label>
                                <input
                                    type="text"
                                    name="company"
                                    value={this.state.company}
                                    onChange={this.updateState}
                                    maxLength={255}
                                />
                            </div>
                            <div>
                                <label>VAT Number (Optional)</label>
                                <input
                                    type="text"
                                    name="vat_number"
                                    value={this.state.vat_number}
                                    onChange={this.updateState}
                                    maxLength={255}
                                />
                            </div>
                            <div>
                                <label>Country <span className={styles.required}>*</span></label>
                                <select
                                    name="country"
                                    value={this.state.country}
                                    onChange={this.updateState}
                                >
                                    {this.props.countries.map((country, i) => {
                                        return <option value={country.code} key={i}>{country.name}</option>
                                    })}
                                </select>
                            </div>
                            <div />
                            <p><span className={styles.required}>*</span> Required Fields</p>
                        </div>
                    </div>
                </div>
                <div className={styles.navigators}>
                    <span />
                    <button className="btn btnBrand" onClick={this.handleSubmit}>Continue</button>
                </div>
            </form>

        )
    }
}

export default PayPalExtraDetails;