import React, { Component } from 'react';
import { Redirect, NavLink, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Context } from 'context/context';
import segment from "segment";

import List from 'dashboard/support/List';
import Ticket from 'dashboard/support/ticket/Ticket';
import New from "dashboard/support/ticket/New";

import Loading from "components/Loading";
import FreeTrialPopup from "components/FreeTrialPopup";


import styles from 'dashboard/Dashboard.module.scss';
import equal from "fast-deep-equal/es6";

class Support extends Component {
    static contextType = Context;

    state = {
        loading: true,
        days: null,
        online: null,
    };

    async componentDidMount() {
        segment.page('Support Tickets');
        await this.context.getTickets();
        this.setState({
            loading: false
        })
    }

    render() {
        return (
            !this.state.loading ?
                <>
                    <div className={styles.container}>
                        <Helmet>
                            <title>Support Tickets - Speedweaver</title>
                        </Helmet>

                        <div className={`${styles.heading} ${styles.supportRight}`}>
                            <h1>Inbox</h1>
                            <div>
                                <span/>
                                <NavLink to="/support-tickets/new-ticket" className="btn btnBrand">+ Open New Ticket</NavLink>
                            </div>
                        </div>

                        <List tickets={this.context.tickets}/>
                    </div>

                    <Switch>

                        



                        <Route path="/support-tickets/new-ticket" component={Ticket} />
                        <Route exact path="/support-tickets/new-ticket/:ticketType/:projectId" component={New} />
                        <Route exact path="/support-tickets/ticket/:id" component={Ticket} />
                        

                        <Redirect to={"/support-tickets"} />

                    </Switch>

                </>
                :
                    <Loading/>
        )
    }
}

export default Support;
