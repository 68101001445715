import React, { Component } from 'react';

import styles from '../../Billing.module.scss';

class Coupon extends Component {

    state = {
        coupon: ""
    };

    removeCoupon = () => {
        this.setState({ coupon: "" });
        this.props.removeCoupon();
    };

    render() {
        return (
            <div className={styles.coupon}>
                <label>Coupon Code</label>
                <input type="text" value={this.state.coupon} onChange={(e) => { this.setState({ coupon: e.target.value }) }} disabled={!!this.props.coupon} />
                {this.props.coupon ?
                    <button onClick={this.removeCoupon} className="btn btnBrand">Remove Coupon</button>
                :
                    <button onClick={this.props.applyCoupon.bind(this, this.state.coupon)} className="btn btnBrand">Apply Coupon</button>
                }
            </div>
        )
    }
}

export default Coupon;