import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from 'history/history';
import { Context } from 'context/context';
import segment from "segment";

import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';

import Entry from 'entry/Entry'
import App from 'App';

import Toast from 'components/Toast';
import Paywall from 'components/Paywall';

import 'styles/themed-styles.scss';
import Config from "./Config";

class Root extends Component {
    static contextType = Context;

    componentDidMount() {
        // Gets users ID if they are or have been signed in.
        if(Cookies.get('token')){
            const referrer = Cookies.get('speedweaver-referrer') ? JSON.parse(Cookies.get('speedweaver-referrer')) : {};
            const token = Cookies.get('token');
            const {id, username, fullName} = jwtDecode(token);
            this.context.setUserId(id);
            Sentry.setUser({id, username, fullName});
            segment.identify(id, {
                email: username,
                name: fullName,
                ...referrer
            });
        }

        if(Cookies.get('lightTheme')) {
            this.context.setLightTheme(JSON.parse(Cookies.get('lightTheme')))
        } else {
            Cookies.set('lightTheme', true, { domain: Config.family, expires: 30 });
            this.context.setLightTheme(true)
        }
    }

    render() {
        return (
            <>
                <div className={`${this.context.lightTheme ? "light" : "dark"} app`}>
                    <div className="toaster">
                        {this.context.toasts.map((toast, idx) =>
                            <Toast key={idx}
                                   message={toast.message}
                                   type={toast.type}
                                   close={() => this.context.unsetToast(toast.id)}
                            />
                        )}
                    </div>



                    <Router history={history}>
                        <Switch>
                            {Cookies.get('token') ?
                                <App />
                            :
                                <Switch>
                                    <Route exact path="/log-in" component={Entry} />
                                    <Route exact path="/create-account" component={Entry} />
                                    <Route exact path="/forgot-password" component={Entry} />
                                    <Route path="/reset-password/:hash/:userId" component={Entry} />
                                    <Redirect to="/log-in" />
                                </Switch>
                            }
                            <Redirect to={{
                                pathname: "/log-in",
                                search: `?referrer=${window.location.href}`
                            }} />
                        </Switch>
                    </Router>
                </div>
            </>
        );
    }
}

export default Root;
