import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import segment from "segment";

import styles from './Components.module.scss'

import Purchase from "../dashboard/billing/actions/checkout/Purchase";
import BillingDetails from "../dashboard/billing/actions/checkout/BillingDetails";
import UpdateBillingDetails from "../dashboard/billing/actions/updateBillingDetails/UpdateBillingDetails";

class PaywallListSummary extends Component {
    static contextType = Context;

    render() {
        return (
            <div className={styles.planPoints}>
                <p>{this.props.title}:</p>
                {this.props.items.map((item, key) => {
                    if (item.selected === true || item.dyno === true) {
                        return (
                            <div className={styles.point} key={key}>
                                <img
                                    src={item.purchased === true ? require('images/icons/tick-success.svg') : require('images/icons/tick.svg')}
                                    alt={item.purchased === true ? "Already purchased" : "Summary Item"}/>
                                <p>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</p>
                            </div>
                        );
                    }
                })}
            </div>
        )
    }
}

class Paywall extends Component {
    static contextType = Context;

    state = {
        updateBillingDetails: false
    };

    componentDidMount() {
        segment.page('Paywall');
    }

    componentWillUnmount() {
        this.context.togglePayWall(false);
    }

    getTotalPrice = () => {
        return this.context.priceToPay;
    };

    getListOfItemsToPurchase = () => {
        let list = [];

        list.push(...this.context.projectItemsToPurchase.bases.filter((item) => {
            return item.purchased === false && item.dyno === true;
        }));

        list.push(...this.context.projectItemsToPurchase.patches.filter((item) => {
            return item.purchased === false && item.selected === true;
        }));

        return list;
    };

    purchaseWizardItems = async (purchaseState) => {
        let list = this.getListOfItemsToPurchase();
        await this.context.purchaseWizards(this.props.project.id, list.map((item) => item.id), purchaseState.coupon, purchaseState.actionTokenResultId);
        this.context.togglePayWall(false);
        this.props.apply();
    };

    updateBillingDetails = (state) => {
        this.setState({ updateBillingDetails: state })
    };

    render() {
        let products = [];
        let list = this.getListOfItemsToPurchase();

        let numBases = list.filter((item) => item.wizType === "Base").length;
        let numPatches = list.filter((item) => item.wizType === "Patch").length;

        if (numBases > 0) {
            products.push({
                itemCode: 'base-wizard',
                quantity: numBases
            })
        }

        if (numPatches > 0) {
            products.push({
                itemCode: 'patch-wizard',
                quantity: numPatches
            })
        }

        return (
            <div className={styles.payWall}>
                <span className={styles.opacityLayer} onClick={this.context.togglePayWall.bind(this, false)}/>
                    <div className={`${styles.popUpContainer}`}>
                        <img className={styles.close} src={require('images/icons/cross-grey.svg')} alt={""} onClick={this.context.togglePayWall.bind(this, false)}/>
                        <div className={styles.payWallPlan}>

                            <span>Project Basket</span>

                            <div>
                                <h2>Purchasing Wizards &amp; Patches</h2>
                                <p>You can use your selection as many times you like on this project</p>
                            </div>

                            <PaywallListSummary title="Wizards selected:" items={this.context.projectItemsToPurchase.bases}/>
                            <hr/>
                            <PaywallListSummary title="Patches selected:" items={this.context.projectItemsToPurchase.patches}/>
                            <hr/>
                        </div>

                        <div className={`${styles.payWallPlan}`}>
                            <Purchase
                                billingDetails={this.context.billingDetails}
                                projectId={this.props.projectId}
                                products={products}
                                action={this.purchaseWizardItems}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Paywall;
