import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import history from 'history/history';
import { Context } from 'context/context';

import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { detect } from 'detect-browser';

import Dashboard from 'dashboard/Dashboard';
import Entry from "entry/Entry";
import Config from "./Config";

class App extends Component {
    static contextType = Context;

    state = {
        loading: true
    };

    async componentDidMount() {
        const browser = detect();
        if(browser.name !== "chrome"){
            this.context.setToast("We recommend you use Google Chrome for the best Speedweaver experience.", "Neutral2", 0);
        }

        if(Cookies.get("token")){
            try {
                const token = Cookies.get("token");
                const decoded = jwtDecode(token);
                await this.context.mercureLogin();
                await this.context.getUser(decoded.id);
                await this.context.getPublishableToken();
                if(Cookies.get("mercureToken")) {
                    await this.context.subscribeTickets();
                    await this.context.subscribeProjects();
                }
                this.setState({ loading: false });
            } catch(error) {
                console.log(error)
                this.context.logout()
            }
        }
    }

    render() {
        if (!this.state.loading) {
            if (this.context.user.verifiedEmail !== null) {
                if (!this.context.user.verifiedEmail) {
                    return (
                        <Switch>
                            <Route exact path="/verify-account" component={Entry}/>
                            <Redirect to="/verify-account" />
                        </Switch>
                    )
                } else {
                    return (
                        <Switch>
                            <Route path="/" component={Dashboard}/>
                            <Redirect to="/" />
                        </Switch>
                    )
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}

export default withRouter(App);
