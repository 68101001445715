import React, { Component } from 'react';
import { Context } from 'context/context';

import styles from 'entry/Entry.module.scss';

class SmallTickForList extends Component {
    render() {
        return (
            <div>
                <img className={styles.img} src={require('images/icons/tick.svg')} alt="" />
                <p>{this.props.name}</p>
            </div>
        );
    }
}

class SmallTickForListSuccess extends Component {
    render() {
        return (
            <div>
                <img src={require('images/icons/tick-success.svg')} alt="" />
                <p>{this.props.name}</p>
            </div>
        );
    }
}
class SmallTickForListSuccessDual extends Component {
    render() {
        return (
            <div>
                <SmallTickForListSuccess name={this.props.nameFirst} />
                {
                    (this.props.nameSecond !== null) ? <SmallTickForListSuccess name={this.props.nameSecond}/> : ''
                }
            </div>
        );
    }
}



class TickBenefit extends Component {
    static contextType = Context;

    componentDidMount() {

    }

    getBenefitList = () => {
        return this.context.product.benefits;
    }

    get2PerLineAlternative = () => {
        let counter = 0;
        let rows = Math.ceil(this.getBenefitList().length / 2);
        let items = this.getBenefitList();
        let divs = [];

        for (let i = 0; i < rows; i++) {
            let item1 = items[counter];
            let item2 = (counter + 1 in items) ? items[counter + 1] : null;
            divs.push(<SmallTickForListSuccessDual nameFirst={item1} nameSecond={item2} key={i} />);
            counter += 2;
        }
        return divs;
    };

    getSingleList = () => {
        return this.getBenefitList().map((item, i) => {
            return <SmallTickForList name={item} key={i} />
        });
    }

    getInLineBullets = () => {
        let theString = "";
        let isFirstInList = true;
        return this.getBenefitList().map((item, i) => {
            if (isFirstInList) {
                isFirstInList = false;
                return item;
            } else {
                return (<span key={i}> &bull; {item.toString()}</span>);
            }
        });
    }

    render() {
        if (this.props.listAs === 'single') {
            return this.getSingleList();
        }
        if (this.props.listAs === 'twoPerLine') {
            return this.get2PerLineAlternative();
        }
        if (this.props.listAs === 'inLineBullets') {
            return this.getInLineBullets();
        }
    }
}

export default TickBenefit;