import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Context } from 'context/context';

import LogIn from 'entry/forms/LogIn';
import CreateAccount from 'entry/forms/CreateAccount';
import ForgotPassword from 'entry/forms/ForgotPassword';
import ResetPassword from 'entry/forms/ResetPassword';
import VerifyAccount from "entry/forms/VerifyAccount";

import styles from 'entry/Entry.module.scss';
import TickBenefit from "./benefits/TickBenefit";

class Entry extends Component {
    static contextType = Context;

    render() {
        let subscriptionPricePerMonth = this.context.subscriptionPricePerMonth;

        return (
            <div className={styles.container}>
                <div className={styles.home}>
                    <a href="https://www.speedweaver.co.uk" className="btn btnSecondary">Home</a>
                </div>
                <div className={styles.aside}>
                    <div className={styles.trailText}>
                        <h1>Just <span>€{subscriptionPricePerMonth}</span> per month</h1>
                        <p>All prices are exclusive of VAT</p>
                        <TickBenefit listAs="single" />
                    </div>
                    <img className={styles.editor} src={require('images/editor.png')}/>

                </div>
                <Switch>
                    <Route exact path="/log-in" component={LogIn}/>
                    <Route exact path="/create-account" component={CreateAccount}/>
                    <Route exact path="/forgot-password" component={ForgotPassword}/>
                    <Route exact path="/reset-password/:hash/:userId" component={ResetPassword}/>
                    <Route exact path="/verify-account" component={VerifyAccount}/>
                </Switch>
            </div>
        )
    }
}

export default Entry;
