import React, { Component } from 'react';
import { Context } from 'context/context';

import moment from 'moment';

import styles from 'dashboard/accountDetails/subscription/Subscription.module.scss';

class Expired extends Component {
    static contextType = Context;

    render() {
        return (
            <div className={`bgSecondary ${styles.status}`}>
                <p>Your subscription expired on {moment(this.props.date).format("Do MMMM")}.</p>
            </div>
        )
    }
}

export default Expired;
