import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";

import PasswordInput from 'components/PasswordInput';

import styles from './Forms.module.scss';

class ChangePassword extends Component {
    static contextType = Context;

    state = {
        processing: false,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        strength: null,
        error: false,
        submitText: "Change Password"
    };

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            strength: event.target.name === "newPassword" ? event.target.strength : this.state.strength,
            error: false,
            submitText: "Change Password"
        })
    };

    updateStateCurrent = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: false,
            submitText: "Change Password"
        })
    };

    handleSubmit = async (event) => {
        try {
            event.preventDefault();
            this.setState({
                processing: true
            });

            if(this.state.newPassword !== this.state.confirmNewPassword){
                this.setState({
                    processing: false,
                    error: true,
                    submitText: "New password doesn't match confirmed password."
                });
                this.context.setToast("Passwords do not match", "Danger", 10000);
                return false;
            }

            if(this.state.strength < 4){
                this.setState({
                    processing: false,
                    error: true,
                    submitText: "New password not strong enough."
                });
                this.context.setToast("Please choose a stronger password. Try to include capitals, symbols and numbers.", "Danger", 10000);
                return false;
            }

            await this.context.updatePassword(this.state.oldPassword, this.state.newPassword);
            await segment.track('password_changed', {
                application: 'dashboard',
                type: 'updated'
            });
            this.setState({
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
                processing: false
            })
        } catch(error) {
            this.setState({
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
                processing: false
            })
        }
    };

    render() {

        let disabled = false;

        const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
        if(this.state.error) disabled = true;
        if(!regex.test(this.state.oldPassword)) disabled = true;
        if(!regex.test(this.state.newPassword)) disabled = true;
        if(!regex.test(this.state.confirmNewPassword)) disabled = true;

        const submit_class = `btn ${this.state.error ? "btnDanger" : "btnBrand"}`;

        return (
            <div className={`highlightTertiary ${styles.form}`}>
                <div>
                    <h3>Change Password</h3>
                </div>
                <div>
                    <form className={styles.changePasswordForm} onSubmit={this.handleSubmit}>
                        <PasswordInput
                            marginTop={true}
                            autoComplete={false}
                            name="oldPassword"
                            placeholder="Current Password"
                            hideStrength={true}
                            value={this.state.oldPassword}
                            onChange={this.updateStateCurrent}
                            maxLength={255}
                        />
                        <PasswordInput
                            marginTop={true}
                            autoComplete={false}
                            name="newPassword"
                            placeholder="New Password"
                            value={this.state.newPassword}
                            onChange={this.updateState}
                            maxLength={255}
                        />
                        <PasswordInput
                            autoComplete={false}
                            name="confirmNewPassword"
                            placeholder="Confirm New Password"
                            value={this.state.confirmNewPassword}
                            hideStrength={true}
                            onChange={this.updateState}
                            maxLength={255}
                        />

                        <input
                            type="submit"
                            form="changePasswordForm"
                            disabled={disabled}
                            value={this.state.submitText}
                            className={submit_class}
                        />
                    </form>
                </div>
            </div>
        )        
    }
}

export default ChangePassword;
