import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';
import history from 'history/history';
import segment from "segment";
import styles from './Components.module.scss'
import TickBenefit from "../entry/benefits/TickBenefit";

class FreeTrialPopup extends Component {
    static contextType = Context;

    async componentDidMount() {
        await segment.page('Paywall');
    }

    async componentWillUnmount() {
        this.context.toggleFreeTrialPopup(false);
    }

    getBenefits() {

    }

    render() {
        return (
            <div className={styles.freeTrialPopup}>
                <span className={styles.opacityLayer} onClick={() => history.push('/projects')}/>
                <div className={`${styles.popUpContainer} bgSecondary`}>
                    <img className={styles.close} src={require(this.context.lightTheme ? 'images/icons/cross-dark.svg' : 'images/icons/cross.svg')} alt={""} onClick={() => history.push('/projects')}/>
                    <div className={styles.header}>
                        <h1>{this.props.heading}</h1>
                        <p className="txtPrimary">{this.props.subHeading}</p>
                        <p></p>
                    </div>
                    <div className={styles.content}>
                        <NavLink to={`/billing/subscribe`} className={`${styles.startFreeTrial} btn btnBrand`}>
                            {this.props.buttonText}
                        </NavLink>
                        <TickBenefit listAs="twoPerLine" />
                    </div>
                </div>
            </div>
        )
    }
}

export default FreeTrialPopup;