import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import styles from "dashboard/support/ticket/Ticket.module.scss";

class General extends Component {
    static contextType = Context;

    state = {
        uploading: false,
        subject: "",
        notes: ""
    };

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    render() {
        let projectId = this.props.match.params.projectId;

        return (
            <>
                <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                    <NavLink to={`/support-tickets/new-ticket`}>
                        <img src={require('images/icons/back.svg')} alt="Back" />
                    </NavLink>
                    <h3>{this.props.ticketType === "general" ? "General Query" : "Report An Issue"}</h3>
                </div>

                <div className={styles.content}>
                    <div className={styles.warning}>
                        <img src={require('../../../../../images/icons/warning.svg')} alt="Warning" />
                        <p className="txtSecondary"><b>Note:</b> We do not provide guidance or advice for any matters related to tuning your vehicle.</p>
                    </div>

                    <div className={styles.form}>
                        <div>
                            <p>Subject <span className="txtDanger">*</span></p>
                            <input className="bgTertiary" name="subject" onChange={this.updateState} placeholder="Subject" />
                        </div>
                        <div>
                            <p>Message <span className="txtDanger">*</span></p>
                            <textarea className="bgTertiary" name="notes" onChange={this.updateState} placeholder="Message" rows={8} />
                        </div>
                    </div>
                </div>
                <div className={`bgPrimary highlightSecondary ${styles.buttonActions}`}>
                    <p className={styles.required}><span className="txtDanger">*</span> Required</p>

                    <button
                        className="btn btnBrand"
                        onClick={this.props.handleSubmit.bind(this,
                            this.props.ticketType,
                            this.state.subject,
                            this.state.notes,
                            projectId ? projectId : "",
                            null
                        )}
                        disabled={this.state.subject.length === 0 || this.state.notes.length === 0}
                    >
                        Send
                    </button>
                </div>
            </>
        )
    }
}

export default General;
