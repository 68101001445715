import React, { Component } from 'react';

import styles from '../Billing.module.scss';

class Error extends Component {
    render() {
        return (
            <div>
                <div className={`${styles.errorPage} ${styles.left}`}>
                    <img src={require("images/icons/error-light.svg")} alt="Tick" />
                    <h2>There was a problem processing your request</h2>
                    <p>This can happen for a variety of reasons, please try again or use a different payment method. If the problem persists, please contact our support team via support@speedweaver.co.uk, or raise a support ticket.</p>
                    <button className="btn btnBrand" onClick={() => { window.location.reload() }}>Re-try</button>
                </div>
            </div>
        )
    }
}

export default Error;