import * as BillingRepository from '../repository/BillingRepository'
import sortObjectsArray from 'sort-objects-array'

export const getPlans = async () => {
    const data = await BillingRepository.getPlans();
    return data.filter((plan) => { return plan.state === "active" });
};

export const getPlan = async (secondaryResourceId) => {
    let plans = await BillingRepository.getPlan();
    return plans.data["hydra:member"].filter((plan) => { return plan.state === "active" && plan.secondaryResourceId === secondaryResourceId })[0];
};


export const getSubscriptions = async () => {
    let data = await BillingRepository.getSubscriptions();
    data = data.map((subscription) => {
        return {
            ...subscription,
            sort: new Date(subscription.dateCreated)
        }
    });
    data = sortObjectsArray(data, "sort", "desc");
    return data;
};

// export const getTrialInfo = async () => {
//     let data = await BillingRepository.getSubscriptions();
//     data = data.map((subscription) => {
//         return {
//             ...subscription,
//             sort: new Date(subscription.dateCreated)
//         }
//     });
//     data = sortObjectsArray(data, "sort", "desc");
//     return data[0];
// };

export const getPublishableToken = async () => {
    return await BillingRepository.getPublishableToken();
};

export const handleRecurlyToken = async (token_id, three_d_secure_action_result_token_id) => {
    return await BillingRepository.handleRecurlyToken(token_id, three_d_secure_action_result_token_id);
};

export const payPalExtraDetails = async (id, data) => {
    return await BillingRepository.payPalExtraDetails(id, data);
};

export const getBillingDetails = async () => {
    return await BillingRepository.getBillingDetails();
};

export const subscribe = async (projectId, coupon, three_d_secure_action_result_token_id) => {
    await BillingRepository.subscribe(projectId, coupon, three_d_secure_action_result_token_id);
};

export const changePlan = async (id, subscription_plan, coupon) => {
    return await BillingRepository.changePlan(id, subscription_plan, coupon);
};

export const cancel = (id, cancelReason) => {
    if(cancelReason.length === 0) cancelReason = "No Feedback Given";
    return BillingRepository.cancel(id, cancelReason);
};

export const reactivate = (id) => {
    return BillingRepository.reactivate(id);
};