import Config from "./Config";

import Analytics from "analytics";
import segmentPlugin from '@analytics/segment';

export default Analytics({
    app: 'speedweaver-web-application',
    plugins: [
        segmentPlugin({
            writeKey: Config.segment
        })
    ]
});