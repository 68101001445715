import React, { Component } from 'react';
import history from 'history/history';
import { Context } from 'context/context';
import segment from "segment";

import { Helmet } from "react-helmet";

import Loading from "components/Loading";

import styles from "dashboard/accountDetails/subscription/Subscription.module.scss";
import dashStyles from "../../Dashboard.module.scss";
import moment from "moment";

class Cancel extends Component {
    static contextType = Context;

    state = {
        loading: true,
        subscription: null,
        startCancel: false,
        reason: ""
    };

    async componentDidMount() {
        segment.page('Cancel Subscription');

        try {
            if(!this.context.subscriptions[0] || this.context.subscriptions[0].status === "canceled" || !this.context.subscriptionVariables.isProUser) {
                this.context.setToast("You have already canceled your plan.", "Danger",  10000);
                history.push(`/account`);
            }
            this.setState({ loading: false });
        } catch(error) {
            console.log(error);
            history.push("/account");
        }
    }

    startCancel = () => {
        this.setState({
            startCancel: true
        })
    }

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    cancel = async () => {
        await this.context.cancel(this.context.subscriptions[0].id, this.state.reason);
        await segment.track('subscription_cancelled', {
            application: 'dashboard',
            reason: this.state.reason
        });

        history.go(0)
        await this.context.getUserStats();
    };

    render() {
        if(!this.state.loading){
            return (
                <div className={styles.cancel}>
                    {!this.state.startCancel ?
                        <div className={styles.cancelForm}>
                            <p>Are you sure you want to cancel you Speedweaver subscription renewal? Once expired, you will no longer have access to your projects.</p>
                            <p>Your {this.context.subscriptionVariables?.isTrialActive ? 'trial' : 'subscription'} will remain active until: {moment(this.context.subscriptions[0]?.dateIntervalEnds).format("Do MMMM YYYY")}</p>
                            <button className="btn btnBrand" onClick={this.props.continue.bind(this)}>Continue Subscription</button>
                            <button className="btn btnDanger" disabled={this.state.startCancel} onClick={this.startCancel}>I want to cancel my subscription</button>
                        </div>
                    :
                    <div className={styles.cancelForm}>
                        <p>We would appreciate your feedback so we can improve Speedweaver in the future.</p>
                        <label>
                            <p>Cancellation Reason (Required):</p>
                            <textarea name="reason" placeholder="Feedback..." maxLength={255} onChange={this.updateState.bind(this)} />
                        </label>
                        <button className="btn btnBrand" onClick={this.props.continue.bind(this)}>Continue Subscription</button>
                        <button className="btn btnDanger" disabled={this.state.reason.length === 0} onClick={this.cancel}>Cancel Subscription</button>
                    </div>
                    }
                </div>
            )
        } else {
            return (
                <>
                    <div></div> {/* this div is here to fill a grid area */}
                    <Loading/>
                </>
            );
        }
    }
}

export default Cancel;
