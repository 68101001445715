class Config {
    api = "";
    editor = "";
    diagnostics = "";
    family = [];
    version = process.env.REACT_APP_VERSION;

    constructor() {
        switch(process.env.REACT_APP_ENV){
            case "develop":
                this.api = process.env.REACT_APP_API_DEV;
                this.diagnostics = process.env.REACT_APP_DIAGNOSTICS_DEV;
                this.editor = process.env.REACT_APP_EDITOR_DEV;
                this.dashboard = process.env.REACT_APP_DASHBOARD_DEV;
                this.family = process.env.REACT_APP_FAMILY_DEV;
                this.segment = process.env.REACT_APP_SEGMENT_DEV;
                this.mercure = process.env.REACT_APP_MERCURE_DEV;
                break;
            case "stage":
                this.api = process.env.REACT_APP_API_STAGE;
                this.diagnostics = process.env.REACT_APP_DIAGNOSTICS_STAGE;
                this.editor = process.env.REACT_APP_EDITOR_STAGE;
                this.dashboard = process.env.REACT_APP_DASHBOARD_STAGE;
                this.family = process.env.REACT_APP_FAMILY_STAGE;
                this.segment = process.env.REACT_APP_SEGMENT_DEV;
                this.mercure = process.env.REACT_APP_MERCURE_DEV;
                break;
            default:
                this.api = process.env.REACT_APP_API_PROD;
                this.diagnostics = process.env.REACT_APP_DIAGNOSTICS_PROD;
                this.editor = process.env.REACT_APP_EDITOR_PROD;
                this.dashboard = process.env.REACT_APP_DASHBOARD_PROD;
                this.family = process.env.REACT_APP_FAMILY_PROD;
                this.segment = process.env.REACT_APP_SEGMENT_PROD;
                this.mercure = process.env.REACT_APP_MERCURE_PROD;
        }
    }
}

export default Object.freeze(new Config());