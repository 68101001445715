import React, { Component } from 'react';
import { Context } from 'context/context';

import styles from 'dashboard/accountDetails/subscription/Subscription.module.scss';

class Status extends Component {
    static contextType = Context;

    render() {
        const subscription = this.context.subscriptions[0]
        if((subscription.status === "canceled" || subscription.status === "expired") && this.context.subscriptionVariables){
            return (
                <div className={`bgSecondary ${styles.status}`}>
                    {subscription.status === "canceled" && this.context.subscriptionVariables.isTrialActive ?
                        <p>You have cancelled the renewal of your subscription. Your free trial will end on {this.context.subscriptionVariables.trialEnds.toLocaleDateString()} at {this.context.subscriptionVariables.expDate.toLocaleTimeString()}. You will not be charged.</p>
                    : subscription.status === "canceled" ?
                        <p>Your subscription has been cancelled, it will expire on {this.context.subscriptionVariables.expDate.toLocaleDateString()} at {this.context.subscriptionVariables.expDate.toLocaleTimeString()}.</p>
                    :
                        <p>Your subscription has been cancelled, it will expire on {this.context.subscriptionVariables.expDate.toLocaleDateString()} at {this.context.subscriptionVariables.expDate.toLocaleTimeString()}.</p>
                    }
                </div>
            )
        } else {
            return false;
        }
    }
}

export default Status;