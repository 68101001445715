import * as UserRepository from '../repository/UserRepository'
import Config from "Config";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export const getUser = async (id) => {
    return await UserRepository.getUser(id);
};

export const getUserStats = async () => {
    return await UserRepository.getUserStats();
};

export const getProfilePicture = async () => {
    return await UserRepository.getProfilePicture();
};

export const updateProfilePicture = async (file) => {
    return await UserRepository.updateProfilePicture(file);
};

export const login = async (email, password) => {
    Cookies.remove('token', { domain: Config.family, path: '' });
    let date = new Date(0);
    const token = await UserRepository.login(email, password);
    date.setUTCSeconds(jwtDecode(token).exp);
    Cookies.set('token', token, { domain: Config.family, expires: date });
    return true;
};

export const mercureLogin = async () => {
    return await UserRepository.mercureLogin();
};

export const createAccount = async (firstName, lastName, email, password) => {
    return await UserRepository.createAccount(firstName, lastName, email, password);
};

export const requestPasswordReset = async (email) => {
    return await UserRepository.requestPasswordReset(email);
};

export const checkResetValid = async (code, userId) => {
    return await UserRepository.checkResetValid(code, userId);
};

export const resetPassword = async (code, userId, newPassword) => {
    return await UserRepository.resetPassword(code, userId, newPassword);
};

export const verifyEmailAddress = async (id, code) => {
    return await UserRepository.verifyEmailAddress(id, code);
};

export const updatePassword = (id, oldPassword, newPassword) => {
    return UserRepository.updatePassword(id, oldPassword, newPassword);
};

export const updateUser = (id, data) => {
    return UserRepository.updateUser(id, data);
};

export const closeAccount = (id) => {
    return UserRepository.closeAccount(id);
};