import React, { Component } from 'react';
import { Context } from 'context/context';

import Item from 'dashboard/projects/Item';
import Loading from "components/Loading";

import equal from 'fast-deep-equal';
import FreeTrialPopup from "components/FreeTrialPopup";
import styles from 'dashboard/Dashboard.module.scss';
import projectStyles from 'dashboard/projects/Projects.module.scss';

class List extends Component {
    static contextType = Context;

    state = {
        loading: true,
        projects: [],
    };

    componentDidMount() {
        this.updateProjects()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!equal(prevProps.projects, this.context.projects)) {
            this.updateProjects()
        }
    }

    updateProjects = () => {
        let projects = this.context.projects.map(this.translate);
        this.setState({
            projects,
            loading: false
        })
    };

    translate = (project) => {
        let fileName = project.originalFileName.split("_");
        fileName.shift();

        if(fileName == "blob") fileName = "Demo File";

        let ready = false;
        let progress = 0;
        let status = null;
        let rejected = false;

        switch(project.tunerStatus){
            case "waiting_to_be_sent_to_tuner":
                progress = 0;
                status = "Queued";
                break;
            case "waiting_for_identification":
                progress = 15;
                status = "Identifying Protocol";
                break;
            case "waiting_for_map_data":
                progress = 48;
                status = "Generating Map Packs";
                break;
            case "ready":
                ready = true;
                progress = 100;
                status = "Detection Complete";
                break;
            case "rejected":
                rejected = true;
                status = "Not Supported";
                break;
            case "error":
                rejected = true;
                status = "Error";
                break;
            case "archived":
                ready = true;
                progress = 100;
                status = "Archived";
                break;
            default:
                progress = 0;
                status = "Queued";
                break;
        }

        return {
            ready: ready,
            progress: progress,
            status: status,
            rejected: rejected,
            fileName
        };
    };

    loadMore = async () => {
        await this.context.setProjectsPerPage(this.context.projectsPerPage + 5);
        this.updateProjects();
    };

  

    render() {
        const hasLoaded = !this.state.loading
        const hasProjects = this.state.projects.length > 0
        const hasNotRefreshed = this.context.projects.length === this.state.projects.length

        return (
            hasLoaded && hasProjects && hasNotRefreshed?
                <div className={styles.list}>
                    {this.props.showLoading ?
                        <div className={`${projectStyles.project} highlightSecondary ${projectStyles.upload}`}>
                            <div>
                                <img src={require('images/animated/loading.svg')} alt={""} />
                                <p>Uploading your file...</p>
                            </div>
                            <div>
                                <span className={projectStyles.amberUploading} />
                                <p className="txtSecondary">Uploading... </p>
                            </div>
                        </div>
                    : this.props.showHover ?
                        <div className={`${projectStyles.project} highlightSecondary ${projectStyles.upload}`}>
                            <div>
                                <img src={require('images/icons/upload.svg')} alt={""} />
                                <p>Drop your file to upload it</p>
                            </div>
                        </div>
                    : null}

                    {this.context.projects.map((project, i) => {
                        project.open = this.props.open === project.id;
                        return (
                            <Item
                                key={project.id}
                                project={project}
                                itemState={this.state.projects[i]}
                                translate={this.translate}
                            />
                        )
                    })}
                    {this.context.totalProjects > this.props.projects.length ?
                        <div className={styles.pagination}>
                            <button className="btn btnSecondary btnOutline" onClick={this.loadMore} >Load More</button>
                        </div>
                    :
                        null
                    }
                </div>
            :
                <Loading/>
        )
    }
}

export default List;