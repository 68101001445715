import Api from "data/repository/Api";

export const getTickets = async (perPage) => {
    return await Api().get(`/tickets?order[updatedDate]=desc&page=1&itemsPerPage=${perPage}`);
};

export const getTicket = async (id) => {
    const res = await Api().get(`/tickets/${id}`);
    return res.data;
};

export const getComments = async (id) => {
    const res = await Api().get(`/tickets/${id}/items?order[createdDate]=asc`);
    return res.data["hydra:member"];
};

export const createTicket = async (ticketType, priority, subject, notes, project, attachments) => {
    const res = await Api().post("/tickets", {ticketType, priority, subject, notes, project, attachments});
    return res.data;
};

export const uploadAttachment = async (file) => {
    const data = new FormData();
    data.append("file", file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    const res = await Api().post("/ticket_attachments", data, config);
    return res.data;
};

export const addComment = async (ticketId, status, priority, message, attachments) => {
    return await Api().post("/ticket_thread_items", { ticket: `/api/tickets/${ticketId}`, status, priority, message, attachments });
};

export const solveTicket = async (ticketId) => {
    const data = {
        ticket: `/api/tickets/${ticketId}`,
        status: "STATUS_RESOLVED",
        message: "User self solved ticket."
    };
    return await Api().post("/ticket_thread_items", data);
};

export const openSolvedTicket = async (ticketId) => {
    const data = {
        ticket: `/api/tickets/${ticketId}`,
        status: "STATUS_OPEN",
        message: "Ticket re-opened by user."
    };
    return await Api().post("/ticket_thread_items", data);
};