import React, { Component } from 'react';
import { Context } from 'context/context';
import segment from "segment";

import Dropzone from 'react-dropzone';
import jwtDecode from 'jwt-decode';
import Cookies from "js-cookie";

import styles from './Forms.module.scss';

class EditProfilePicture extends Component {
    static contextType = Context;

    state = {
        processing: false
    };

    /**
     * When the user uploads their new profile picture, the image is passed down to user.js and sent to the API with the filepath
     * @param acceptedFiles - the file the user uploaded
     * @author Matthew Rowberry
     */
    updatePicture = async (acceptedFiles) => {
        try {
            this.setState({
                processing: true
            });
            await this.context.updateProfilePicture(acceptedFiles);
            await segment.track('account_updated', {
                application: 'dashboard',
                type: 'profilePicture'
            });
            const token = Cookies.get("token");
            const decoded = jwtDecode(token);
            await this.context.getUser(decoded.id);
            this.setState({
                processing: false
            })
        } catch(error) {
            this.setState({
                processing: false
            })
        }
    };

    render() {
        return (
            <div className={`highlightTertiary ${styles.form}`}>
                <div>
                    <h3>Update Profile Picture</h3>
                </div>
                <div>
                    {this.state.processing === true ?
                        <div className={styles.editProfilePicture}>
                            <img src={require('images/animated/loading.svg')} alt="Processing" />
                        </div>
                    :
                        <div className={styles.editProfilePicture}>
                            <form encType="multipart/form-data">
                                <Dropzone accept={'image/jpeg, image/png, image/jpg'} onDrop={acceptedFiles => this.updatePicture(acceptedFiles[0])}>
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()} className={styles.fileUpload}>
                                            <input {...getInputProps()} />
                                            <img src={require('images/icons/upload.svg')} alt="Upload" />
                                        </div>
                                    )}
                                </Dropzone>
                                <div>
                                    <img className={styles.profile} src={this.context.user.profilePicture ? this.context.getProfilePictureRoute() +this.context.user.profilePicture : require("images/stockProfile.svg")} alt="Profile" />
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default EditProfilePicture;
