import React, { Component } from 'react';

import styles from '../../Billing.module.scss';

class Loading extends Component {
    render() {
        return (
            <div className={styles.loading}>
                <div>
                    <p>Please wait...</p>
                </div>
            </div>
        )
    }
}

export default Loading;

