import React, { Component } from 'react';
import Config from "Config";
import { NavLink } from "react-router-dom";
import { Context } from 'context/context';

import styles from 'dashboard/projects/project/Project.module.scss';

class WizardSuccess extends Component {
    static contextType = Context;

    componentWillUnmount() {
        this.props.fetchProject()
    }

    render() {
        return (
            <>
                <div className={`bgSecondary ${styles.content}`}>
                    <div className={styles.wizardSuccess}>
                        <img src={require(`images/icons/${this.context.lightTheme ? "success-light" : "success"}.svg`)} alt="Success"/>
                        <h3>Wizards successfully applied!</h3>
                        <p>You can add additional modifications using the editing suite, or return to the main menu to export your modified project.</p>
                        <div>
                            <img src={require(`images/icons/warning.svg`)} alt="Warning"/>
                            <p><b>Please note: </b>We recommend you review changes in the editor before flashing to your vehicle. Speedweaver does not take responsibility for any flashing related issues.</p>
                        </div>
                    </div>
                </div>
                <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                    <a href={`${Config.editor}/${this.props.id}`} className="btn btnBrand">
                        <img src={require('images/icons/editor-noCirc.svg')} alt=""/>
                        Open in Editing Suite
                    </a>
                    <NavLink className="btn btnOutline bgSecondary" to={`/projects/${this.props.id}/tuning`}>
                        <img src={require(`images/icons/${this.context.lightTheme? 'tick-grey' : 'tick-white'}.svg`)} alt=""/>
                        Done
                    </NavLink>
                </div>
            </>

        )
    }
}

export default WizardSuccess;
