import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import history from "history/history";
import { Context } from 'context/context';
import segment from "segment";

import clone from 'clone';

import Loading from "components/Loading";

import styles from "dashboard/projects/project/Project.module.scss";

class Details extends Component {
    static contextType = Context;

    state = {
        loading: true,
        archiving: false,
        projectName: "",
        vin: "",
        customerName: "",
        customerPhone: "",
        notes: "",
        make: "",
        makes: [],
        model: "",
        models: [],
        powerTrain: "",
        protocolId: "",
        powerTrains: [],
        disabled: true,
        cloneProject: clone(this.context.projects[this.props.projectIndex])
    };

    async componentDidMount() {
        segment.page('Project > Details');

        try {
            const makes = await this.context.getMakes(this.props.project.id);
            const models = await this.context.getModels(this.props.project.id, this.props.project.protocolInformation.vehicleMake.id);
            const powerTrains = await this.context.getPowerTrains(this.props.project.id, this.props.project.protocolInformation.vehicleMake.id, this.props.project.protocolInformation.vehicleModel.id);
            this.setState({
                loading: false,
                projectName: this.state.cloneProject.projectName,
                vin: this.state.cloneProject.vehicleVin,
                customerName: this.state.cloneProject.customerName,
                customerPhone: this.state.cloneProject.customerPhone,
                notes: this.state.cloneProject.notes,
                make: this.state.cloneProject.protocolInformation.vehicleMake.id,
                model: this.state.cloneProject.protocolInformation.vehicleModel.id,
                powerTrain: this.state.cloneProject.protocolInformation.vehiclePowerTrain.id,
                protocolId: this.state.cloneProject.protocolInformation.id,
                makes,
                models,
                powerTrains,
                disabled: false
            });
        } catch (error) {
            history.push("/projects");
        }
    }

    updateState = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    updateMake = (event) => {
        this.setState({
            make: event.target.value,
            model: "",
            powerTrain: "",
            protocolId: "",
            powerTrains: [],
            disabled: true
        }, async () => {
            try {
                this.setState({
                    models: await this.context.getModels(this.props.project.id, this.state.make),
                    disabled: false
                })
            } catch {
                history.push("/projects");
            }
        })
    };

    updateModel = (event) => {
        this.setState({
            model: event.target.value,
            powerTrain: "",
            protocolId: "",
            disabled: true
        }, async () => {
            try {
                this.setState({
                    powerTrains: await this.context.getPowerTrains(this.props.project.id, this.state.make, this.state.model),
                    disabled: false
                })
            } catch(err) {
                history.push("/projects");
            }
        })
    };

    updatePowerTrain = (event) => {
        this.setState({
            powerTrain: event.target.value,
            protocol: `api/protocol_infos/${event.target.value}`,
            protocolId: event.target.value
        })
    };

    save = async () => {
        await segment.track('project_updated', {
            application: 'dashboard',
            projectId: this.props.project.id,
            projectType: this.props.project.projectType === "DEMO" ? "demo" : "user",
        });

        await this.context.updateProject({
            projectId: this.props.project.id,
            projectName: this.state.projectName,
            vehicleVin: this.state.vin,
            customerName: this.state.customerName,
            customerPhone: this.state.customerPhone,
            notes: this.state.notes,
            protocolInformation: this.state.protocol
        });

        this.setState({
            cloneProject: clone(this.context.projects[this.props.projectIndex])
        })
    };

    archive = async () => {
        this.setState({
            archiving: true
        }, async () => {
            await this.context.archiveProject(this.props.project.id);
            await this.props.fetchProjects();
            await this.props.fetchProject();

            this.setState({
                archiving: false
            })
        })

    };

    render() {
        if(!this.state.loading){
            let changed = false;
            let valid = true;

            const regex = new RegExp(/(.|\s)*\S(.|\s)*/);
            if((this.state.projectName !== this.props.project.projectName) && regex.test(this.state.projectName)) changed = true;
            if((this.state.vin !== this.props.project.vehicleVin) && regex.test(this.state.vin)) changed = true;
            if((this.state.customerName !== this.props.project.customerName) && regex.test(this.state.customerName)) changed = true;
            if((this.state.customerPhone !== this.props.project.customerPhone) && regex.test(this.state.customerPhone)) changed = true;
            if((this.state.make !== this.props.project.protocolInformation.vehicleMake.id) && regex.test(this.state.make)) changed = true;
            if((this.state.model !== this.props.project.protocolInformation.vehicleModel.id) && regex.test(this.state.model)) changed = true;
            if((this.state.protocolId !== this.props.project.protocolInformation.id) && regex.test(this.state.powerTrain)) changed = true;
            if((this.state.notes !== this.props.project.notes)) changed = true;

            if(!regex.test(this.state.projectName)) valid = false;
            if(!regex.test(this.state.make)) valid = false;
            if(!regex.test(this.state.model)) valid = false;
            if(!regex.test(this.state.powerTrain)) valid = false;
            if(this.state.archiving) valid = false;

            const disabled = changed && valid;

            return (
                <>
                    <div className={`bgSecondary ${styles.content}`}>
                        <div>
                            <div className={styles.form}>
                                <div>
                                    <p>Project Name</p>
                                    <input className="bgTertiary" name="projectName" maxLength={255} value={this.state.projectName} onChange={this.updateState.bind(this)} />
                                </div>
                                <div>
                                    <p>Vin / Reg Number</p>
                                    <input className="bgTertiary" name="vin" maxLength={25} value={this.state.vin} onChange={this.updateState.bind(this)} />
                                </div>
                                <div>
                                    <p>Customer Name</p>
                                    <input className="bgTertiary" name="customerName" maxLength={255} value={this.state.customerName} onChange={this.updateState.bind(this)} />
                                </div>
                                <div>
                                    <p>Customer Phone Number</p>
                                    <input className="bgTertiary" name="customerPhone" maxLength={255} value={this.state.customerPhone} onChange={this.updateState.bind(this)} />
                                </div>
                            </div>
                            <div className={`highlightSecondary ${styles.form}`}>
                                <div>
                                    <p>ECU Manufacturer</p>
                                    <div className={`${styles.locked} bgTertiary highlightTertiary`}>
                                        <img src={require('images/icons/padlock-grey.svg')} alt="Locked" />
                                        <p>{this.props.project.ecuManufacturer}</p>
                                    </div>
                                </div>
                                <div>
                                    <p>ECU Manufacturer</p>
                                    <div className={`${styles.locked} bgTertiary highlightTertiary`}>
                                        <img src={require('images/icons/padlock-grey.svg')} alt="Locked" />
                                        <p>{this.props.project.ecu}</p>
                                    </div>
                                </div>
                                <div>
                                    <p>Manufacturer</p>

                                    <select className="bgTertiary" value={this.state.make} name="make" disabled={this.state.disabled} onChange={this.updateMake}>
                                        <option selected disabled value="">Select...</option>
                                        {this.state.makes.map((make) => {
                                            return <option value={make.id}>{make.name}</option>
                                        })}
                                    </select>

                                </div>
                                <div>
                                    <p>Model</p>

                                    <select className="bgTertiary" value={this.state.model} name="model" disabled={this.state.disabled} onChange={this.updateModel}>
                                        <option selected disabled value="">Select...</option>
                                        {this.state.models.map((model) => {
                                            return <option value={model.id}>{model.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className={styles.powerTrain}>
                                    <p>Powertrain</p>

                                    <select className="bgTertiary" value={this.state.protocolId} name="powerTrain" disabled={this.state.disabled} onChange={this.updatePowerTrain}>
                                        <option selected disabled value="">Select...</option>
                                        {this.state.powerTrains.map((powerTrain) => {
                                            return <option value={powerTrain.protocolId}>{powerTrain.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className={`${`highlightSecondary ${styles.form}`} ${styles.notes}`}>
                                <div>
                                    <p>Notes</p>
                                    <textarea className="bgTertiary" name="notes" maxLength={255} value={this.state.notes} onChange={this.updateState.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`bgPrimary highlightSecondary ${styles.fileActions}`}>
                        <button className="btn btnBrand" disabled={!disabled} onClick={this.save.bind(this)}>
                            <img src={require('images/icons/tick-white.svg')} alt="" />
                            Save Details
                        </button>

                        {this.props.project.tunerStatus !== "archived" ?
                            null
                        :
                            <NavLink to={`/projects`} className="btn btnBrand" disabled={this.state.archiving} onClick={this.props.unArchive}>
                                <img src={require('images/icons/unarchive.svg')} alt="" />
                                Un-Archive File
                            </NavLink>
                        }

                        <button className="btn btnDanger" onClick={this.props.deleteProject} disabled={this.props.deletingProject || this.state.archiving}>
                            {this.props.deletingProject ?
                                <>
                                    <img src={require('images/animated/processing.svg')} alt="Processing" />
                                    Deleting Project
                                </>
                            :
                                <>
                                    <img src={require('images/icons/delete.svg')} alt="" />
                                    Delete Project
                                </>
                            }
                        </button>
                    </div>
                </>
            )
        } else {
            return <Loading/>
        }
    }
}

export default Details;
