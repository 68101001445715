import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Context } from 'context/context';

import Nav from 'dashboard/Nav';
import Projects from 'dashboard/projects/Projects';
import AccountDetails from 'dashboard/accountDetails/AccountDetails';
import Support from 'dashboard/support/Support';
import Logs from "dashboard/logs/Logs";
import Billing from "dashboard/billing/Billing";

import CompatibilitySearch from 'dashboard/compatibilitySearch/CompatibilitySearch'

import SelectSearch from 'dashboard/compatibilitySearch/components/SelectSearch'

import styles from 'dashboard/Dashboard.module.scss';
import Cookies from "js-cookie";
import Config from "../Config";
import Subscription from "./accountDetails/subscription/Subscription";
import Loading from "../components/Loading";

class Dashboard extends Component {
    static contextType = Context;

    state = {
        loading: true
    }
    async componentDidMount() {
        await this.context.getUserStats();
        await this.context.getProjects();
        this.setState({
            loading: false
        })
    }

    render() {
        if(!this.state.loading) {
            return (
                <>
                    <div className={!this.props.location.pathname.includes("billing") ? styles.dashboard : null}>
                        {!this.props.location.pathname.includes("billing") && <Nav /> }
                        <main className="bgPrimary">
                            <Switch>
                                <Route path="/projects/freetrial" component={Projects} />
                                <Route path="/projects/resubscribe" component={Projects} />
                                <Route path="/projects/:id" component={Projects} />
                                <Route path="/projects" component={Projects} />
                                <Route path="/logs/:id" component={Logs} />
                                <Route path="/logs" component={Logs} />
                                <Route path="/account" component={AccountDetails} />
                                <Route path="/support-tickets" component={Support}/>
                                <Route path="/billing" component={Billing}/>
                                <Route path="/compatibility-search" component={CompatibilitySearch} />
                                <Route path="/compatibility-search/ecu" component={SelectSearch} />
                                <Route path="/compatibility-search/vehicle" component={SelectSearch} />
                                <Redirect to="/projects"/>
                            </Switch>
                        </main>
                    </div>
                </>
            )
        } else {
            return null
        }
    }
}

export default Dashboard;