import React, { Component } from 'react';

import styles from '../Billing.module.scss';
import Accordion from "../actions/components/Accordion";

class FAQ extends Component {
    render() {
        return (
            <div className={styles.faq}>
                <div className={styles.container}>
                    <h2>Frequently Asked Questions</h2>
                    <div>
                        <Accordion
                            heading={`What payment methods can I use?`}
                            message={`We use Stripe as our payment processor, this means we support most major debit and credit cards. We also accept payment via PayPal.`}
                        />

                        <Accordion
                            heading={`What about taxes?`}
                            message={`Taxes are automatically calculated based on your location and our tax obligations in your region. All of our listed prices are exclusive of taxes such as VAT.`}
                        />

                        <Accordion
                            heading={`How do subscription cancellations work?`}
                            message={`You can cancel your subscription at any time, you will still have full access to your account and projects until the end of your current billing period.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default FAQ;