import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import styles from "../../Ticket.module.scss";

class ProjectSelect extends Component {
    static contextType = Context;

    render() {
        let projects = this.props.filter ? this.context.projects.filter(this.props.filter) : this.context.projects;

        return (
            <>
                <div className={`bgTertiary highlightSecondary ${styles.sidebarHead}`}>
                    <NavLink to={`/support-tickets/new-ticket`}>
                        <img src={require('images/icons/back.svg')} alt="Back"/>
                    </NavLink>
                    <h3>Select project</h3>
                </div>

                {this.props.skipTo ?
                    <div className={`bgTertiary highlightSecondary ${styles.buttonActions}`}
                         style={{borderTop: "none"}}>
                        <NavLink to={this.props.skipTo} className={"btn btnBrand"}>
                            Skip selection
                        </NavLink>
                    </div>
                    :
                    <></>
                }

                <div className={styles.content}>
                    <div className={styles.itemList}>
                        <div>
                            {projects.length > 0 ?
                                <>
                                    <h3>Choose a project</h3>
                                    <div className={`${styles.options}`}>
                                        {projects.map((project, idx) => {
                                            return (
                                                <NavLink key={idx} to={this.props.linkTo(project)} className={`${styles.optionContainer} bgTertiary highlightTertiary`}>
                                                    <div className={styles.info}>
                                                        <p className={styles.optionText}>{project.originalFileName.split("_", 2).pop()}</p>
                                                    </div>

                                                    <img src={require('images/icons/forward.svg')} alt="Forward"/>
                                                </NavLink>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <div className={styles.alert}>
                                    <img src={require(`images/icons/${this.context.lightTheme ? "alert-grey" : "alert"}.svg`)} alt="Alert" />
                                    <h3>No projects to show.</h3>
                                    <p>There are no projects on your account.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ProjectSelect;
