import React, { Component } from 'react';
import PropTypes from 'prop-types';

import equals from 'fast-deep-equal';

import styles from 'components/Components.module.scss';

export class TokenInput extends Component{
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        label: PropTypes.string
    };

    state = {
        code: [],
        length: 6
    };

    element = React.createRef();

    componentDidUpdate(prevProps) {
        if(!equals(prevProps, this.props) && this.props.value !== null){
            this.setState({
                code: this.props.value.toString().split('')
            })
        }
    }

    paste = (event) => {
        event.preventDefault();

        navigator.clipboard.readText()
        .then(code => {
            if(code.length === 6 && /^\d+$/.test(code)){

                code = code.split("");

                this.setState({code});
                this.updateFocus();
                this.update();
            }
        })
    };

    push = (event) => {
        if (event.target.value !== "") {
            const code = this.state.code;
            if (RegExp("[0-9]").test(event.target.value))
                code.push(event.target.value);

            this.setState({code});
            this.updateFocus();
            this.update();
        }
    };

    pop = (event) => {
        if (event.key === "Backspace"){
            const code = this.state.code;
            code.pop();

            this.setState({ code });
            this.updateFocus();
            this.update();
        }
    };

    updateFocus = () => {
        const codeInput = this.element.current;
        const currentChar = this.state.code.length === 6 ? this.state.code.length - 1 : this.state.code.length;

        if (codeInput.children[currentChar]) codeInput.children[currentChar].focus();
    };

    update = () => {
        const event = {
            target: {
                value: this.state.code.join(""),
                name: this.props.name,
                full: this.state.code.length === this.state.length
            }
        };
        this.props.onChange(event);
    };

    /**
     * Pads an array to required length using fill value
     * @param arr
     * @param len
     * @param fill
     * @returns {T[]}
     */
    pad = (arr,len,fill) => {
        return arr.concat(Array(len).fill(fill)).slice(0,len);
    };

    render() {
        const code = this.pad(this.state.code, this.state.length, "");
        return (
            <div className={styles.codeInput}>
                <p>{this.props.label}</p>
                <div className={styles.fields} ref={this.element} onClick={this.updateFocus}>
                    {code.map(char =>
                        <input
                            type="number"
                            value={char}
                            onMouseDown={(event => event.preventDefault())}
                            onChange={this.push}
                            onKeyDown={this.pop}
                            maxLength="1"
                            onPaste={this.paste.bind(this)}
                            pattern="\d*"
                        />
                    )}
                </div>
            </div>
        )
    }
}