import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import { Helmet } from "react-helmet";
import history from "history/history";
import { Context } from 'context/context';
import segment from "segment";

import ReactSwitch from "react-switch";

import Details from 'dashboard/accountDetails/forms/Details';
import EditProfilePicture from 'dashboard/accountDetails/forms/EditProfilePicture';
import ChangePassword from 'dashboard/accountDetails/forms/ChangePassword';
import CloseAccount from 'dashboard/accountDetails/forms/CloseAccount';

import Loading from "components/Loading";

import styles from 'dashboard/Dashboard.module.scss';
import formStyles from './forms/Forms.module.scss';
import accountDetailsStyles from './AccountDetails.module.scss';
import Subscription from "./subscription/Subscription";
import ContactWholesale from "./forms/ContactWholesale";
import Aboutus from "./forms/Aboutus";

class AccountDetails extends Component {
    static contextType = Context;

    state = {
        loading: true,
    };

    async componentDidMount() {
        segment.page('Account Details');

        try {
            let plan = await this.context.getPlans();

            const free = {
                name: "Basic",
                secondaryResourceId: "basic",
                unitAmount: 0
            };

            let subscribedPlan;
            if (this.context.subscriptions[0]) {
                subscribedPlan = plan.filter(plan => plan.secondaryResourceId === this.context.subscriptions[0].subscriptionPlan.secondaryResourceId)[0];
            } else {
                subscribedPlan = free;
            }

            this.setState({
                plan: plan[0],
                subscribedPlan,
                loading: false
            });

        } catch(error) {
            console.log(error);
        }


    }

    /**
     * Calls logout function in context and handles response; either redirect or error toast
     * @param {Object} event - clicking logout button
     */
    logout = async (event) => {
        event.preventDefault();

        await segment.track('auth_logout', {
            application: 'dashboard'
        });

        try {
            await this.context.logout()
        } catch {
            this.context.setToast('Logout unsuccessful, try again.', 'Danger', 10000);
        }
    };

    render() {
        if(!this.state.loading) {
            return (
                <div className={styles.container}>
                    <Helmet>
                        <title>Account - Speedweaver</title>
                    </Helmet>

                    <div className={`${styles.account}`}>
                        <h1>Account Details</h1>
                        <button className="btn btnBrand" onClick={this.logout}>Log Out</button>
                    </div>

                    <div className={accountDetailsStyles.accountDetailsWrapper}>
                        <div className={`bgSecondary highlightSecondary ${accountDetailsStyles.subscription}`}>
                            <Subscription
                                plan={this.state.plan}
                                subscribedPlan={this.state.subscribedPlan}
                            />
                        </div>
                    </div>

                    <div className={accountDetailsStyles.accountDetailsWrapper}>
                        <div className={`bgSecondary highlightSecondary ${accountDetailsStyles.accountDetails}`}>
                            <div>
                                <EditProfilePicture />

                                <div className={`highlightTertiary ${formStyles.form}`}>
                                    <div>
                                        <h3>Email Address</h3>
                                    </div>
                                    <div className={`bgTertiary highlightSecondary ${formStyles.lockedEmail}`}>
                                        <img src={require('images/icons/padlock-grey.svg')} alt="Locked" />
                                        <p>{this.context.user.email}</p>
                                    </div>
                                </div>

                                <Details />
                                <ChangePassword />

                                <div className={`highlightSecondary ${accountDetailsStyles.theme}`}>
                                    <label>
                                        <h3>Dark Mode</h3>
                                        <ReactSwitch
                                            onChange={this.context.toggleLightMode}
                                            checked={!this.context.lightTheme}
                                            onColor="#fe6500"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={accountDetailsStyles.accountDetailsWrapper}>
                        <div className={`bgSecondary highlightSecondary ${accountDetailsStyles.accountDetails}`}>
                            <div className={accountDetailsStyles.closeAccount}>
                                <CloseAccount />
                            </div>
                        </div>
                    </div>

                    <div className={accountDetailsStyles.accountDetailsWrapper}>
                        <div className={`bgSecondary highlightSecondary ${accountDetailsStyles.accountDetails}`}>
                            <div className={accountDetailsStyles.closeAccount}>
                                <ContactWholesale />
                            </div>
                        </div>
                    </div>

                </div>
            )
        } else {
            return <Loading />
        }
    }
}

export default AccountDetails;
