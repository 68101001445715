import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Context } from 'context/context';

import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import moment from 'moment';

import styles from 'dashboard/projects/Projects.module.scss';
import vars from "styles/_vars-dark.scss";

class Item extends Component {
    static propTypes = {
        project: PropTypes.object,
    };

    static contextType = Context;

    state = {
        progress: 0,
        ready: false,
        rejected: false,
        status: null,
        fileName: null,
    };

    componentDidMount() {
        this.countUp(this.props.project);
    }


     componentDidUpdate(prevProps, prevState) {
        const tunerStatusChanged = prevProps.project.tunerStatus !== this.props.project.tunerStatus;
        
        if(tunerStatusChanged){ 
            this.props.translate(this.props.project);
        }
    }

    countUp = (project) => {
        setInterval(() => {

            let progress = this.props.itemState.progress;
            progress = progress + Math.floor(Math.random() * 3) + 1;

            switch(this.props.project.tunerStatus){
                case "waiting_to_be_sent_to_tuner":
                    progress = progress >= 13 ? 13 : progress;
                    break;
                case "waiting_for_identification":
                    progress = progress >= 43 ? 43 : progress;
                    break;
                case "waiting_for_map_data":
                    progress = progress >= 99 ? 99 : progress;
                    break;
                case "ready":
                    progress = progress >= 100 ? 100 : progress;
                    break;
                default:
                    progress = 0;
                    break;
            }

            this.setState({ progress })

        }, Math.floor(Math.random() * 5000) + 2000);
    };


     

    render (){
        const userPlan = this.context.user.status === "expired" ? "Free" : this.context.user.plan;
        const isFreeUser = userPlan == 'Free';
        const project = this.props.project
        const isDemo = project.projectType === "DEMO"
        const isProProject = !isDemo && !this.context.subscriptionVariables?.isProUser
        let purchased = this.props.project.purchased;
        const projectStatus = this.props.itemState.status === 'Detection Complete'? 'Ready' : this.props.itemState.status
        const theme = this.context.lightTheme? 'light' : 'dark'
        const hasHadTrial = this.context.subscriptionVariables?.hasHadTrial
        const freeTrialLink = !hasHadTrial?  'freetrial' : 'resubscribe'
        const projectLink = `/projects/${isFreeUser && !isDemo && !purchased? freeTrialLink : this.props.project.id+`/tuning`}`
        return (
            <>
                {this.props.itemState.ready && this.props.project.projectName?
                    <NavLink to={projectLink} key={this.props.project.id} className={`${styles.project} ${this.context.lightTheme? styles.lightBorder : styles.darkBorder} bgSecondary`}>
                        <div>
                            {this.props.project.protocolInformation?.vehicleMake.logo && <img src={`${process.env.REACT_APP_LOGOS}/${theme}/${this.props.project.protocolInformation.vehicleMake.logo}`} alt="" />}
                            <div className={styles.details}>
                                <div>
                                    {
                                        isDemo?
                                            <span className={`bgNeutral ${styles.isDemo}`}>Demo</span> 
                                        : isProProject && !purchased ? 
                                            <span title="Subscription Required">
                                                <img src={require('images/icons/padlock.svg')} alt="Subscription Required" />
                                            </span> 
                                        : null
                                    }

                                    <div>
                                        <p className="txtPrimary">{this.props.project.projectName}</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <p className="txtSecondary">{`${this.props.project.protocolInformation?.vehicleMake?.name}, ${this.props.project.protocolInformation?.vehicleModel?.name.split(" ()")[0]}`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.props.project.tunerStatus === "archived" ?
                                <img src={require(`images/icons/${this.context.lightTheme ? "archived-grey" : "archived"}.svg`)} alt="Archived" />
                            :
                                <>
                                    <p>{moment(this.props.project.createdDate).format('MMM Do, HH:mm')}</p>
                                </>
                            }

                        </div>
                    </NavLink>
                :
                    this.props.itemState.ready ?
                        <NavLink to={projectLink} key={this.props.project.id} className={`${styles.project} ${this.context.lightTheme? styles.lightBorder : styles.darkBorder} bgSecondary`}>
                            <div>
                                <div className={styles.circularProgress}>
                                    <CircularProgressbar
                                        value={this.props.itemState.progress}
                                        text={`${this.props.itemState.progress}%`}
                                        maxValue={100}
                                        strokeWidth={6}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                            pathColor: vars.success,
                                            trailColor: this.context.lightTheme ? '#DCDCDC' : '#393939' ,
                                            textColor: this.context.lightTheme ? '#1e1e1e' : '#E0E0E0' ,
                                            textSize: '22px',
                                        })}
                                    />
                                </div>
                                <div className={styles.details}>
                                    <div>
                                        {
                                            isDemo?
                                                <span className={`bgNeutral ${styles.isDemo}`}>Demo</span> 
                                            : isProProject && !purchased ? 
                                                <span title="Subscription Required">
                                                    <img src={require('images/icons/padlock.svg')} alt="Subscription Required" />
                                                </span> 
                                            : null
                                        } 

                                        <div>
                                            <p className="txtPrimary">{this.props.itemState.fileName}</p>
                                        </div> 
                                    </div>   
                                    <div>
                                        <div>
                                            <p className="txtSecondary">{this.props.project.ecu ? `${this.props.project.ecuManufacturer} ${this.props.project.ecu}` : null}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p>{moment(this.props.project.createdDate).format('MMM Do, HH:mm')}</p>
                            </div>
                        </NavLink>
                    : !this.props.itemState.rejected ?
                        <div className={`bgSecondary ${styles.project} ${this.context.lightTheme? styles.lightBorder : styles.darkBorder}`} key={this.props.project.id}>
                            <div>
                                <div className={styles.circularProgress}>
                                    <CircularProgressbar
                                        value={this.props.itemState.progress}
                                        text={`${this.props.itemState.progress}%`}
                                        maxValue={100}
                                        strokeWidth={6}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                            pathColor: vars.brand,
                                            trailColor: this.context.lightTheme ? '#DCDCDC' : '#393939' ,
                                            textColor: this.context.lightTheme ? '#1e1e1e' : '#E0E0E0' ,
                                            textSize: '22px',
                                        })}
                                    />
                                </div>
                                <div className={styles.details}>
                                    <div>
                                        {
                                            isDemo?
                                                <span className={`bgNeutral ${styles.isDemo}`}>Demo</span> 
                                            : isProProject && !purchased ? 
                                                <span title="Subscription Required">
                                                    <img src={require('images/icons/padlock.svg')} alt="Subscription Required" />
                                                </span> 
                                            : null
                                        }
                                        <div>
                                            <p className="txtPrimary">{this.props.itemState.fileName}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <p className="txtSecondary">{this.props.project.ecu ? `${this.props.project.ecuManufacturer} ${this.props.project.ecu}` : null}</p>
                                        </div>
                                    </div>                                </div>
                            </div>
                            <div>
                                <span className={styles.amber} />
                                <p className="txtSecondary">{projectStatus} </p>
                            </div>
                        </div>
                    :
                        <NavLink to={`/projects/${this.props.project.id}`} className={`bgSecondary ${styles.project} ${this.context.lightTheme? styles.lightBorder : styles.darkBorder} ${styles.notSupported} highlightPrimary`} key={this.props.project.id}>
                            <div>
                                <div className={styles.circularProgress}>
                                    <img src={require(`images/icons/${this.context.lightTheme? `unsupported-light` : `unsupported`}.svg`)} alt="Unsupported" />
                                </div>
                                <div className={styles.details}>
                                    <div>
                                        <div>
                                            <p className="txtPrimary">{this.props.itemState.fileName}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p>{moment(this.props.project.createdDate).format('MMM Do, HH:mm')}</p>
                            </div>
                        </NavLink>
                }
            </>
        )
    }
}

export default Item;