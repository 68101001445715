import React, { Component } from 'react';

import styles from '../../Billing.module.scss';

class ErrorMessage extends Component {
    render() {
        return (
            <div className={styles.errorMessage}>
                <div className={styles.container}>
                    <p>Error: {this.props.errorMessage}</p>
                </div>
            </div>
        )
    }
}

export default ErrorMessage;

