import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Billing.module.scss';

class ThankYou extends Component {
    render() {

        return (
            <div className={styles.thankYou}>
                <img src={require("images/icons/success-light.svg")} alt="Tick" />
                {this.props.update ?
                    <>
                        <h2>Your Billing Details have been updated.</h2>
                        <NavLink to="/account" className="btn btnOutline">Back to Dashboard</NavLink>
                    </>
                :
                    <>
                        <h2>Welcome to Speedweaver!</h2>
                        <NavLink to="/account" className={`btn btnOutline ${styles.backToDashboard}`}>Back to Dashboard</NavLink>
                    </>
                }
            </div>
        )
    }
}

export default ThankYou;