import React, { Component } from 'react';

import styles from 'dashboard/compatibilitySearch/CompatibilitySearch.module.scss';
import history from 'history/history'

class Back extends Component {
    render() {
        return (
            <button onClick={() => {history.push('/compatibility-search')}} className={`btn bgSecondary btnOutline ${styles.back}`}>
                <img src={require(`images/icons/${this.context.lightTheme ? "back-grey" : "back"}.svg`)} alt="Back" />
                <p>Back to Start</p>
            </button>
        )
    }
}

export default Back;
