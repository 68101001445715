import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import history from "history/history";
import { Context } from 'context/context';
import segment from "segment";

import Chat from 'dashboard/support/ticket/components/Chat';
import Reply from 'dashboard/support/ticket/components/Reply';
import Loading from "../../../components/Loading";

import styles from "dashboard/support/ticket/Ticket.module.scss";
import moment from "moment";

class Existing extends Component {
    static contextType = Context;

    state = {
        loading: true,
        ticket: null,
        comments: null,
    };

    async componentDidMount() {
        await segment.page('Existing Support Ticket', {
            ticket: this.props.match.params.id
        });

        await this.context.getProjects()

        await this.subscribeSingleTicket(this.props.match.params.id);
        await this.getTicket();

        const ticket = this.context.tickets[this.state.ticketIndex]
        const projectId = this.getProjectIdFromUrl(ticket)

        await segment.track('ticket_opened', {
            application: 'dashboard',
            ticketId: ticket.id,
            ticketType: ticket.ticketType,
            projectId: projectId,
        });

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                loading: true
            });
            await this.subscribeSingleTicket(this.props.match.params.id);
            await this.getTicket();

            const ticket = this.context.tickets[this.state.ticketIndex]
            const projectId = this.getProjectIdFromUrl(ticket)

            await segment.track('ticket_opened', {
                application: 'dashboard',
                ticketId: ticket.id,
                ticketType: ticket.ticketType,
                projectId: projectId,
            });

            this.setState({
                loading: false
            })
        }
    }

    getProjectIdFromUrl(ticket) {
        let project = ticket.project
        if(project) {
            return project.split("/")[3]
        } 
        return project;
    }

    async subscribeSingleTicket(id) {
        await this.context.addEventSourceTopic(`/tickets/${id}/?ticket_thread_item={iri}`);
        this.context.addEventSourceListener("message", this.onSingleTicketUpdate);
    }

    onSingleTicketUpdate = async (e) => {
        try {
            let data = JSON.parse(e.data);
            let comment = this.state.comments.filter((comment) => comment.id === data.id );
            if(data['@type'] !== "TicketThreadItem" || comment.length >= 1) return;
            this.updateSubscribedSingleTicket(data)
        } catch(error) {
            console.log(error)
        }
    };

    updateSubscribedSingleTicket(data) {
        let stateComments = this.state.comments;
        stateComments.push(data);

        this.setState({
            tickets: stateComments
        })
    }

    async unSubscribeSingleTicket(id) {
        await this.context.removeEventSourceTopic(`/tickets/${id}/?ticket_thread_item={iri}`);
        this.context.removeEventSourceListener("message", this.onSingleTicketUpdate);
    }

    async componentWillUnmount(){
        await this.unSubscribeSingleTicket(this.props.match.params.id);
    }

    getTicket = async () => {
        try {
            let ticket = await this.context.getTicket(this.props.match.params.id);
            const comments = await this.context.getComments(this.props.match.params.id);
            let ticketIndex = this.context.tickets.findIndex((contextTicket) => contextTicket.id === ticket.id);
            this.setState({
                loading: false,
                comments,
                ticketIndex,
                attachments: ticket.attachments.length > 0 ? ticket.attachments : []
            })
        } catch(error) {
            history.push("/support-tickets");
        }
    };

    close = () => {
        history.push("/support-tickets");
    };

    solveTicket = async (ticketId) => {
        await segment.track('ticket_solved', {
            application: 'dashboard',
            ticketId: this.context.tickets[this.state.ticketIndex].id,
            ticketType: this.context.tickets[this.state.ticketIndex].ticketType,
            projectId: this.context.tickets[this.state.ticketIndex].project ? this.context.tickets[this.state.ticketIndex].project : undefined
        });
        await this.context.solveTicket(ticketId);
        await this.context.getTickets();
    };

    openSolvedTicket = async (ticketId) => {
        await segment.track('ticket_reOpened', {
            application: 'dashboard',
            ticketId: this.context.tickets[this.state.ticketIndex].id,
            ticketType: this.context.tickets[this.state.ticketIndex].ticketType,
            projectId: this.context.tickets[this.state.ticketIndex].project ? this.context.tickets[this.state.ticketIndex].project : undefined
        });
        await this.context.openSolvedTicket(ticketId);
        await this.context.getTickets();
    };

    render() {
        if(this.state.loading) {
            return <div className={`bgSecondary highlightTertiary ${styles.container}`}><Loading /></div> ;
        } else {
            let ticketTypeConverted;

            switch (this.context.tickets[this.state.ticketIndex]?.ticketType) {
                case "general" :
                    ticketTypeConverted = "General Support";
                    break;
                case "rejected_binary":
                    ticketTypeConverted = "Rejected Binary";
                    break;
                case "account":
                    ticketTypeConverted = "Account / Billing Details";
                    break;
                case "technical":
                    ticketTypeConverted = "Bug Issue Report";
                    break;
                case "wizard_request":
                    ticketTypeConverted = "Wizard Request";
            }

            let projectString = "No Associated Project";
            let project = null;
            let ticketProjectId = this.context.tickets[this.state.ticketIndex].project;
            if(ticketProjectId) project = this.context.projects.includes((project) => project.id === this.context.tickets[this.state.ticketIndex].project.split('/')[3]);
            if(this.context.tickets[this.state.ticketIndex].project !== null && project) {
                if(!!project.projectName) {
                    projectString = project.projectName;
                } else if(!!project.originalFileName) {
                    projectString = project.originalFileName.split('_')[1];
                }

                if(projectString === "blob") projectString = "Demo File";
            }

            return (
                <>
                    <div className={`bgSecondary highlightSecondary ${styles.container}`}>
                        <>
                            <Helmet>
                                <title>Ticket: {this.context.tickets[this.state.ticketIndex].subject} - Speedweaver</title>
                            </Helmet>

                            <div className={`bgTertiary highlightSecondary ${styles.head}`}>
                                <div>
                                    <div>
                                        <p className={`${this.context.tickets[this.state.ticketIndex].statusStringHuman.toLowerCase()} ${styles.status}`}>
                                            {this.context.tickets[this.state.ticketIndex].statusStringHuman.toUpperCase()}
                                        </p>
                                        <p className={`txtPrimary ${styles.sub}`}>{this.context.tickets[this.state.ticketIndex].subject}</p>
                                    </div>
                                    
                                    <div className={styles.meta}>
                                        <p className="txtSecondary">{ticketTypeConverted}</p>
                                        <p className="txtSecondary">Ticket #{this.context.tickets[this.state.ticketIndex].resourceId}</p>
                                    </div>
                                </div>

                                <div className={styles.details}>
                                    <div className={styles.actions}>
                                        <NavLink to={`/support-tickets`}>
                                            <img src={require(`images/icons/${this.context.lightTheme ? "cross-dark" : "cross"}.svg`)} alt={"X"} />
                                        </NavLink>
                                    </div>
                                    <div>
                                        <p className="txtSecondary">{moment(this.context.tickets[this.state.ticketIndex].createdDate).format('lll')}</p>
                                        {this.context.tickets[this.state.ticketIndex].project && project ?
                                            <NavLink
                                                to={`/projects/${this.context.tickets[this.state.ticketIndex].project.split('/')[3]}/tuning`}
                                            >
                                                <p className="txtSecondary">{projectString}</p>
                                                <img
                                                    src={require('images/icons/open-grey.svg')}
                                                    alt="(Click to open project)"
                                                />
                                            </NavLink>
                                        :
                                            <p className="txtSecondary">{projectString}</p>
                                        }
                                    </div>
                                </div>
                            </div>


                            <Chat
                                status={this.context.tickets[this.state.ticketIndex].statusStringHuman.toLowerCase()}
                                notes={this.context.tickets[this.state.ticketIndex].notes}
                                createdDate={this.context.tickets[this.state.ticketIndex].createdDate}
                                comments={this.state.comments}
                                attachments={this.state.attachments}
                            />

                            {this.context.tickets[this.state.ticketIndex].statusString !== "STATUS_CLOSED" ?
                                <Reply
                                    id={this.context.tickets[this.state.ticketIndex].id}
                                    getTicket={this.getTicket}
                                    ticket={this.context.tickets[this.state.ticketIndex]}
                                    openSolvedTicket={this.openSolvedTicket}
                                    solveTicket={this.solveTicket}
                                />
                            :
                                null
                            }
                        </>
                    </div>
                </>
            )
        }
    }
}

export default Existing;
