import React, { Component } from 'react';
import Config from "../Config";
import {Link, NavLink} from 'react-router-dom';
import { Context } from 'context/context';

import preval from 'preval.macro';
import ProgressBar from '../../src/components/ProgressBar'

import styles from 'dashboard/Nav.module.scss';

class Nav extends Component {
    static contextType = Context;

    state = {
        open: false,
        openPrompt: true,
        cheapestPlan: 0,
        cheapestCurrency: '£'
    };

    componentDidMount(){
        this.getCheapestSubscriptionPlan();
        window.addEventListener("resize", this.closeNavMove);
    }

    /**
     * Close the mobile if the user resizes the window outside of 889px
     */
    closeNavMove = () => {
        if(this.state.open === true){
            if(window.innerWidth > 899){
                this.setState({
                    open: false
                })
            }
        }
    };

    /**
     * Open or close the mobile nav
     */
    toggleNav = () => {
        this.setState({
            open: !this.state.open
        })
    };

    closeNav = () => {
        this.setState({
            open: false
        });

        window.scrollTo(0, 0);
    };

    getCheapestSubscriptionPlan = async () => {
        let cheapestPlan = await this.context.getCheapestPlan();
        this.setState({
            cheapestPlan: cheapestPlan.unitAmount,
            cheapestCurrency: this.getCurrencySymbol(cheapestPlan.currency)
        });
    };

    getCurrencySymbol = (currency) => {
        if (currency === 'EUR') {
            return '€';
        } else {
            return '£';
        }
    }

    closePrompt = () => {
        this.setState({
            openPrompt: false
        });
    }

    navClose = () => {
        this.closeNav();
        this.closePrompt();
    }

    render() {
        const user = this.context.user
        const subVariables = this.context.subscriptionVariables
        const trialEnds = subVariables.trialEnds

        let daysRemaining = Date.parse(trialEnds) - Date.now()
        daysRemaining = Math.round(((daysRemaining / (1000*60*60*24)) % 7))
        let daysRemainingText = `${daysRemaining} Day${daysRemaining > 1 ? 's' : ''} Remaining`
        if(daysRemaining === 0) {
            daysRemainingText = 'Expires Today!'
        }
        let subscriptionPricePerMonth = this.context.subscriptionPricePerMonth;


        return (
            <>
                <nav className={`bgSecondary ${styles.nav}`}>
                    <span className={`bgSecondary ${styles.navHeadSpan}`} />
                    <div className={styles.head}>
                        <NavLink
                            className={styles.logo}
                            to="/projects"
                            title={'Version ' + Config.version + ' at ' + preval`module.exports = new Date().toLocaleString();`}
                            onClick={this.closeNav}
                        >
                            <img src={require("images/speedweaver.svg")} alt="Speedweaver logo"/>
                        </NavLink>
                    </div>

                    <div className={`bgSecondary highlightSecondary ${styles.links} ${this.state.open ? styles.open : null}`}>
                        <nav className={`${this.state.open ? "visible" : ""} bgSecondary`}>
                            <div>
                                <NavLink onClick={this.closeNav} to="/projects">
                                    <img src={require(`images/icons/projects.svg`)} alt="Tuning Projects" />
                                    Tuning Projects
                                </NavLink>

                                <NavLink onClick={this.closeNav} to="/logs">
                                    <img src={require(`images/icons/logs.svg`)} alt="Diagnostic Logs" />
                                    Diagnostics Logs
                                </NavLink>

                                <NavLink onClick={this.closeNav} to="/support-tickets">
                                    <img src={require(`images/icons/tickets.svg`)} alt="Support Tickets" />
                                    Support Tickets
                                </NavLink>

                                <NavLink onClick={this.closeNav} to="/compatibility-search">
                                    <img src={require(`images/icons/${this.context.lightTheme ? "compatibility-grey" : "compatibility"}.svg`)} alt="Support Tickets" />
                                    Compatibility Search
                                </NavLink>
                            </div>
                        </nav>

                        {subVariables.isTrialAvailable ?
                            <Link to="/billing/subscribe" className={styles.freeTrial} onClick={this.closeNav}>
                                <p>Subscribe for just <b>€{subscriptionPricePerMonth}</b>/month (exclusive of VAT)</p>
                                <NavLink className={`btn btnBrand ${styles.subButton}`} onClick={this.closeNav} to="/billing/subscribe">Start Tuning Now</NavLink>
                            </Link>
                        :!subVariables.isProUser?
                            <Link to="/billing/subscribe" className={styles.freeTrial} onClick={this.closeNav}>
                                <NavLink className={`btn btnBrand ${styles.subButton}`} onClick={this.closeNav} to="/billing/subscribe">Start Tuning Now</NavLink>
                            </Link>
                        : subVariables.isTrialActive?
                            <Link to="/account" className={styles.freeTrial} onClick={this.closeNav}>
                                <p><b>Free Trial:</b> {daysRemainingText} </p>
                                <ProgressBar total={7} value={daysRemaining}/>
                            </Link>
                        :  null }

                        <NavLink to="/account" className={`${styles.navLogo} bgTertiary`} onClick={this.closeNav}>
                            <div className={styles.profile}>
                                <img src={user.profilePicture ? this.context.getProfilePictureRoute() + user.profilePicture : require("images/stockProfile.svg")} alt="Profile" />
                                <div>
                                    <p>{user.firstName} {user.lastName}</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div id="hamburger" onClick={this.toggleNav} className={this.state.open ? "close" : null}><span/></div>
                </nav>

                {this.state.open && <span className={styles.closeNav} onClick={this.toggleNav} />}
            </>
        );
    }
}

export default Nav;
